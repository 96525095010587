import { createSelector } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import * as fromSubscription from './subscription-plan.reducer';

const getSubscriptionPlanState = (state: fromApp.AppState) =>
  state.subscriptionPlan;

export const getSubscriptionPlanIsLoading = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.isLoading
);

export const getSubscriptionPlanIsFetching = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.isFetching
);

export const getAllSubscriptionPlans = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.allSubscriptionPlans
);

export const getSubscriptionPlanPricings = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.allSubscriptionPricings
);

export const getAllActiveSubscriptionPlansForWebsite = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.allActiveSubscriptionPlansForWebsite
);

export const getAllActiveSubscriptionPlans = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.allActiveSubscriptionPlans
);

export const getAllActiveCurrencies = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.allActiveCurrencies
);

export const getCurrentSubscription = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.currentSubscription
);

export const getPreviousSubscription = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.previousSubscription
);

export const getSubscriptionHistory = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.subscriptionHistory
);

export const getSubscriptionPlanFeaturesBySubscriptionPlanId = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) =>
    state.subscriptionPlanFeaturesBySubscriptionPlanId
);

// export const getSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel =
//   createSelector(
//     getSubscriptionPlanState,
//     (state: fromSubscription.State) =>
//       state.subscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel
//   );

export const getSubscriptionById = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.subscriptionById
);

export const getSubscriptionPlanById = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) => state.subscriptionPlanById
);

export const getActiveSubPlanForCorporateUser = createSelector(
  getSubscriptionPlanState,
  (state: fromSubscription.State) =>
    state.activeSubuscriptionFeatureForCorporateUser
);
