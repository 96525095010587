import { Action, createReducer, on } from '@ngrx/store';
import * as OrganizationActions from './organization.actions';

export interface organizationReducerState {
  isLoading: boolean;
  // toggleLoader: boolean;
  // isTwoFactorEnabled: boolean;
  divisions: {
    divisions: any[];
    count: number;
  } | null;
  departments: {
    departments: any[];
    count: number;
  } | null;
  units: {
    units: any[];
    count: number;
  } | null;
}

export const initialState: organizationReducerState = {
  isLoading: false,
  // toggleLoader: false,
  // isTwoFactorEnabled: false,
  divisions: null,
  departments: null,
  units: null,
};

const organizationReducerInternal = createReducer(
  initialState,
  on(OrganizationActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(OrganizationActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),

  on(OrganizationActions.SaveAllDivisions, (state, { payload }) => ({
    ...state,
    divisions: payload,
  })),
  on(OrganizationActions.SaveAllDepartments, (state, { payload }) => ({
    ...state,
    departments: payload,
  })),
  on(OrganizationActions.SaveAllUnits, (state, { payload }) => ({
    ...state,
    units: payload,
  }))
);

export function organizationReducer(
  state: organizationReducerState | undefined,
  action: Action
) {
  return organizationReducerInternal(state, action);
}
