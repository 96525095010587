import {
  HttpClient,
  HttpContext,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as fromApp from '../app/app.reducer';
import {
  map,
  catchError,
  switchMap,
  withLatestFrom,
  share,
} from 'rxjs/operators';
import * as MyFormsActions from './my-forms.actions';
import { environment } from 'src/environments/environment';
import { GenericResponse, Notification } from 'src/app/@core/interfaces/index';
import { NotificationService } from '../../services/notification.service';
import { HelperService } from '../../services/helper.service';
import { USE_ACCESS_TOKEN } from '../../interceptors/app.interceptor.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class MyFormsEffects {
  defaultPaginationPayload = {
    payload: {
      page: 0,
      take: 10,
    },
  };
  constructor(
    private actions$: Actions,
    private http: HttpClient,
    private dialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private notificationService: NotificationService,
    private helperService: HelperService
  ) {}

  private handleCatchError = (errorRes: HttpErrorResponse, type: string) => {
    this.store.dispatch(MyFormsActions.IsLoading({ payload: false }));

    return this.helperService.handleErrorMessages(errorRes, type);
  };

  getAllForms$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyFormsActions.GetAllForms),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formsData, authState]) => {
        return this.http
          .get<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/projects/${authState.user?.SubscriberId}/${authState.user?.UserId}/${formsData.payload.page}/${formsData.payload.take}`,
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(MyFormsActions.IsLoading({ payload: false }));

              if (resData.succeeded === true) {
                return MyFormsActions.SaveAllForms({
                  payload: resData.entity,
                });
              } else {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                return { type: '[My forms] Failed To Get All forms' };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[My forms][CatchError] Failed To Get All forms ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  getResponses$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.getResponses),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .get<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/projectinstance/projectinstanceresponses/${authState.user?.SubscriberId}/${authState.user?.UserId}/${payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData: any) => {
                this.store.dispatch(
                  MyFormsActions.IsLoading({ payload: false })
                );

                if (resData.succeeded === true) {
                  return resData;
                } else {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return resData;
                }
              }),
              catchError((errorRes) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed To Get All forms ${errorRes.message}`
                );
              })
            );
        }),
        share()
      );
    },
    { dispatch: false }
  );

  deleteForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(MyFormsActions.DeleteForm),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/delete`,
            {
              ...formData.payload,
              ownerId: authState.user.UserId,
              actorEmail: authState.user.Email,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData: any) => {
              this.store.dispatch(
                MyFormsActions.IsLoadingDeleteForm({ payload: false })
              );
              if (resData.succeeded) {
                const notification: Notification = {
                  state: 'success',
                  message: resData?.message,
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.dialog.closeAll();

                return MyFormsActions.GetAllForms(
                  this.defaultPaginationPayload
                );
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[My forms] Is Loading',
                  payload: false,
                };
              }
            }),
            catchError((errorRes) => {
              return this.handleCatchError(
                errorRes,
                `[My forms] Delete forms Failed`
              );
            })
          );
      })
    )
  );

  renameForm$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MyFormsActions.RenameForm),
      withLatestFrom(this.store.select('auth')),
      switchMap(([formData, authState]) => {
        return this.http
          .post<GenericResponse>(
            `${environment.onyxDocPhoenixFormBuilderUrl}/Project/update`,
            {
              ...formData.payload,
              actorEmail: authState.user.Email,
              ownerId: authState.user.UserId,
              subscriberId: authState.user.SubscriberId,
            },
            {
              context: new HttpContext().set(USE_ACCESS_TOKEN, true),
            }
          )
          .pipe(
            map((resData) => {
              this.store.dispatch(
                MyFormsActions.IsLoadingRenameForm({ payload: false })
              );

              if (resData.succeeded === true) {
                const notification: Notification = {
                  state: 'success',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-success'
                );

                this.store.dispatch(
                  MyFormsActions.GetAllForms(this.defaultPaginationPayload)
                );

                this.dialog.closeAll();

                return {
                  type: '[My forms] Form was Renamed Successfully',
                };
              } else {
                const notification: Notification = {
                  state: 'error',
                  message: resData.message || resData.messages[0],
                };

                this.notificationService.openNotification(
                  notification,
                  'flwmn-notification-error'
                );

                return {
                  type: '[My forms] Failed To Rename Form',
                };
              }
            }),
            catchError((errorRes: any) => {
              return this.handleCatchError(
                errorRes,
                `[My forms][CatchError] Failed To Rename Form ${errorRes.message}`
              );
            })
          );
      })
    );
  });

  deleteProjectPermanently$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.DeleteProjectPermanently),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/${payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  return {
                    type: '[My forms] Project has been permanently deleted',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[My forms] Failed to permanently delete project',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed to permanently delete project ${errorRes.message}`
                );
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  restoreProject$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.RestoreProject),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/${payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  return {
                    type: '[My forms] Project has been successfully restored',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[My forms] Failed to restore project',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed to restore project ${errorRes.message}`
                );
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  restoreTemplate$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.RestoreTemplate),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/${payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  return {
                    type: '[My forms] Template has been successfully restored',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[My forms] Failed to restore template',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed to restore template ${errorRes.message}`
                );
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  deleteTemplatePermanently$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.DeleteTemplatePermanently),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/${payload.id}`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  this.dialog.closeAll();

                  return {
                    type: '[My forms] Template has been permanently deleted',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[My forms] Failed to permanently delete template',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed to permanently delete template ${errorRes.message}`
                );
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  emptyRecycleBin$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(MyFormsActions.EmptyRecycleBin),
        withLatestFrom(this.store.select('auth')),
        switchMap(([payload, authState]) => {
          return this.http
            .delete<GenericResponse>(
              `${environment.onyxDocPhoenixFormBuilderUrl}/Project/empty-recycle-bin`,
              {
                context: new HttpContext().set(USE_ACCESS_TOKEN, true),
              }
            )
            .pipe(
              map((resData) => {
                if (resData.succeeded === true) {
                  const notification: Notification = {
                    state: 'success',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-success'
                  );

                  return {
                    type: '[My forms] Recycle bin has been emptied',
                  };
                } else {
                  const notification: Notification = {
                    state: 'error',
                    message: resData.message || resData.messages[0],
                  };

                  this.notificationService.openNotification(
                    notification,
                    'flwmn-notification-error'
                  );

                  return {
                    type: '[My forms] Failed to empty the recycle bin',
                  };
                }
              }),
              catchError((errorRes: any) => {
                return this.handleCatchError(
                  errorRes,
                  `[My forms][CatchError] Failed to empty the recycle bin ${errorRes.message}`
                );
              })
            );
        })
      );
    },
    { dispatch: false }
  );
}
