export enum ControlTypeEnum {
  text = 1,
  signature = 2,
  checkbox = 3,
  date = 4,
  name = 5,
  email = 6,
  jobTitle = 7,
  select = 8,
  // input2 = 4,
  // select = 5,
}

export enum ControlModeEnum {
  Internal = 'internal',
  External = 'external',
}
