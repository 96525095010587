import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subscription, take } from 'rxjs';
import { PaymentRedirectParamResponse } from 'src/app/@core/interfaces/payment.interface';
import { HelperService } from 'src/app/@core/services/helper.service';
import { InviteUsersComponent } from 'src/app/@core/shared/invite-users/invite-users.component';
import { SetupUsersComponent } from 'src/app/@core/shared/setup-users/setup-users.component';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as PaymentActions from 'src/app/@core/stores/payment/payment.actions';
import { PaymentEffects } from 'src/app/@core/stores/payment/payment.effects';
import * as PaymentSelectors from 'src/app/@core/stores/payment/payment.selectors';
import * as UserActions from 'src/app/@core/stores/user/user.actions';
import { UserEffects } from 'src/app/@core/stores/user/user.effects';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';

@Component({
  templateUrl: './checkout-success.component.html',
  styleUrls: ['./checkout-success.component.scss'],
})
export class CheckoutSuccessComponent implements OnInit, OnDestroy {
  isLoading!: Observable<boolean>;
  isPaymentSuccessful: boolean;
  paymentResponse: PaymentRedirectParamResponse;
  email: string;

  private subscription: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private router: Router,
    private paymentEffects: PaymentEffects,
    private location: Location,
    private helperService: HelperService,
    private userEffects: UserEffects
  ) {}

  ngOnInit(): void {
    this.isLoading = this.store.pipe(
      select(PaymentSelectors.getPaymentIsLoading)
    );

    this.store.dispatch(PaymentActions.IsLoading({ payload: true }));

    this.listenToActivatedRouteSubscription();

    this.listenToVerifyPaystackPaymentEffect();

    this.listenToVerifyFlutterwavePaymentEffect();
  }

  back(): void {
    this.location.back();
  }

  getEmail() {
    this.store.dispatch(
      UserActions.GetUserById({
        payload: {
          id: this.paymentResponse.item.UserId,
          isSubscriberNewlyCreated: true,
          userId: this.paymentResponse.item.UserId,
          subscriberId: this.paymentResponse.item.SubscriberId,
        },
      })
    );

    this.userEffects.getUserById$.pipe(take(1)).subscribe((resData) => {
      this.email = resData.entity.email;

      if (!resData.entity.emailConfirmed) {
        this.onSendVerificationEmail();
      }
    });
  }

  onInviteTeamMembers() {
    this.dialog.open(InviteUsersComponent, {
      data: {
        userId: this.paymentResponse.item.UserId,
        subscriberId: this.paymentResponse.item.SubscriberId,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-md'],
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  onSetupUsers() {
    this.dialog.open(SetupUsersComponent, {
      data: {
        userId: this.paymentResponse.item.UserId,
        subscriberId: this.paymentResponse.item.SubscriberId,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: 'flwmn-dialog',
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  listenToActivatedRouteSubscription() {
    this.subscription.add(
      this.activatedRoute.queryParams.subscribe((params: any) => {
        if (!params['email']) {
          this.router.navigate([], {
            relativeTo: this.activatedRoute,
            queryParams: { email: 'testcase' },
            queryParamsHandling: 'merge',
            skipLocationChange: false,
          });
        }

        this.paymentResponse = { ...params, item: JSON.parse(params.item) };

        if (this.paymentResponse) {
          this.helperService.manageDeveloperTokenAndCallFunction(
            this.verifyPayment.bind(this, this.paymentResponse)
          );

          this.getEmail();
        }
      })
    );
  }

  verifyPayment(paymentResponse: PaymentRedirectParamResponse): void {
    if (paymentResponse?.item?.PaymentChannel === 'Paystack') {
      // Verification logic for Paystack
      this.verifyPaymentWithPaystack(paymentResponse);
    } else if (paymentResponse?.item?.PaymentChannel === 'Flutterwave') {
      // Verification logic for Flutterwave
      this.verifyPaymentWithFlutterwave(paymentResponse);
    } else {
      // Handle unsupported payment channel or show an error message
    }
  }

  verifyPaymentWithPaystack(
    paymentResponse: PaymentRedirectParamResponse
  ): void {
    // Logic to verify payment with Paystack using trxId
    // ...
    this.store.dispatch(PaymentActions.IsLoading({ payload: true }));

    this.store.dispatch(
      PaymentActions.VerifyPaystackPayment({
        payload: {
          referenceNo: paymentResponse.trxref,
          userId: paymentResponse.item.UserId,
          subscriberId: paymentResponse.item.SubscriberId,
        },
      })
    );
  }

  listenToVerifyPaystackPaymentEffect() {
    this.subscription.add(
      this.paymentEffects.verifyPaystackPayment$.subscribe((res: any) => {
        if (res.succeeded) {
          this.isPaymentSuccessful = true;
        } else {
          this.isPaymentSuccessful = false;
        }
      })
    );
  }

  verifyPaymentWithFlutterwave(
    paymentResponse: PaymentRedirectParamResponse
  ): void {
    this.store.dispatch(
      PaymentActions.VerifyFlutterwavePayment({
        payload: {
          referenceNo: paymentResponse.tx_ref,
          transactionId: paymentResponse.transaction_id!,
          userId: paymentResponse.item.UserId,
          subscriberId: paymentResponse.item.SubscriberId,
        },
      })
    );
  }

  listenToVerifyFlutterwavePaymentEffect() {
    this.subscription.add(
      this.paymentEffects.verifyFlutterwavePayment$.subscribe((res: any) => {
        if (res.succeeded) {
          this.isPaymentSuccessful = true;
        } else {
          this.isPaymentSuccessful = false;
        }
      })
    );
  }

  get isLoggedIn() {
    return localStorage.getItem('OnyxDoc') !== null;
  }

  onLogOut() {
    localStorage.removeItem('OnyxDoc_auth');
    localStorage.removeItem('OnyxDoc');

    location.href = '/login';
  }

  onSendVerificationEmail() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.sendVerificationEmail.bind(this)
    );
  }

  sendVerificationEmail() {
    this.store.dispatch(
      AuthActions.SendVerificationEmail({
        payload: {
          email: this.email!,
        },
      })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
