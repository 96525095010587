import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { AddSignatureComponent } from 'src/app/@components/webapp/add-signature/add-signature.component';
import { ControlModeEnum, ControlTypeEnum } from 'src/app/@core/enums';
import { ControlMode, EditorMode } from 'src/app/@core/types';
import * as editorSelector from 'src/app/@core/stores/editor/editor.selectors';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import { Store } from '@ngrx/store';
import * as recipientSignatureSelectors from 'src/app/@core/stores/recipient-signature/recipient-signature.selectors';
import * as recipientSignatureActions from 'src/app/@core/stores/recipient-signature/recipient-signature.actions';
import { ManageSignatureComponent } from 'src/app/@components/webapp/manage-signature/manage-signature.component';
import { ActivatedRoute } from '@angular/router';
import { RecipientSignatureEffects } from 'src/app/@core/stores/recipient-signature/recipient-signature.effects';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit, OnDestroy {
  currentSignature!: string;
  controlModeEnum = ControlModeEnum;
  controlValues: any;
  showWaterMark: boolean = false;
  signType: string;

  userData: any | null = null;

  private touchTime = 0;
  private subscription: Subscription = new Subscription();

  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;
  @Input() actorMenu: boolean = false;

  @ViewChild('applyAllFeedback') applyAllFeedback: TemplateRef<any>;

  public dialogRefResponse: MatDialogRef<any>;

  constructor(
    public dialog: MatDialog,
    private elementRef: ElementRef,
    public store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private recipientEffect: RecipientSignatureEffects
  ) {}

  ngOnInit(): void {
    this.signType = this.route.snapshot.queryParams.signType;

    this.setSignature();

    this.listenToGetInternalUserByEmail();

    this.listenToGetExternalUserByEmail();

    this.listenToGetRecipientDefaultSignatures();

    this.listenToGetRecipientSignatures();

    this.listenToGetDocumentBySigningRequestId();

    this.listenToDecodeHash();
  }

  setSignature() {
    this.controlValues = JSON.parse(
      (
        this.elementRef.nativeElement as HTMLElement
      ).parentElement?.parentElement?.getAttribute('data-control') as string
    );
    this.currentSignature = this.controlValues?.value;
  }

  onHandleDoubleClick() {
    if (this.touchTime == 0) {
      // Set first click
      this.touchTime = new Date().getTime();
    } else {
      // Compare first click to this click and see if they occurred within double click threshold
      if (new Date().getTime() - this.touchTime < 800) {
        // Double click occurred
        if (this.userData.signature) {
          // const signType = this.route.snapshot.queryParams.signType;
          if (this.signType === 'self') {
            this.userData = { ...this.userData, isFlowmonoUser: true };
          }
          this.setSignatureControlByControl();
        } else {
          this.onAddSignature();
        }
      } else {
        // Not a double click so set as a new first click
        this.touchTime = new Date().getTime();
      }
    }
  }

  setSignatureControlByControl() {
    if (this.controlValues.name === this.control.name) {
      if (this.showWaterMark) {
        this.addWaterMark(this.userData.signature);
      } else {
        this.currentSignature = this.userData.signature;
        this.updateFormValues();
        this.onApplyToAllSignatures(this.currentSignature);
      }
      // this.currentSignature = this.userData.signature;
      // this.updateFormValues();
      // this.onApplyToAllSignatures(this.currentSignature);
    }
  }

  updateFormValues() {
    this.form.patchValue({
      [this.control.name]:
        this.currentSignature !== undefined ? this.currentSignature : null,
    });

    this.form.updateValueAndValidity();
  }

  onEditSignatureClicked() {
    if (this.userData) {
      if (
        this.userData.isFlowmonoUser === true &&
        this.userData.flowmonoUserHasSignature === true
      ) {
        this.onManageSignature();
      } else {
        this.onAddSignature();
      }
    }
  }

  onManageSignature() {
    this.dialogRefResponse = this.dialog.open(ManageSignatureComponent, {
      data: {
        showSavedSignature: false,
        user: {
          fullName: this.userData.fullName,
          initials:
            this.userData.fullName.charAt(0).toUpperCase() +
            '.' +
            this.userData.fullName.split(' ')[1].charAt(0).toUpperCase(),
        },
        email: this.userData.email,
        subscriberId: this.userData.subscriberId,
        isEditor: true,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    this.subscription.add(
      this.dialogRefResponse.afterClosed().subscribe((result) => {
        if (result) {
          // this.form.patchValue({
          //   [this.control.name]: result !== undefined ? result : null,
          // });

          // this.form.updateValueAndValidity();
          if (result.signature) {
            this.userData = { ...this.userData, signature: result.signature };
            this.setSignatureControlByControl();
          }
          // this.onApplyToAllSignatures(result);
          if (result.openAddModal) {
            this.onAddSignature();
          }
        }
      })
      // this.dialogRefResponse.close()
    );
  }

  onAddSignature() {
    const dialogRef = this.dialog.open(AddSignatureComponent, {
      data: {
        editorMode: this.editorMode,
        recipientEmail: JSON.parse(
          (
            this.elementRef.nativeElement as HTMLElement
          ).parentElement?.parentElement?.getAttribute('data-control') as string
        )?.recipientEmail,

        fullName: this.userData.fullName,
        initials:
          this.userData.fullName.charAt(0).toUpperCase() +
          '.' +
          this.userData.fullName.split(' ')[1].charAt(0).toUpperCase(),
        isEditor: true,
      },
      disableClose: true,
      autoFocus: false,
      panelClass: ['flwmn-dialog', 'flwmn-dialog-lg'],
      backdropClass: 'flwmn-dialog-backdrop',
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((result) => {
        // this.currentSignature = result;
        if (result) {
          // this.form.patchValue({
          //   [this.control.name]: result !== undefined ? result : null,
          // });

          // this.form.updateValueAndValidity();

          this.userData = {
            ...this.userData,
            signature: result,
          };

          this.setSignatureControlByControl();

          // if (result !== undefined && result !== null) {
          //   this.onApplyToAllSignatures(result);
          // }
        }
      })
    );
  }

  onApplyToAllSignatures(value?: any) {
    const formValues = this.form.value;

    const emptySignatures = Object.keys(formValues).filter(
      (key) => key.includes('signature') && formValues[key] === null
    );

    if (emptySignatures.length > 0) {
      this.dialogRefResponse = this.dialog.open(this.applyAllFeedback, {
        disableClose: true,
        autoFocus: false,
        panelClass: ['flwmn-dialog', 'flwmn-dialog-sm'],
        backdropClass: 'flwmn-dialog-backdrop',
      });

      this.subscription.add(
        this.dialogRefResponse.afterClosed().subscribe((res) => {
          if (res === true) {
            this.applyToAllSignatures(value);
          }
        })
      );
    }
  }

  onResponse(response: boolean) {
    this.dialogRefResponse.close(response);
  }

  applyToAllSignatures(value: any) {
    const controlChildren = document.getElementsByTagName(
      'app-control-builder'
    );

    const dataValues: any[] = [];

    for (let i = 0; i < controlChildren.length; i++) {
      dataValues.push(
        JSON.parse(controlChildren[i].getAttribute('data-control') as string)
      );
    }

    const recipientEmail = JSON.parse(
      (
        this.elementRef.nativeElement as HTMLElement
      ).parentElement?.parentElement?.getAttribute('data-control') as string
    )?.recipientEmail;

    // console.log(dataValues, recipientEmail);

    // for (const control in this.form.controls) {
    //   dataValues.forEach((item) => {
    //     if (item.recipientEmail === recipientEmail) {
    //       const currentControl = this.form.get(control);
    //       currentControl?.patchValue(value);
    //     }
    //   });
    // }

    // const matchingItem = dataValues.filter(
    //   (item) => item.recipientEmail === recipientEmail
    // );

    // if (matchingItem.length > 0) {
    //   Object.keys(this.form.controls).forEach((control) => {
    //     matchingItem.forEach((item) => {
    //       if (item.name === control) {
    //         const currentControl = this.form.get(control);
    //         currentControl?.patchValue(value);
    //       }
    //     });
    //   });
    // }

    const matchingItemsMap = new Map<string, any>();

    dataValues.forEach((item) => {
      if (
        item.recipientEmail === recipientEmail &&
        item.type === ControlTypeEnum.signature
      ) {
        matchingItemsMap.set(item.name, item);
      }
    });

    const controls = Object.keys(this.form.controls).filter((key) =>
      key.includes('signature')
    );

    controls.forEach((control) => {
      const matchingItem = matchingItemsMap.get(control);

      if (
        matchingItem &&
        matchingItem.value === null &&
        matchingItem.type === ControlTypeEnum.signature
      ) {
        const currentControl = this.form.get(control);
        currentControl?.patchValue(this.currentSignature);
      }
    });

    this.form.updateValueAndValidity();
  }

  isCurrentRecipient(): boolean {
    const signType = this.route.snapshot.queryParams.signType;
    if (
      (this.controlValues &&
        this.controlValues.recipientEmail === this.userData.email) ||
      signType
    ) {
      return true;
    }
    return false;
  }

  updateSignatureControl() {
    if (this.currentSignature) {
      // this.form.patchValue({
      //   [this.control.name]: this.currentSignature,
      // });

      // this.form.updateValueAndValidity();
      return;
    } else {
      this.setSignature();
    }
  }

  listenToGetInternalUserByEmail() {
    this.subscription.add(
      this.store
        .select(editorSelector.getInternalUserByEmail)
        .subscribe((res) => {
          if (
            res?.entity !== null &&
            res?.entity !== undefined &&
            res?.succeeded === true
          ) {
            const { email, firstName, lastName, subscriberId } = res.entity;
            this.userData = {
              ...this.userData,
              isFlowmonoUser: true,
              email: email,
              fullName: firstName + ' ' + lastName,
              subscriberId: subscriberId,
            };

            this.getRecipientDefaultSignature(email, subscriberId);
            this.getRecipientSignatures(email, subscriberId);
            // this.signatureBase64 = this.loggedInUsersSignature;
          }
        })
    );
  }

  listenToGetExternalUserByEmail() {
    this.subscription.add(
      this.store
        .select(editorSelector.getExternalUserByEmail)
        .subscribe((res) => {
          if (
            res?.entity !== null &&
            res?.entity !== undefined &&
            res?.succeeded === true
          ) {
            const { emailAddress, subscriberId, firstName, lastName } =
              res.entity;
            this.userData = {
              ...this.userData,
              isFlowmonoUser: false,
              fullName: firstName + ' ' + lastName,
              email: emailAddress,
            };
            this.getRecipientDefaultSignature(emailAddress, subscriberId);
            // this.signatureBase64 = this.loggedInUsersSignature;
          }
        })
    );
  }

  listenToGetDocumentBySigningRequestId() {
    this.subscription.add(
      this.store
        .select(editorSelector.getDocumentsBySigningRequestId)
        .subscribe((res: any) => {
          if (res?.length > 0) {
            const { email, subscriberId } = res[0].document;
            this.getRecipientDefaultSignature(email, subscriberId);
            this.getRecipientSignatures(email, subscriberId);
            const signType = this.route.snapshot.queryParams.signType;
            if (signType) {
              this.userData = {
                ...this.userData,
                isFlowmonoUser: true,
                fullName: res[0].document.createdByName,
                email: email,
              };

              this.showWaterMark =
                res[0].document.allowTimeStampOnSignatureControl;
            }
          }
        })
    );
  }

  getRecipientDefaultSignature(email: string, subscriberId: number) {
    if (email) {
      this.store.dispatch(
        recipientSignatureActions.GetRecipientDefaultSignature({
          payload: {
            email: email,
            subscriberId: subscriberId,
          },
        })
      );
    }
  }

  listenToGetRecipientDefaultSignatures() {
    this.subscription.add(
      this.store
        .select(recipientSignatureSelectors.getRecipientDefaultSignature)
        .subscribe((res: any) => {
          if (res !== null) {
            // this.currentSignature = res.signature
            //   ? res.signature
            //   : this.currentSignature;
            this.userData = {
              ...this.userData,
              signature: res.signature,
              // fullName: res.fullName,
              subscriberId: res.subscriberId,
            };
          }
        })
    );
  }

  getRecipientSignatures(email: string, subscriberId: number) {
    if (email) {
      this.store.dispatch(
        recipientSignatureActions.GetRecipientActiveSignatures({
          payload: {
            email: email,
            skip: 0,
            take: 10,
            subscriberId: subscriberId,
          },
        })
      );
    }
  }

  listenToGetRecipientSignatures() {
    this.subscription.add(
      this.recipientEffect.getRecipientActiveSignatures$.subscribe(
        (res: any) => {
          if (res.succeeded && res.entity && res.entity.count > 0) {
            this.userData = {
              ...this.userData,
              flowmonoUserHasSignature: true,
            };
          } else {
            this.userData = {
              ...this.userData,
              flowmonoUserHasSignature: false,
            };
          }
        }
      )
    );
  }

  listenToDecodeHash() {
    this.subscription.add(
      this.store.select(editorSelector.getSavedDecodeHash).subscribe((res) => {
        if (res !== null) {
          this.showWaterMark =
            res.signingRequest.documents[0].allowTimeStampOnSignatureControl;
          // this.showWaterMark = false;
        }
      })
    );
  }

  addWaterMark(inputtedSignature: string) {
    const fontSize = '60px';
    const color = '#4e33ff';

    const canvas = document.getElementById('markedCanvas') as HTMLCanvasElement;
    // const defaultWidth = canvas.width;
    const spaceToWriteText = 150;
    // canvas.width = canvas.width * 4 + 100;
    // canvas.width = 1300;
    const ctx = canvas.getContext('2d')!;

    const space = 10;

    const img1 = new Image();
    img1.crossOrigin = 'anonymous';
    img1.src = inputtedSignature as string;

    const img2 = new Image();
    img2.src = '/assets/logo/flowmono-logo-only.svg';

    // Function to draw images and text
    const drawImages = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      const halfHeight = (canvas.height - 2 * space) / 2;

      const imgAspectRatio = img1.width / img1.height;
      let imgWidth = 800;
      let imgHeight = imgWidth / imgAspectRatio;

      if (imgHeight > halfHeight) {
        // If the calculated height exceeds the available height, adjust the width instead
        imgHeight = halfHeight;
        imgWidth = imgHeight * imgAspectRatio;
      }

      const dx = (canvas.width - imgWidth) / 2;

      ctx.drawImage(img1, dx, 0, imgWidth, halfHeight);

      ctx.font = `${fontSize} Arial`;
      ctx.textBaseline = 'bottom';
      const text = 'Signature';
      const textWidth = ctx.measureText(text).width;
      const textX = 10;
      const textY = halfHeight + space + 8;

      ctx.lineWidth = 4;
      const lineX = textX + 10;
      ctx.beginPath();
      ctx.moveTo(lineX, textY);
      ctx.lineTo(canvas.width + 100, textY);
      ctx.stroke();

      ctx.drawImage(img2, 350 - textWidth / 2, textY + 16 + space, 100, 100);

      const smallText = `${new Date().toDateString()} ${new Date()
        .toTimeString()
        .slice(0, 5)}`;
      const smallTextWidth = ctx.measureText(smallText).width;
      const smallTextX = (canvas.width - smallTextWidth) / 2;
      const smallTextY = halfHeight + space + 16;
      ctx.font = `${fontSize} Arial`;
      ctx.fillStyle = color;
      ctx.fillText(
        smallText,
        smallTextX + spaceToWriteText - textWidth / 2,
        smallTextY + 100
      );

      const fullName = `${this.userData.fullName}`;
      const fullNameWidth = ctx.measureText(fullName).width;
      const fullNameX = (canvas.width - fullNameWidth) / 2;
      const fullNameY = halfHeight + space + 16;
      ctx.font = `${fontSize} Arial`;
      ctx.fillStyle = color;
      ctx.fillText(fullName, fullNameX + 150 - textWidth / 2, fullNameY + 170);

      const dataURL = canvas.toDataURL('image/png', 1.0);
      this.currentSignature = dataURL;
      // this.currentSignature = '';
      this.updateFormValues();
      this.onApplyToAllSignatures(this.currentSignature);

      // canvas.width = defaultWidth;
    };

    // Load both images before drawing
    Promise.all([
      new Promise<void>((resolve) => {
        img1.onload = () => resolve();
      }),
      new Promise<void>((resolve) => {
        img2.onload = () => resolve();
      }),
    ]).then(drawImages);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
