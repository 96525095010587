import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';

import * as fromApp from 'src/app/@core/stores/app/app.reducer';

import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';

import { select, Store } from '@ngrx/store';
import { HelperService } from 'src/app/@core/services/helper.service';
import { TwoFAVerificationType } from 'src/app/@core/enums';

import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-otp-verification',
  templateUrl: './otp-verification.component.html',
  styleUrls: ['./otp-verification.component.scss'],
})
export class OtpVerificationComponent implements OnInit, OnDestroy {
  otpForm: FormGroup;

  ipAddress: string;
  email: string;
  selectedMethod: 'email' | 'authenticator' = 'authenticator';

  hasAuthenticatorApp: boolean = false;
  emailToken: boolean = false;
  isEmailAuthenticator: boolean = false;
  showAuthenticationError: boolean = false;

  isSingleMethodAvailable: boolean = false;
  lastEmailTime: string | null;
  lastAuthenticatorTime: string | null;
  failedEmailAttempts: number = 0;
  failedAuthenticatorAttempts: number = 0;
  countdownDuration = 1800; // Total countdown duration in seconds (30 minutes)
  minutes: number = 0;
  seconds: number = 0;
  interval: any; // 30 minutes in seconds
  attempts: number = 0;

  emailTimeLeft: any;
  authenticatorTimeLeft: any;

  emailExhausted: boolean = false;
  authenticatorExhausted: boolean = false;

  user: any;
  item: any;

  showCountdown: boolean = false;

  twoFAVerificationType = TwoFAVerificationType;

  countdownDurationEmail: number = 1800; // Duration for email countdown
  countdownDurationAuthenticator: number = 1800; // Duration for authenticator countdown
  minutesEmail: number = 0;
  secondsEmail: number = 0;
  minutesAuthenticator: number = 0;
  secondsAuthenticator: number = 0;

  emailInterval: any;
  authenticatorInterval: any;

  private subscription: Subscription = new Subscription();
  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<OtpVerificationComponent>,
    private store: Store<fromApp.AppState>,
    private helperService: HelperService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.buildOtpForm();

    this.listenToGetUserTwoFADetails();
  }

  buildOtpForm() {
    this.otpForm = this.fb.group({
      otp1: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp2: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp3: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp4: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp5: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
      otp6: ['', [Validators.required, Validators.pattern('^[0-9]{1}$')]],
    });
  }

  moveFocus(event: KeyboardEvent, nextField: string, previousField?: string) {
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;

    if (inputValue && nextField && event.key !== 'Backspace') {
      // Move focus to next field if the current field has a value and the key is not Backspace
      const nextInput = document.querySelector(
        `input[formControlName=${nextField}]`
      ) as HTMLInputElement; // Cast to HTMLInputElement
      if (nextInput) {
        nextInput.focus();
      }
    } else if (event.key === 'Backspace' && !inputValue && previousField) {
      // Move focus to the previous field if the current field is empty and the key is Backspace
      const previousInput = document.querySelector(
        `input[formControlName=${previousField}]`
      ) as HTMLInputElement; // Cast to HTMLInputElement
      if (previousInput) {
        previousInput.focus();
        previousInput.value = ''; // Optionally clear the value in the previous input
      }
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onTryAnotherMethod() {
    this.emailToken = true;

    this.isEmailAuthenticator = true;
    this.showAuthenticationError = false;
  }

  onSelectionChange(event: MatRadioChange) {
    this.selectedMethod = event.value;

    if (this.selectedMethod === 'email') {
      this.otpForm[this.emailExhausted ? 'disable' : 'enable']();
    } else if (this.selectedMethod === 'authenticator') {
      this.otpForm[this.authenticatorExhausted ? 'disable' : 'enable']();
    }

    this.onSelect();
  }

  onSelect() {
    this.isEmailAuthenticator = false;

    // Reset the authentication error visibility
    this.showAuthenticationError = false;

    // Check if both methods are exhausted
    if (this.emailExhausted && this.authenticatorExhausted) {
      this.showAuthenticationError = true; // Show the error if both are exhausted
      return; // Stop further processing
    }

    // Handle the selected method
    if (this.selectedMethod === 'email') {
      // Check if the email method is exhausted
      if (this.emailExhausted) {
        this.showAuthenticationError = true; // Show the error if exhausted
        return; // Stop further processing
      }
      // Send OTP to email
      this.sendToOtp();
    } else if (this.selectedMethod === 'authenticator') {
      // Check if the authenticator method is exhausted
      if (this.authenticatorExhausted) {
        this.showAuthenticationError = true; // Show the error if exhausted
        return; // Stop further processing
      }
    }

    // Reset the OTP form if moving forward
    this.otpForm.reset();
  }

  onTryAgain() {
    if (this.selectedMethod === 'email') {
      if (this.emailExhausted) {
        this.showAuthenticationError = false;
        return;
      }
    } else if (this.selectedMethod === 'authenticator') {
      if (this.authenticatorExhausted) {
        this.showAuthenticationError = false;
        return;
      }
      // Perform any specific actions for the authenticator method if needed
    }
  }

  sendToOtp() {
    this.store.dispatch(
      AuthActions.SendOtpToEmail({
        payload: {
          email: this.data?.email,
        },
      })
    );
  }

  onSubmit() {
    if (this.otpForm.valid) {
      let deviceGuid = localStorage.getItem('deviceGuid');
      if (deviceGuid === undefined || deviceGuid === null) {
        deviceGuid = this.helperService.generateGUID();
        localStorage.setItem('deviceGuid', deviceGuid);
      }

      // Concatenate the OTP values into a single string
      const otpValues = Object.values(this.otpForm.value).join('');

      const verificationType =
        this.selectedMethod === 'email'
          ? TwoFAVerificationType.EmailAddress
          : TwoFAVerificationType.AuthenticatorApp;

      this.store.dispatch(
        AuthActions.OtpLogin({
          payload: {
            otp: otpValues,
            email: this.data.email,
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            ipAddress: this.data.ipAddress,
            deviceName: '',
            twoFAVerificationType: verificationType,
          },
        })
      );
    }
  }

  listenToGetUserTwoFADetails() {
    this.subscription.add(
      this.store
        .pipe(select(authSelectors.getUserTwoFADetails))
        .subscribe((resData: any) => {
          if (resData !== null) {
            const hasEmailTwoFA = resData.hasEmailTwoFA;
            const hasAuthenticatorApp = resData.hasAuthenticatorApp;

            this.isSingleMethodAvailable =
              Number(hasEmailTwoFA) + Number(hasAuthenticatorApp) === 1;

            this.failedAuthenticatorAttempts =
              resData.failedAuthenticatorOtpAttempts;
            this.lastAuthenticatorTime =
              resData.lastAuthenticatorOtpFailureTime;
            this.failedEmailAttempts = resData.failedEmailOtpAttempts;
            this.lastEmailTime = resData.lastEmailOtpFailureTime;

            this.emailTimeLeft = resData.timeLeftEmailAuthentication;
            this.authenticatorTimeLeft = resData.timeLeftAuthenticatorApp;

            this.minutesAuthenticator =
              resData.timeLeftAuthenticatorApp.minutes;
            this.secondsAuthenticator =
              resData.timeLeftAuthenticatorApp.seconds;
            this.minutesEmail = resData.timeLeftEmailAuthentication.minutes;
            this.secondsEmail = resData.timeLeftEmailAuthentication.seconds;

            // Logic for disabling methods based on attempts
            this.emailExhausted = this.checkExhaustion(
              this.failedEmailAttempts,
              this.lastEmailTime,
              this.minutesEmail,
              this.secondsEmail,
              this.authenticatorTimeLeft
            );
            this.authenticatorExhausted = this.checkExhaustion(
              this.failedAuthenticatorAttempts,
              this.lastAuthenticatorTime,
              this.minutesAuthenticator,
              this.secondsAuthenticator,
              this.emailTimeLeft
            );

            if (this.selectedMethod === 'email') {
              this.otpForm[this.emailExhausted ? 'disable' : 'enable']();
              if (this.emailExhausted) this.startEmailCountdown(); // Start email countdown if exhausted
            } else if (this.selectedMethod === 'authenticator') {
              this.otpForm[
                this.authenticatorExhausted ? 'disable' : 'enable'
              ]();
              if (this.authenticatorExhausted)
                this.startAuthenticatorCountdown(); // Start authenticator countdown if exhausted
            }
          }
        })
    );
  }

  checkExhaustion(
    failedAttempts: number,
    lastTime: string | null,
    minutesLeft: number,
    secondsLeft: number,
    authenticatorTimeLeft: any
  ): boolean {
    if (failedAttempts >= 5 && lastTime !== null) {
      if (!this.isCurrentTimeGreaterThanTargetBy30Minutes(lastTime)) {
        return true;
      }
    }
    return false;
  }

  startAuthenticatorCountdown() {
    this.countdownDurationAuthenticator =
      this.authenticatorTimeLeft.minutes * 60 +
      this.authenticatorTimeLeft.seconds;
    this.minutesAuthenticator = Math.floor(
      this.countdownDurationAuthenticator / 60
    );
    this.secondsAuthenticator = this.countdownDurationAuthenticator % 60;

    this.interval = setInterval(() => {
      if (this.countdownDurationAuthenticator > 0) {
        this.countdownDurationAuthenticator--;
        this.minutesAuthenticator = Math.floor(
          this.countdownDurationAuthenticator / 60
        );
        this.secondsAuthenticator = this.countdownDurationAuthenticator % 60;
      } else {
        clearInterval(this.authenticatorInterval);
        this.authenticatorExhausted = false; // Reset when time is up
        this.otpForm.enable(); // Enable the form if needed
      }
    }, 1000);
  }

  startEmailCountdown() {
    this.countdownDurationEmail =
      this.emailTimeLeft.minutes * 60 + this.emailTimeLeft.seconds;
    this.minutesEmail = Math.floor(this.countdownDurationEmail / 60);
    this.secondsEmail = this.countdownDurationEmail % 60;

    this.interval = setInterval(() => {
      if (this.countdownDurationEmail > 0) {
        this.countdownDurationEmail--;
        this.minutesEmail = Math.floor(this.countdownDurationEmail / 60);
        this.secondsEmail = this.countdownDurationEmail % 60;
      } else {
        clearInterval(this.emailInterval);
        this.emailExhausted = false;
        this.otpForm.enable();
      }
    }, 1000);
  }

  clearEmailCountdown() {
    if (this.emailInterval) {
      clearInterval(this.emailInterval);
    }
    this.emailExhausted = false;
    this.otpForm.enable();
  }

  clearAuthenticatorCountdown() {
    if (this.authenticatorInterval) {
      clearInterval(this.authenticatorInterval);
    }
    this.authenticatorExhausted = false;
    this.otpForm.enable();
  }

  isCurrentTimeGreaterThanTargetBy30Minutes(targetDateTime: any) {
    const currentTime = new Date();
    const targetTime = new Date(targetDateTime);

    targetTime.setMinutes(targetTime.getMinutes() + 30);

    return currentTime > targetTime;
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    this.clearEmailCountdown();

    this.clearAuthenticatorCountdown();
  }
}
