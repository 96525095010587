import { createReducer, on, Action } from '@ngrx/store';
import * as SubscriptionPlanActions from './subscription-plan.actions';

export interface State {
  isLoading: boolean;
  isFetching: boolean;
  allSubscriptionPlans: any;
  allSubscriptionPricings: any;
  allActiveSubscriptionPlansForWebsite: any;
  allActiveSubscriptionPlans: any;
  allActiveCurrencies: any;
  currentSubscription: any;
  previousSubscription: any;
  subscriptionHistory: any;
  subscriptionPlanFeaturesBySubscriptionPlanId: any;
  // subscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel: any;
  subscriptionById: any;
  subscriptionPlanById: any;
  activeSubuscriptionFeatureForCorporateUser: any;
}

const initialState: State = {
  isLoading: false,
  isFetching: false,
  allSubscriptionPlans: null,
  allSubscriptionPricings: null,
  allActiveSubscriptionPlansForWebsite: null,
  allActiveSubscriptionPlans: null,
  allActiveCurrencies: null,
  currentSubscription: null,
  previousSubscription: null,
  subscriptionHistory: null,
  subscriptionPlanFeaturesBySubscriptionPlanId: null,
  // subscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel: null,
  subscriptionById: null,
  subscriptionPlanById: null,
  activeSubuscriptionFeatureForCorporateUser: null,
};

const subscriptionPlanReducerInternal = createReducer(
  initialState,
  on(SubscriptionPlanActions.ResetStore, (state) => ({
    ...initialState,
  })),
  on(SubscriptionPlanActions.IsLoading, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(SubscriptionPlanActions.IsFetching, (state, { payload }) => ({
    ...state,
    isLoading: payload,
  })),
  on(SubscriptionPlanActions.SaveAllSubscriptionPlans, (state, { payload }) => {
    return {
      ...state,
      allSubscriptionPlans: payload,
    };
  }),
  on(
    SubscriptionPlanActions.SaveAllSubscriptionPlanPricings,
    (state, { payload }) => {
      return {
        ...state,
        allSubscriptionPricings: payload,
      };
    }
  ),
  on(
    SubscriptionPlanActions.SaveActiveSubscriptionPlansForWebsite,
    (state, { payload }) => ({
      ...state,
      allActiveSubscriptionPlansForWebsite: payload,
    })
  ),
  on(
    SubscriptionPlanActions.SaveActiveSubscriptionPlans,
    (state, { payload }) => ({
      ...state,
      allActiveSubscriptionPlans: payload,
    })
  ),
  on(SubscriptionPlanActions.SaveActiveCurrencies, (state, { payload }) => ({
    ...state,
    allActiveCurrencies: payload,
  })),

  //////////////
  on(SubscriptionPlanActions.SaveAllSubscriptionPlan, (state, { payload }) => ({
    ...state,
    subscriptionPlan: payload,
  })),

  on(
    SubscriptionPlanActions.SaveCurrentSubscriptionPlan,
    (state, { payload }) => ({
      ...state,
      currentSubscription: payload,
    })
  ),
  on(
    SubscriptionPlanActions.SavePreviousSubscriptionPlan,
    (state, { payload }) => ({
      ...state,
      previousSubscription: payload,
    })
  ),
  on(
    SubscriptionPlanActions.SaveSubscriptionPlanHistory,
    (state, { payload }) => ({
      ...state,
      subscriptionHistory: payload,
    })
  ),
  on(
    SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanId,
    (state, { payload }) => ({
      ...state,
      subscriptionPlanFeaturesBySubscriptionPlanId: payload,
    })
  ),
  // on(
  //   SubscriptionPlanActions.SaveSubscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel,
  //   (state, { payload }) => ({
  //     ...state,
  //     subscriptionPlanFeaturesBySubscriptionPlanIdAndRoleAccessLevel: payload,
  //   })
  // ),
  on(SubscriptionPlanActions.SaveSubscriptionById, (state, { payload }) => ({
    ...state,
    subscriptionById: payload,
  })),

  on(SubscriptionPlanActions.SaveSubscriptionPlanById, (state, { payload }) => {
    return {
      ...state,
      subscriptionPlanById: payload,
    };
  }),
  on(
    SubscriptionPlanActions.SaveActiveSubscriptionPlanForCorporateUser,
    (state, { payload }) => ({
      ...state,
      activeSubuscriptionFeatureForCorporateUser: payload,
    })
  )
);

export function subscriptionPlanReducer(
  state: State | undefined,
  action: Action
) {
  return subscriptionPlanReducerInternal(state, action);
}
