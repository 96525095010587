import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Subscription } from 'rxjs';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import { AuthEffects } from 'src/app/@core/stores/auth/auth.effects';
import * as subscriptionSelectors from 'src/app/@core/stores/subscription-plan/subscription-plan.selectors';
import * as SubscriptionActions from 'src/app/@core/stores/subscription-plan/subscription-plan.actions';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as generalSelectors from 'src/app/@core/stores/general/general.selectors';
import {
  PricingPlanType,
  SubscriberTypeEnum,
  SubscriptionFrequency,
} from '../../@core/enums';
import { HelperService } from 'src/app/@core/services/helper.service';
import { getActiveSubscriptionPlanForRegistration } from 'src/app/@core/utils/helpers';
import { ThemeService } from 'src/app/@core/services/theme.service';

@Component({
  selector: 'app-linked-in-response',
  templateUrl: './linked-in-response.component.html',
  styleUrls: ['./linked-in-response.component.scss'],
})
export class LinkedInResponseComponent implements OnInit, OnDestroy {
  year!: number;
  linkedInToken = '';
  linkedInVerificationEffectSub!: Subscription;
  actionType!: string | null;
  linkedInState = '';
  subscription!: any;
  pricing: any;
  registrationType!: 'individual' | 'corporate';
  country: 'Nigeria' | 'Others' = 'Others';
  latitude: number = 0;
  longitude: number = 0;
  getActiveSubscriptionPlanForRegistration =
    getActiveSubscriptionPlanForRegistration;

  private _subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private store: Store<fromApp.AppState>,
    private router: Router,
    public dialog: MatDialog,
    private authEffects: AuthEffects,
    private helperService: HelperService,
    private themeService: ThemeService
  ) {}

  ngOnInit() {
    this.themeService.removeWebappThemes();

    this.getIpAddressDetails();

    this.listenToGetIpAddressDetailsSelector();

    this.getGeoLocation();

    this.getLinkedInCode();

    this.listenToAuthRegisterEffects();

    //this.actionType = localStorage.getItem('linkedInAction');

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getActiveSubscriptionPlans.bind(this)
    );
  }

  getIpAddressDetails() {
    this.store.dispatch(GeneralActions.GetIpAddressDetails());
  }

  listenToGetIpAddressDetailsSelector() {
    this._subscription.add(
      this.store
        .pipe(select(generalSelectors.getIpAddressDetails))
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.country = resData?.country_name;
          }
        })
    );
  }

  getGeoLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  getLinkedInCode() {
    this.linkedInToken = this.route.snapshot.queryParams['code'];
    this.linkedInState = this.route.snapshot.queryParams['state'];

    if (this.linkedInToken && this.linkedInState) {
      if (
        localStorage.getItem('linkedInAction') !== null &&
        localStorage.getItem('linkedInAction') === 'Login'
      ) {
        this.store.dispatch(
          AuthActions.LinkedInSignIn({
            payload: {
              state: this.linkedInState,
              code: this.linkedInToken,
              keep_me_logged_in: true,
            },
          })
        );
      } else {
        // this.store.dispatch(
        //   AuthActions.LinkedInVerification({
        //     payload: {
        //       state: this.linkedInState,
        //       code: this.linkedInToken,
        //     },
        //   })
        // );

        this.registrationType = 'individual';

        this.manageGetActiveSubscriptionPlans();
      }
    } else {
      this.store.dispatch(AuthActions.IsLoading({ payload: false }));

      this.router.navigate(['/login']);
    }
  }

  manageGetActiveSubscriptionPlans() {
    this._subscription.add(
      this.store
        .pipe(
          select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)
        )
        .subscribe((resData) => {
          // if (resData !== null) {
          //   this.subscription = this.getActiveSubscriptionPlanForRegistration(
          //     resData
          //     // this.registrationType
          //   );

          //   this.subscription?.subscriptionPlanPricings?.forEach(
          //     (subscriptionPlanPricing: any) => {
          //       if (
          //         this.country === 'Nigeria' &&
          //         subscriptionPlanPricing.currencyCode === 'NGN' &&
          //         subscriptionPlanPricing.pricingPlanType ===
          //           PricingPlanType.monthly
          //       ) {
          //         this.pricing = subscriptionPlanPricing;
          //       } else if (
          //         this.country === 'Others' &&
          //         subscriptionPlanPricing.currencyCode === 'USD' &&
          //         subscriptionPlanPricing.pricingPlanType ===
          //           PricingPlanType.monthly
          //       ) {
          //         this.pricing = subscriptionPlanPricing;
          //       }
          //     }
          //   );

          //   this.linkedInThirdPartyDispatch(
          //     this.linkedInState,
          //     this.linkedInToken
          //   );
          // }
          // //  else {
          // //   this.helperService.manageDeveloperTokenAndCallFunction(
          // //     this.getActiveSubscriptionPlans.bind(this)
          // //   );
          // // }

          if (resData !== null) {
            this.subscription = getActiveSubscriptionPlanForRegistration(
              resData
              // this.registrationType
            );

            this.subscription?.subscriptionPlanPricings?.forEach(
              (subscriptionPlanPricing: any) => {
                if (
                  this.country === 'Nigeria' &&
                  subscriptionPlanPricing.currencyCode === 'NGN' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                } else if (
                  this.country === 'Others' &&
                  subscriptionPlanPricing.currencyCode === 'USD' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                }
              }
            );

            this.linkedInThirdPartyDispatch(
              this.linkedInState,
              this.linkedInToken
            );
          }
        })
    );
  }

  getActiveSubscriptionPlans() {
    this.store.dispatch(
      SubscriptionActions.GetActiveSubscriptionPlansForWebsite()
    );
  }

  linkedInThirdPartyDispatch(state: any, code: any) {
    /*
    this.store.dispatch(
      AuthActions.RegisterStart({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            subscriberName: '',
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType: this.subscription?.subscriptionType,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            // numberOfUsers:
            //   this.subscription?.subscriptionType === 2
            //     ? // ? this.registrationForm.value.no_of_users
            //       1
            //     : 1,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            name: '',
            subscriberType: SubscriberTypeEnum.individual,
            phoneNumber: '',
            city: '',
            themeColor: '',
            state: state,
            country: '',
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
            companyName: '',
            contactEmail: '',
            subscriberCode: code,
            staffSize: '',
            industry: '',
            location: '',
            referrer: 5,
            subscriptionPurpose: '',
            address: '',
            firstName: 'individual',
            lastName: 'individual',
            password: '',
            email: 'individual',
            jobTitle: '',
            invitedRecipients: null,
            profilePicture: '',
            rcNumber: null,
            companyDocument: null,
            allowUsersOutsideDomainToJoinOrganization: false,
            deviceType: 0,
          },
        },
      })
    );
    **/

    this.store.dispatch(
      AuthActions.StartRegistration({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            subscriberName: '',
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType: this.subscription?.subscriptionType,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            subscriberType: SubscriberTypeEnum.individual,
            contactEmail: '',
            subscriberCode: '',
            thirdPartyType: 0,
            firstName: 'individual',
            lastName: 'individual',
            email: 'individual',
            deviceId: '',
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
            deviceLocation: '',
            ipAddress: '',
            deviceName: '',
            browser: '',
            deviceType: 0,
            updateSubscriber: true,
            profilePicture: '',
            referrer: 5,
          },
        },
      })
    );
  }

  listenToAuthRegisterEffects() {
    this._subscription.add(
      this.authEffects.authRegister$.subscribe((resData: any) => {
        if (resData !== null) {
          if (resData.succeeded === true) {
            const email = resData?.entity?.user?.email;

            if (email) {
              this.router.navigate([`register/welcome-onboard/${email}`]);
            }
            // this.openSignupCompletedModal();
          }
        }
      })
    );
  }

  // listenToGetAllActiveSubscriptionPlansForWebsite() {
  //   this.subscription.add(
  //     this.store
  //       .pipe(
  //         select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)
  //       )
  //       .subscribe((resData) => {
  //         if (resData !== null) {
  //           this.subscription = getActiveSubscriptionPlanForRegistration(
  //             resData
  //             // this.registrationType
  //           );

  //           this.subscription?.subscriptionPlanPricings?.forEach(
  //             (subscriptionPlanPricing: any) => {
  //               if (
  //                 this.country === 'Nigeria' &&
  //                 subscriptionPlanPricing.currencyCode === 'NGN' &&
  //                 subscriptionPlanPricing.pricingPlanType ===
  //                   PricingPlanType.monthly
  //               ) {
  //                 this.pricing = subscriptionPlanPricing;
  //               } else if (
  //                 this.country === 'Others' &&
  //                 subscriptionPlanPricing.currencyCode === 'USD' &&
  //                 subscriptionPlanPricing.pricingPlanType ===
  //                   PricingPlanType.monthly
  //               ) {
  //                 this.pricing = subscriptionPlanPricing;
  //               }
  //             }
  //           );
  //         }
  //         // else {
  //         //   this.helperService.manageDeveloperTokenAndCallFunction(
  //         //     this.getActiveSubscriptionPlans.bind(this)
  //         //   );
  //         // }
  //       })
  //   );
  // }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
