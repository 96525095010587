import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import {
  PricingPlanType,
  // SubscriberTypeEnum,
  // SubscriptionFrequency,
  // SubscriptionPlanTypeEnum,
} from 'src/app/@core/enums';
import {
  getActiveSubscriptionPlanForRegistration,
  regexValidator,
} from 'src/app/@core/utils/helpers';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as SubscriptionActions from 'src/app/@core/stores/subscription-plan/subscription-plan.actions';
import * as subscriptionSelectors from 'src/app/@core/stores/subscription-plan/subscription-plan.selectors';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import { Store } from '@ngrx/store';
import { HelperService } from 'src/app/@core/services/helper.service';
import { AuthEffects } from 'src/app/@core/stores/auth/auth.effects';

@Component({
  selector: 'app-additional-information',
  templateUrl: './additional-information.component.html',
  styleUrls: ['./additional-information.component.scss'],
})
export class AdditionalInformationComponent implements OnInit, OnDestroy {
  isAuthLoading!: Observable<boolean>;
  isSubscriptionLoading!: Observable<boolean>;
  isFromDeveloperWebsite: boolean = false;
  page = 1;
  year!: number;
  show: boolean = false;

  latitude: number = 0;
  longitude: number = 0;

  email!: string;
  token!: string;
  subscriberId!: string;
  userId!: string;

  subscription!: any;
  pricing: any;
  country: 'Nigeria' | 'Others' = 'Others';

  // Password validations
  uppercasePattern = new RegExp('^(?=.*?[A-Z])');
  lowercasePattern = new RegExp('(?=.*?[a-z])');
  numberPattern = new RegExp('(?=.*?[0-9])');
  specialCharPattern = new RegExp('(?=.*?[#?!@$%^&*-])');

  registrationForm!: FormGroup;

  private _subscription = new Subscription();

  constructor(
    private store: Store<fromApp.AppState>,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private helperService: HelperService,
    private authEffects: AuthEffects
  ) {
    this._subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.checkForParams();

    this.createRegistrationForm();

    this.isAuthLoading = this.store.select(authSelectors.getAuthIsLoading);
    this.createRegistrationForm();

    this.year = new Date().getFullYear();

    this.isSubscriptionLoading = this.store.select(
      subscriptionSelectors.getSubscriptionPlanIsLoading
    );

    this.getLocation();
    this.listenToGetAllActiveSubscriptionPlansForWebsite();

    this.getActiveSubscriptionPlans();

    this.checkRegistrationSuccess();
  }

  checkForParams() {
    this.route.queryParams.subscribe((params) => {
      const email = params['email'];
      const token = params['token'];
      const subscriberId = params['subscriberId'];
      const userId = params['userId'];

      if (email && token && subscriberId && userId) {
        this.email = email;
        this.token = token;
        this.userId = userId;
        this.subscriberId = subscriberId;
        //
      } else {
        this.router.navigate(['/login']);
      }
    });
  }

  createRegistrationForm() {
    this.registrationForm = this.fb.group({
      password: [
        null,
        [
          Validators.required,
          regexValidator(new RegExp('^(?=.*?[A-Z])'), {
            uppercaseLetter: true,
          }),
          regexValidator(new RegExp('(?=.*?[a-z])'), { lowercaseLetter: true }),
          regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
          regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
            specialCharacter: true,
          }),
          regexValidator(new RegExp('.{8,}$'), { minimum: true }),
          // Validators.pattern(
          //   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
          // ),
        ],
      ],

      repeatPassword: [null, [Validators.required]],

      firstname: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      lastname: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      phone_number: [
        '',
        [Validators.required, Validators.pattern('^[+0-9]{7,15}$')],
      ],

      company_name: [null, [Validators.pattern('^[A-Za-z0-9\\s]+$')]],
    });
  }

  get registrationFormControls() {
    return this.registrationForm.controls;
  }

  get passwordValue() {
    return this.registrationForm.controls['password'].value;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'password' &&
      this.registrationFormControls.password.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    } else if (
      instance === 'firstname' &&
      this.registrationFormControls.firstname.hasError('required')
    ) {
      return 'Please enter your first name';
    } else if (
      instance === 'firstname' &&
      this.registrationFormControls.firstname.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'lastname' &&
      this.registrationFormControls.lastname.hasError('required')
    ) {
      return 'Please enter your last name';
    } else if (
      instance === 'lastname' &&
      this.registrationFormControls.lastname.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('required')
    ) {
      return 'Please enter your phone number';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('pattern')
    ) {
      return 'Incorrect phone number format';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('required')
    ) {
      return 'Please enter your company name';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('pattern')
    ) {
      return 'Incorrect format';
    } else {
      return;
    }
  }

  listenToGetAllActiveSubscriptionPlansForWebsite() {
    this._subscription.add(
      this.store
        .select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)

        .subscribe((resData) => {
          if (resData !== null) {
            this.subscription = getActiveSubscriptionPlanForRegistration(
              resData
              // this.registrationType
            );
            this.subscription?.subscriptionPlanPricings?.forEach(
              (subscriptionPlanPricing: any) => {
                if (
                  this.country === 'Nigeria' &&
                  subscriptionPlanPricing.currencyCode === 'NGN' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                } else if (
                  this.country === 'Others' &&
                  subscriptionPlanPricing.currencyCode === 'USD' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                }
              }
            );
          }
        })
    );
  }

  getActiveSubscriptionPlans() {
    this.store.dispatch(SubscriptionActions.IsLoading({ payload: true }));

    this.store.dispatch(
      SubscriptionActions.GetActiveSubscriptionPlansForWebsite()
    );
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    let deviceGuid = localStorage.getItem('deviceGuid');
    if (deviceGuid === undefined || deviceGuid === null) {
      deviceGuid = this.helperService.generateGUID();
      localStorage.setItem('deviceGuid', deviceGuid);
    }

    /*   this.store.dispatch(
      AuthActions.RegisterStart({
        payload: {
          subscription: {
            subscriberId: parseInt(this.subscriberId),
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            // subscriberName: this.pricing?.subscriberName,
            subscriberName: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType:
              this.subscription?.subscriptionType ??
              SubscriptionPlanTypeEnum.Standard,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            // numberOfUsers:
            //   this.subscription?.subscriptionType === 2
            //     ? this.subscription?.numberOfUsers
            //     : 1,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: this.userId,
            autoRenew: false,
          },
          subscriber: {
            name: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            subscriberType: SubscriberTypeEnum.individual,
            phoneNumber: this.registrationForm.value.phone_number,
            city: this.registrationForm.value.city,
            themeColor: '',
            state: this.registrationForm.value.state,
            country: this.registrationForm.value.country,
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
            companyName: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            contactEmail: this.email,
            subscriberCode: '',
            staffSize: `${this.registrationForm.value.staff_size}`,
            industry: this.registrationForm.value.industry,
            location: '',
            referrer: this.registrationForm.value.referrer,
            subscriptionPurpose:
              this.registrationForm.value.subscription_purpose,
            address: '',
            firstName: this.registrationForm.value.firstname,
            lastName: this.registrationForm.value.lastname,
            password: this.registrationForm.value.password,
            email: this.email!,
            jobTitle: this.registrationForm.value.job_title,
            invitedRecipients: null,
            profilePicture: this.registrationForm.value.profile_picture,
            rcNumber: null,
            companyDocument: null,
            allowUsersOutsideDomainToJoinOrganization: false,
            // rcNumber: this.registrationForm.value.rc_number,
            // companyDocument: this.registrationForm.value.company_document,
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            deviceId: '',
            deviceName: '',
            updateSubscriber: true,
            token: this.token!,
            deviceType: 0,
          },
          subscriberId: parseInt(this.subscriberId),
          userId: this.userId,
        },
      })
    ); */

    this.store.dispatch(
      AuthActions.CompleteRegistration({
        payload: {
          allowUsersOutsideDomainToJoinOrganization: false,
          token: this.token!,
          phoneNumber: this.registrationForm.value.phone_number,
          contactEmail: this.email,
          address: '',
          city: '',
          state: this.registrationForm.value.state,
          sessionId: '',
          deviceType: 0,
          name: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
          country: this.registrationForm.value.country,
          latitude: `${this.latitude}`,
          longitude: `${this.longitude}`,
          referrer: this.registrationForm.value.referrer,
          profilePicture: '',
          thirdPartyType: null,
          staffSize: 0,
          firstName: this.registrationForm.value.firstname,
          lastName: this.registrationForm.value.lastname,
          password: this.registrationForm.value.password,
          email: this.email!,
          companyName: `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
          deviceId: '',
          deviceName: '',
          browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
          invitedEmails: [],
          themeColor: '',
          themeColorCode: '',
          subscriberId: parseInt(this.subscriberId),
          userId: this.userId,
        },
      })
    );
  }

  checkRegistrationSuccess() {
    this._subscription.add(
      this.authEffects.authRegister$.subscribe((resData) => {
        if (resData.succeeded) {
          // this.router.navigate(['/login']);
          this.router.navigate(['/invite-team'], {
            queryParams: {
              email: this.email,
              subscriberId: this.subscriberId,
              userId: this.userId,
            },
          });
        }
      })
    );
  }

  ngOnDestroy(): void {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
