import { Injectable, Renderer2 } from '@angular/core';
// import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
// import { ControlBuilderComponent } from 'src/app/@components/editor/control-builder/control-builder.component';
// import { DefaultControlTypeStyles } from '../constants';
// import { ControlType } from '../enums';
import {
  CreateSignatureActionPayload,
  FmbrControl,
  FmbrControlCategory,
  FmbrControlType,
  FmbrProperty,
  SaveSignatureActionPayload,
  UpdateSignatureActionPayload,
} from '../types';
import {
  FmbrControlCategoryEnum,
  FmbrFillableControlTypeEnum,
  FmbrLayoutControlTypeEnum,
  FmbrPageOrientationEnum,
  FmbrPageSizeEnum,
  FmbrPropertyTypeOptionEnum,
  FmbrShapesControlTypeEnum,
} from '../enums';
import {
  FmbrPropertiesData,
  FmbrPropertyData,
  FmbrPropertyInterface,
} from '../interfaces';
import { FmbrOptionSources } from '../constants';
// import { HelperService } from './helper.service';
// import { NotificationService } from './notification.service';

interface AnyObject {
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class FormBuilderService {
  onTouchStartInToolbar = new Subject<any>();
  addPageThumbnail = new Subject<MutationRecord>();
  removePageThumbnail = new Subject<MutationRecord>();
  updatePageThumbnail = new Subject<MutationRecord>();
  controlResize = new Subject();
  activeProperties = new BehaviorSubject<FmbrPropertiesData | null>(null);
  updateControlProperties = new Subject<FmbrPropertiesData>();
  updateControlProperty = new Subject<FmbrPropertyData>();
  updatePageProperties = new Subject<FmbrPropertiesData>();
  updatePageProperty = new Subject<FmbrPropertyData>();
  userProfile = new BehaviorSubject<any>(null);

  saveSignatureAction = new BehaviorSubject<SaveSignatureActionPayload | null>(
    null
  );
  createSignatureAction =
    new BehaviorSubject<CreateSignatureActionPayload | null>(null);
  updateSignatureAction =
    new BehaviorSubject<UpdateSignatureActionPayload | null>(null);

  defaultPageOrientation = FmbrPageOrientationEnum.Portrait;
  defaultPageSize = FmbrPageSizeEnum.A4;
  customPageWidth = '';
  customPageHeight = '';

  private signatures: string[] = [];
  gridColumnIndex = 0;
  currentPageInd = 0;

  analyticsData: any;

  constructor() {} // private helperService: HelperService // private notificationService: NotificationService, // private store: Store<fromApp.AppState>,

  genGraphUnitAndData(
    arr: number[],
    data: number,
    unitcallback: (unit: string) => void
  ) {
    const max = Math.max(...Object.values(arr));
    const conv = (n: number, u: string) => {
      if (u == 'h') {
        return n / (1000 * 60 * 60);
      } else if (u == 'm') {
        return n / (1000 * 60);
      }
      return n / 1000;
    };
    if (Math.floor(conv(max, 'h'))) {
      unitcallback('hr');
      return conv(data, 'h');
    }
    if (Math.floor(conv(max, 'm'))) {
      unitcallback('min');
      return conv(data, 'm');
    }
    unitcallback('sec');
    return conv(data, 's');
  }

  getFmbrControlMetaData({
    left,
    top,
    width,
    height,
    controlData,
    pageNumber,
    formId,
  }: {
    left: number;
    top: number;
    width: string | null;
    height: string | null;
    controlData: any;
    pageNumber: string;
    formId: string;
  }) {
    const control: FmbrControl = {
      id: controlData?.id || this.generateGUID(),
      controlCategory: controlData?.controlCategory,
      controlType: controlData?.controlType,
      name: !controlData?.name?.includes('_')
        ? `${controlData?.name?.replace(' ', '')}_${Date.now()}`
        : controlData?.name,
      coordinate: {
        position: 'absolute',
        width: width,
        height: height,
        transform: `translate3d(${left}px, ${top}px, 0px)`,
      },
      value: controlData?.value ?? null,
      disabled: controlData?.disabled ?? false,
      pageNumber: pageNumber,
      formId: formId,
      parentControl: controlData?.parentControl ?? null,
      columnIndex: controlData?.columnIndex ?? null,
      logic: controlData?.logic ?? null,
      properties:
        controlData?.properties ??
        this.getControlProperties(
          controlData?.controlCategory,
          controlData?.controlType
        ),
    };

    // let control: any = {
    //   id: controlData?.id,
    //   type: controlData?.type,
    //   name: !controlData?.name?.includes('_')
    //     ? `${controlData?.name}_${Date.now()}`
    //     : controlData?.name,
    //   label: controlData?.label,
    //   value: controlData?.value,
    //   selectOptions: controlData?.selectOptions,
    //   validators: controlData?.validators,
    //   disabled: controlData?.disabled,
    //   coordinate: {
    //     position: 'absolute',
    //     width: width,
    //     height: height,
    //     transform: `translate3d(${left}px, ${top}px, 0px)`,
    //   },
    //   recipientId: controlData?.recipientId,
    //   recipientEmail: controlData?.recipientEmail,
    //   rank: 0,
    //   pageNumber: pageNumber,
    //   documentId: documentId,
    // };

    // if (
    //   controlData?.type === ControlType.text ||
    //   controlData?.type === ControlType.date ||
    //   controlData?.type === ControlType.name ||
    //   controlData?.type === ControlType.email ||
    //   controlData?.type === ControlType.jobTitle
    // ) {
    //   control = {
    //     ...control,
    //     additionalStyles: {
    //       'font-family': controlData?.additionalStyles?.['font-family']
    //         ? controlData?.additionalStyles['font-family']
    //         : DefaultControlTypeStyles[controlData?.type]['font-family'],

    //       'font-size': controlData?.additionalStyles?.['font-size']
    //         ? controlData?.additionalStyles['font-size']
    //         : DefaultControlTypeStyles[controlData?.type]['font-size'],

    //       'font-weight': controlData?.additionalStyles?.['font-weight']
    //         ? controlData?.additionalStyles['font-weight']
    //         : DefaultControlTypeStyles[controlData?.type]['font-weight'],

    //       color: controlData?.additionalStyles?.['color']
    //         ? controlData?.additionalStyles['color']
    //         : DefaultControlTypeStyles[controlData?.type]['color'],

    //       'text-align': controlData?.additionalStyles?.['text-align']
    //         ? controlData?.additionalStyles['text-align']
    //         : DefaultControlTypeStyles[controlData?.type]['text-align'],
    //     },
    //   };
    // }

    return control;
  }

  getControlTextDescription(category: FmbrControlCategory, type: number) {
    let catEnum;
    switch (category) {
      case FmbrControlCategoryEnum.Fillable:
        catEnum = FmbrFillableControlTypeEnum;
        break;
      case FmbrControlCategoryEnum.Layout:
        catEnum = FmbrLayoutControlTypeEnum;
        break;
      default:
        catEnum = FmbrShapesControlTypeEnum;
        break;
    }
    return catEnum[type];
  }

  getControlProperties(
    controlCategory: FmbrControlCategory,
    controlType: FmbrControlType
  ): FmbrProperty[] {
    let properties: FmbrProperty[] = [];

    if (controlCategory === FmbrControlCategoryEnum.Layout) {
      if (controlType === FmbrLayoutControlTypeEnum.Image) {
        properties = [
          {
            groupLabel: 'Image upload details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Image',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Foreground Color',
                propertyType: 'color',
                propertyValue: '#f6f5ff',
              },
            ],
          },
          {
            groupLabel: 'Image type',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Allowable type',
                propertyType: 'select',
                propertyValue: ['jpeg', 'png'],
                selectMultipleOptions: [
                  { name: 'JPEG', value: 'jpeg' },
                  { name: 'PNG', value: 'png' },
                ],
              },
              {
                propertyName: 'Image size for upload(MB)',
                propertyType: 'input',
                propertyValue: 5,
                inputPropertyType: 'number',
              },
              {
                propertyName: 'Object Fit',
                propertyType: 'select',
                propertyValue: 'fill',
                selectOptions: [
                  { name: 'Fill', value: 'fill' },
                  { name: 'Contain', value: 'contain' },
                  { name: 'Cover', value: 'cover' },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrLayoutControlTypeEnum.Grid) {
        properties = [
          {
            groupLabel: 'Column',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Column',
                propertyType: 'column',
                propertyValue: 1,
              },
            ],
          },
          {
            groupLabel: 'Gap Settings',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Column Gap',
                propertyType: 'input',
                propertyValue: '10',
                inputPropertyType: 'number',
              },
            ],
          },
        ];
      } else if (controlType === FmbrLayoutControlTypeEnum.Section) {
        properties = [
          {
            groupLabel: 'Section details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Section title',
                propertyType: 'input',
                propertyValue: 'Section',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Stroke',
                propertyType: 'color',
                propertyValue: '#4E33FF',
              },
              {
                propertyName: 'Background Color',
                propertyType: 'color',
                propertyValue: '#FFFFFF',
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#64748B',
              },
            ],
          },
          {
            propertyName: 'Section visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Toggle help',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Help text',
            propertyType: 'input',
            propertyValue: '',
            inputPropertyType: 'text',
            propertyCanToggle: true,
            propertyToggleValue: true,
          },
        ];
      }
    } else if (controlCategory === FmbrControlCategoryEnum.Fillable) {
      if (controlType === FmbrFillableControlTypeEnum.Text) {
        properties = [
          {
            groupLabel: 'Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Text field',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'Text',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              // {
              //   propertyName: 'Text type',
              //   propertyType: 'select',
              //   propertyValue: 'text',
              //   selectOptions: [
              //     { name: 'Text', value: 'text' },
              //     { name: 'Number', value: 'number' },
              //   ],
              // },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Poppins, sans-serif',
                selectOptions: [
                  { name: 'Poppins', value: 'Poppins, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: "'Roboto Mono', monospace" },
                ],
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '12',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
              {
                propertyType: 'row',
                properties: [],
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
            ],
          },
          {
            groupLabel: 'Settings',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Multiline text', // Tobi, you can make this a button toggle, showing multi line and single line
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Set as required',
                propertyType: 'slideToggle',
                propertyValue: false,
              },
              {
                propertyName: 'Show Control MenuBar',
                propertyType: 'slideToggle',
                propertyValue: false,
              },
              // {
              //   propertyType: 'row',
              //   properties: [
              //     {
              //       propertyName: 'Min characters',
              //       propertyType: 'input',
              //       propertyValue: '',
              //       inputPropertyType: 'number',
              //     },
              //     {
              //       propertyName: 'Max characters',
              //       propertyType: 'input',
              //       propertyValue: '',
              //       inputPropertyType: 'number',
              //     },
              //   ],
              // },
            ],
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Email) {
        properties = [
          {
            groupLabel: 'Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Email address',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'fola@flowmono.com',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              // {
              //   propertyName: 'Text type',
              //   propertyType: 'select',
              //   propertyValue: 'text',
              //   selectOptions: [
              //     { name: 'Text', value: 'text' },
              //     { name: 'Number', value: 'number' },
              //   ],
              // },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Poppins, sans-serif',
                selectOptions: [
                  { name: 'Poppins', value: 'Poppins, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: "'Roboto Mono', monospace" },
                ],
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
            ],
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Name) {
        properties = [
          {
            groupLabel: 'Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Name',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'Name',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              // {
              //   propertyName: 'Text type',
              //   propertyType: 'select',
              //   propertyValue: 'text',
              //   selectOptions: [
              //     { name: 'Text', value: 'text' },
              //     { name: 'Number', value: 'number' },
              //   ],
              // },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Poppins, sans-serif',
                selectOptions: [
                  { name: 'Poppins', value: 'Poppins, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: "'Roboto Mono', monospace" },
                ],
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
            ],
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Date) {
        properties = [
          {
            groupLabel: 'Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Date',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'DD/MM/YYYY',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Date format',
                propertyType: 'select',
                propertyValue: 'dd/MM/YYYY',
                selectOptions: [
                  { name: 'DD/MM/YYYY', value: 'dd/MM/YYYY' },
                  { name: 'MM/DD/YYYY', value: 'MM/dd/YYYY' },
                  { name: 'YYYY/MM/DD', value: 'YYYY/MM/dd' },
                ],
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              // {
              //   propertyName: 'Text type',
              //   propertyType: 'select',
              //   propertyValue: 'text',
              //   selectOptions: [
              //     { name: 'Text', value: 'text' },
              //     { name: 'Number', value: 'number' },
              //   ],
              // },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Poppins, sans-serif',
                selectOptions: [
                  { name: 'Poppins', value: 'Poppins, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: "'Roboto Mono', monospace" },
                ],
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
            ],
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Rating) {
        properties = [
          {
            groupLabel: 'Rating Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Rating',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              {
                propertyName: 'Number of stars',
                propertyType: 'input',
                propertyValue: '5',
                inputPropertyType: 'number',
              },
              {
                propertyName: 'Rating direction',
                propertyType: 'select',
                propertyValue: 'row',
                selectOptions: [
                  { name: 'Horizontal', value: 'row' },
                  { name: 'Vertical', value: 'column' },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Select) {
        properties = [
          {
            groupLabel: 'Dropdown details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Dropdown title',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'Select from list',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
            ],
          },
          {
            groupLabel: 'Options list',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Option Source',
                propertyType: 'option',
                propertyValue: '',
                optionsList: ['Option 1'],
                optionSource: FmbrOptionSources,
                optionSourceValue: FmbrPropertyTypeOptionEnum.ManualInput,
              },
            ],
          },
          // {
          //   groupLabel: 'Typography',
          //   propertyType: 'group',
          //   isCollapsible: true,
          //   properties: [
          //     {
          //       propertyName: 'Typeface',
          //       propertyType: 'select',
          //       propertyValue: 'Arial, sans-serif',
          //       selectOptions: [
          //         { name: 'Arial', value: 'Arial, sans-serif' },
          //         { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
          //         { name: 'Monospace', value: 'monospace' },
          //       ],
          //     },
          //     {
          //       propertyType: 'row',
          //       properties: [
          //         {
          //           propertyName: 'Size',
          //           propertyType: 'input',
          //           propertyValue: '16',
          //           inputPropertyType: 'number',
          //         },
          //         {
          //           propertyName: 'Height',
          //           propertyType: 'input',
          //           propertyValue: '22',
          //           inputPropertyType: 'number',
          //         },
          //         {
          //           propertyName: 'Weight',
          //           propertyType: 'select',
          //           propertyValue: '400',
          //           selectOptions: [
          //             { name: 'Thin', value: '100' },
          //             { name: 'Extra Light', value: '200' },
          //             { name: 'Light', value: '300' },
          //             { name: 'Normal', value: '400' },
          //             { name: 'Semi Bold', value: '600' },
          //             { name: 'Bold', value: '700' },
          //             { name: 'Extra Bold', value: '900' },
          //           ],
          //         },
          //         {
          //           propertyName: 'Spacing',
          //           propertyType: 'input',
          //           propertyValue: '0',
          //           inputPropertyType: 'number',
          //         },
          //       ],
          //     },
          //     {
          //       propertyName: 'Alignment',
          //       propertyType: 'textAlignment',
          //       propertyValue: 'left',
          //     },
          //   ],
          // },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Signature) {
        properties = [
          {
            groupLabel: 'Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Signature',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'information',
                propertyValue: 'Double click here to sign',
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#4e33ff',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Arial, sans-serif',
                selectOptions: [
                  { name: 'Arial', value: 'Arial, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: 'monospace' },
                  {
                    name: 'Comforter Brush',
                    value: "'Comforter Brush', cursive",
                  },
                  { name: 'Italianno', value: "'Italianno', cursive" },
                  {
                    name: 'Edwardian Script ITC',
                    value: 'Edwardian Script ITC',
                  },
                  { name: 'Brush Script MT', value: 'Brush Script MT' },
                  { name: 'Freestyle Script', value: 'Freestyle Script' },
                  { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
                ],
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                ],
              },
              {
                propertyName: 'Visibility',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Set as required',
                propertyType: 'slideToggle',
                propertyValue: false,
              },
            ],
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Radio) {
        properties = [
          {
            groupLabel: 'Radio button details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Radio button',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Options arrangment',
                propertyType: 'select',
                propertyValue: 'horizontal',
                selectOptions: [
                  { name: 'Horizontal', value: 'horizontal' },
                  { name: 'Vertical', value: 'vertical' },
                ],
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },

              {
                propertyName: 'Theme',
                propertyType: 'select',
                propertyValue: 'row',
                selectOptions: [
                  { name: 'Primary', value: 'primary' },
                  { name: 'Accent', value: 'accent' },
                  { name: 'Warn', value: 'warn' },
                ],
              },
            ],
          },
          {
            groupLabel: 'Options list',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Option Source',
                propertyType: 'option',
                propertyValue: '',
                optionsList: ['Option 1'],
                optionSource: FmbrOptionSources,
                optionSourceValue: FmbrPropertyTypeOptionEnum.ManualInput,
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.PhoneNumber) {
        properties = [
          {
            groupLabel: 'Phone number details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Phone number',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'Enter phone number',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#4e33ff',
              },
              {
                propertyName: 'Region',
                propertyType: 'information',
                propertyValue: 'All',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Character type',
                propertyType: 'select',
                propertyValue: 'numeric',
                selectOptions: [
                  { name: 'Numeric', value: 'numeric' },
                  { name: 'Alpha Numeric', value: 'tel' },
                ],
              },
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Arial, sans-serif',
                selectOptions: [
                  { name: 'Arial', value: 'Arial, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: 'monospace' },
                  {
                    name: 'Comforter Brush',
                    value: "'Comforter Brush', cursive",
                  },
                  { name: 'Italianno', value: "'Italianno', cursive" },
                  {
                    name: 'Edwardian Script ITC',
                    value: 'Edwardian Script ITC',
                  },
                  { name: 'Brush Script MT', value: 'Brush Script MT' },
                  { name: 'Freestyle Script', value: 'Freestyle Script' },
                  { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
                ],
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            groupLabel: 'Padding Option',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Top',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Bottom',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Left',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Right',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Checkbox) {
        properties = [
          {
            groupLabel: 'Checkbox details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Checkbox label',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#4e33ff',
              },
              {
                propertyName: 'Options arrangment',
                propertyType: 'select',
                propertyValue: 'horizontal',
                selectOptions: [
                  { name: 'Horizontal', value: 'horizontal' },
                  { name: 'Vertical', value: 'vertical' },
                ],
              },
            ],
          },
          {
            groupLabel: 'Options list',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Option Source',
                propertyType: 'option',
                propertyValue: '',
                optionsList: ['Option 1'],
                optionSource: FmbrOptionSources,
                optionSourceValue: FmbrPropertyTypeOptionEnum.ManualInput,
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Time) {
        properties = [
          {
            groupLabel: 'Time details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Time',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Arial, sans-serif',
                selectOptions: [
                  { name: 'Arial', value: 'Arial, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: 'monospace' },
                  {
                    name: 'Comforter Brush',
                    value: "'Comforter Brush', cursive",
                  },
                  { name: 'Italianno', value: "'Italianno', cursive" },
                  {
                    name: 'Edwardian Script ITC',
                    value: 'Edwardian Script ITC',
                  },
                  { name: 'Brush Script MT', value: 'Brush Script MT' },
                  { name: 'Freestyle Script', value: 'Freestyle Script' },
                  { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
                ],
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '16',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            groupLabel: 'Padding Option',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Top',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Bottom',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Left',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Right',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: false,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Address) {
        properties = [
          {
            groupLabel: 'Address details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'Address',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'Placeholder',
                propertyType: 'input',
                propertyValue: 'Enter Address',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#4e33ff',
              },
              {
                propertyName: 'Region',
                propertyType: 'information',
                propertyValue: 'All',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: 'Arial, sans-serif',
                selectOptions: [
                  { name: 'Arial', value: 'Arial, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: 'monospace' },
                  {
                    name: 'Comforter Brush',
                    value: "'Comforter Brush', cursive",
                  },
                  { name: 'Italianno', value: "'Italianno', cursive" },
                  {
                    name: 'Edwardian Script ITC',
                    value: 'Edwardian Script ITC',
                  },
                  { name: 'Brush Script MT', value: 'Brush Script MT' },
                  { name: 'Freestyle Script', value: 'Freestyle Script' },
                  { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
                ],
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#000000',
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '12',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '22',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Weight',
                    propertyType: 'select',
                    propertyValue: '400',
                    selectOptions: [
                      { name: 'Thin', value: '100' },
                      { name: 'Extra Light', value: '200' },
                      { name: 'Light', value: '300' },
                      { name: 'Normal', value: '400' },
                      { name: 'Semi Bold', value: '600' },
                      { name: 'Bold', value: '700' },
                      { name: 'Extra Bold', value: '900' },
                    ],
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            groupLabel: 'Settings',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Multiline text',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Set as required',
                propertyType: 'slideToggle',
                propertyValue: false,
              },
              {
                propertyName: 'Show Control MenuBar',
                propertyType: 'slideToggle',
                propertyValue: false,
              },
              {
                propertyName: 'Visibility',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
            ],
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.File) {
        properties = [
          {
            groupLabel: 'File upload details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Label',
                propertyType: 'input',
                propertyValue: 'File',
                inputPropertyType: 'text',
                propertyCanToggle: true,
                propertyToggleValue: true,
              },
              {
                propertyName: 'File title',
                propertyType: 'input',
                propertyValue: '',
                inputPropertyType: 'text',
              },
              {
                propertyName: 'Foreground Color',
                propertyType: 'color',
                propertyValue: '#f6f5ff',
              },
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '#4e33ff',
              },
            ],
          },
          {
            groupLabel: 'File type',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Allowable type',
                propertyType: 'select',
                propertyValue: ['jpeg', 'pdf', 'png'],
                selectMultipleOptions: [
                  { name: 'JPEG', value: 'jpeg' },
                  { name: 'PDF', value: 'pdf' },
                  { name: 'PNG', value: 'png' },
                  { name: 'SVG', value: 'svg' },
                  { name: 'XLS', value: 'xls' },
                  { name: 'XLSX', value: 'xlsx' },
                  { name: 'DOC', value: 'doc' },
                  { name: 'DOCX', value: 'docx' },
                  { name: 'CSV', value: 'csv' },
                  { name: 'TXT', value: 'txt' },
                  { name: 'HTML', value: 'html' },
                  { name: 'XML', value: 'xml' },
                ],
              },
              {
                propertyName: 'File size for upload(MB)',
                propertyType: 'input',
                propertyValue: 5,
                inputPropertyType: 'number',
              },
            ],
          },
          {
            propertyName: 'File visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Open file as new document',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Toggle help',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Help text',
            propertyType: 'input',
            propertyValue: '',
            inputPropertyType: 'text',
            propertyCanToggle: true,
            propertyToggleValue: true,
          },
        ];
      } else if (controlType === FmbrFillableControlTypeEnum.Table) {
        properties = [
          {
            groupLabel: 'Table details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Table Header',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Header BG Color',
                propertyType: 'color',
                propertyValue: '#F6F5FF',
              },
              {
                propertyName: 'Background Color',
                propertyType: 'color',
                propertyValue: '#ffffff',
              },
              {
                propertyName: 'Number of Rows',
                propertyType: 'input',
                propertyValue: 2,
                inputPropertyType: 'number',
              },
              {
                propertyName: 'Number of Columns',
                propertyType: 'input',
                propertyValue: 4,
                inputPropertyType: 'number',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Typeface',
                propertyType: 'select',
                propertyValue: "'Greycliff CF', sans-serif",
                selectOptions: [
                  { name: 'Arial', value: 'Arial, sans-serif' },
                  { name: 'Greycliff', value: "'Greycliff CF', sans-serif" },
                  { name: 'Monospace', value: 'monospace' },
                  {
                    name: 'Comforter Brush',
                    value: "'Comforter Brush', cursive",
                  },
                  { name: 'Italianno', value: "'Italianno', cursive" },
                  {
                    name: 'Edwardian Script ITC',
                    value: 'Edwardian Script ITC',
                  },
                  { name: 'Brush Script MT', value: 'Brush Script MT' },
                  { name: 'Freestyle Script', value: 'Freestyle Script' },
                  { name: 'Lucida Handwriting', value: 'Lucida Handwriting' },
                ],
              },
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '#323A46',
              },
              {
                propertyName: 'Alignment',
                propertyType: 'textAlignment',
                propertyValue: 'left',
              },
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '12',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '14',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Spacing',
                    propertyType: 'input',
                    propertyValue: '0',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            groupLabel: 'Padding Option',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Top',
                    propertyType: 'input',
                    propertyValue: 10,
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Bottom',
                    propertyType: 'input',
                    propertyValue: 10,
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Left',
                    propertyType: 'input',
                    propertyValue: 10,
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Right',
                    propertyType: 'input',
                    propertyValue: 10,
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            groupLabel: 'Borders',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Horizontal',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Vertical',
                propertyType: 'slideToggle',
                propertyValue: true,
              },
              {
                propertyName: 'Border style',
                propertyType: 'select',
                propertyValue: 'solid',
                selectOptions: [
                  { name: 'Solid', value: 'solid' },
                  { name: 'Dashed', value: 'dashed' },
                  { name: 'Dotted', value: 'dotted' },
                ],
              },
              {
                propertyName: 'Stroke',
                propertyType: 'color',
                propertyValue: '#64748B',
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
          {
            propertyName: 'Set as required',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      }
    } else if (controlCategory === FmbrControlCategoryEnum.Shapes) {
      if (controlType === FmbrShapesControlTypeEnum.Rectangle) {
        properties = [
          {
            groupLabel: 'Rectangle Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      } else if (controlType === FmbrShapesControlTypeEnum.Square) {
        properties = [
          {
            groupLabel: 'Square Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      } else if (controlType === FmbrShapesControlTypeEnum.Star) {
        properties = [
          {
            groupLabel: 'Star Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Highlight Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      } else if (controlType === FmbrShapesControlTypeEnum.Circle) {
        properties = [
          {
            groupLabel: 'Circle Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Height',
                    propertyType: 'input',
                    propertyValue: '100',
                    inputPropertyType: 'number',
                  },
                  {
                    propertyName: 'Width',
                    propertyType: 'input',
                    propertyValue: '100',
                    inputPropertyType: 'number',
                  },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      } else if (controlType === FmbrShapesControlTypeEnum.Triangle) {
        properties = [
          {
            groupLabel: 'Triangle Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      } else if (controlType === FmbrShapesControlTypeEnum.Arrow) {
        properties = [
          {
            groupLabel: 'Triangle Details',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyName: 'Color',
                propertyType: 'color',
                propertyValue: '',
              },
            ],
          },
          {
            groupLabel: 'Typography',
            propertyType: 'group',
            isCollapsible: true,
            properties: [
              {
                propertyType: 'row',
                properties: [
                  {
                    propertyName: 'Size',
                    propertyType: 'input',
                    propertyValue: '100',
                    inputPropertyType: 'number',
                  },
                  // {
                  //   propertyName: 'Rotate',
                  //   propertyType: 'input',
                  //   propertyValue: '0',
                  //   inputPropertyType: 'number',
                  // },
                ],
              },
            ],
          },
          {
            propertyName: 'Visibility',
            propertyType: 'slideToggle',
            propertyValue: true,
          },
        ];
      }
    }

    return properties;
  }

  // async onReplaceDocument({ event, documentReplaceInputElement }: any) {
  //   const files = (event.target as HTMLInputElement).files!;

  //   if (files) {
  //     // if (validateFileType(files, ['application/pdf'])) {
  //     const documentsAreEncryptedPromises = Array.from(files).map(
  //       (file: any) => {
  //         // Return a promise per file
  //         return new Promise((resolve, reject) => {
  //           const reader = new FileReader();

  //           this.helperService.checkIfDocumentIsEncrypted(
  //             reader,
  //             file,
  //             resolve,
  //             reject
  //           );
  //         });
  //       }
  //     );

  //     // Wait for all promises to be resolved
  //     const documentsAreEncryptedResult: any = await Promise.all(
  //       documentsAreEncryptedPromises
  //     );

  //     if (
  //       documentsAreEncryptedResult.every(
  //         (res: any) => res.isDocumentEncrypted === false
  //       )
  //     ) {
  //       const continueReplaceDocumentPromises = documentsAreEncryptedResult.map(
  //         (item: any) => {
  //           // Return a promise per item
  //           return new Promise((resolve, reject) => {
  //             this.continueReplaceDocument(
  //               item.reader,
  //               item.file,
  //               resolve,
  //               reject
  //             );
  //           });
  //         }
  //       );

  //       // Wait for all promises to be resolved
  //       const continueReplaceDocumentResult: {
  //         file: File;
  //         base64String: string;
  //       }[] = await Promise.all(continueReplaceDocumentPromises);

  //       return continueReplaceDocumentResult;
  //       // this.replaceDocument(existingDocument, continueReplaceDocumentResult);
  //     } else {
  //       documentReplaceInputElement.nativeElement.value = null;

  //       const notification: Notification = {
  //         state: 'warning',
  //         message: 'Encrypted file: Please select an unencrypted file',
  //       };

  //       this.notificationService.openNotification(
  //         notification,
  //         'flwmn-notification-warning'
  //       );
  //     }
  //   } else {
  //     documentReplaceInputElement.nativeElement.value = null;

  //     //  message: 'Max file size is ...MB'
  //   }
  // }

  // continueReplaceDocument(
  //   reader: FileReader,
  //   file: any,
  //   resolve: any,
  //   reject: any
  // ) {
  //   reader.onload = async () => {
  //     try {
  //       const fullBase64String = reader.result!.toString();
  //       const base64String = fullBase64String.split(',');
  //       base64String;

  //       resolve({ file: file, base64String: base64String[1] });
  //     } catch (error: any) {
  //       const notification: Notification = {
  //         state: 'error',
  //         message: `${error.name}: ${error.message}`,
  //       };

  //       this.notificationService.openNotification(
  //         notification,
  //         'flwmn-notification-error'
  //       );

  //       reject(error);
  //     }
  //   };

  //   reader.readAsDataURL(file);
  // }

  getControlsActualCoordinates(
    controlData: any,
    relativePage: any,
    documentIndex: number,
    allDocuments: any[]
  ): {
    width: string;
    height: string;
    xPosition: string;
    yPosition: string;
    // transform: string;
  } {
    const originalPage = allDocuments[documentIndex].pages.find((page: any) => {
      return page.pageNumber === Number(controlData?.pageNumber);
    });

    const width =
      (this.pixelToNumber(originalPage!.width) *
        this.pixelToNumber(controlData?.coordinate.width)) /
      this.pixelToNumber(relativePage.width);

    const height =
      (this.pixelToNumber(originalPage!.height) *
        this.pixelToNumber(controlData?.coordinate.height)) /
      this.pixelToNumber(relativePage.height);

    const translate3dCoordinates = this.getTransformCoordinates(
      controlData?.coordinate.transform
    );

    const x =
      (this.pixelToNumber(originalPage!.width) * translate3dCoordinates.x) /
      this.pixelToNumber(relativePage.width);

    const y =
      (this.pixelToNumber(originalPage!.height) * translate3dCoordinates.y) /
      this.pixelToNumber(relativePage.height);

    // const transform = `translate3d(${x}px, ${y}px, 0px)`;

    return {
      width: `${width}px`,
      height: `${height}px`,
      xPosition: `${x}px`,
      yPosition: `${y}px`,
      // transform: `${transform}`,
    };
  }

  pixelToNumber(value: string): number {
    return parseFloat(value.replaceAll('"', '').replaceAll('px', ''));
  }

  pixelToRem(px: string): string {
    if (!px.endsWith('px')) {
      return px;
    }

    const pxValue = parseFloat(px);

    if (isNaN(pxValue)) {
      return px;
    }

    const remValue = pxValue / 16;

    return `${remValue}rem`;
  }

  pixelsToRems(pixels: string[]): string[] {
    return pixels.map((px) => this.pixelToRem(px));
  }

  generateGUID(): string {
    const s4 = (): string => {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    };
    return `${s4()}${s4()}-${s4()}-${s4()}-${s4()}-${s4()}${s4()}${s4()}`;
  }

  getTransformCoordinates(transform: string) {
    const transform3dCoordinates = transform
      .split('translate3d')[1]
      .replace('(', '')
      .replace(')', '')
      .split(',');

    const x = parseFloat(transform3dCoordinates[0].split('px')[0]);
    const y = parseFloat(transform3dCoordinates[1].split('px')[0]);
    const z = parseFloat(transform3dCoordinates[2].split('px')[0]);

    return { x, y, z };
  }

  /**
   * This function generates a controls clientCoordinates from
   * @param actualControlCoordinates which are the controls coordinates relative to the original page dimensions
   * and @param actualPageDimensions which are the original page dimensions
   * and @param clientPageDimensions which are the client page dimensions
   */
  generateControlClientCoordinates(
    actualControlCoordinates: {
      position: string;
      xPosition: string;
      yPosition: string;
      width: string;
      height: string;
    },
    actualPageDimensions: {
      width: string;
      height: string;
    },
    clientPageDimensions: {
      width: string;
      height: string;
    }
  ): {
    position: string;
    xPosition: string;
    yPosition: string;
    width: string;
    height: string;
  } {
    const xPosition: number =
      (this.pixelToNumber(clientPageDimensions.width) *
        this.pixelToNumber(actualControlCoordinates.xPosition)) /
      this.pixelToNumber(actualPageDimensions.width);

    const yPosition: number =
      (this.pixelToNumber(clientPageDimensions.height) *
        this.pixelToNumber(actualControlCoordinates.yPosition)) /
      this.pixelToNumber(actualPageDimensions.height);

    const width: number =
      (this.pixelToNumber(clientPageDimensions.width) *
        this.pixelToNumber(actualControlCoordinates.width)) /
      this.pixelToNumber(actualPageDimensions.width);

    const height: number =
      (this.pixelToNumber(clientPageDimensions.height) *
        this.pixelToNumber(actualControlCoordinates.height)) /
      this.pixelToNumber(actualPageDimensions.height);

    const clientCoordinates: {
      position: string;
      xPosition: string;
      yPosition: string;
      width: string;
      height: string;
    } = {
      position: actualControlCoordinates.position,
      xPosition: `${xPosition}px`,
      yPosition: `${yPosition}px`,
      width: `${width}px`,
      height: `${height}px`,
    };

    return clientCoordinates;
  }

  get doFlowmonoControlsExistOnDocument() {
    const flowmonoControls = document.querySelector('.flwmn-control');

    return flowmonoControls ? true : false;
  }

  get doAllFlowmonoControlsHaveActors() {
    const flowmonoControls = document.querySelectorAll('.flwmn-control');

    return Array.from(flowmonoControls).every((control) => {
      return (
        JSON.parse(control.getAttribute('data-control')!).recipientId !== 0
      );
    });
  }

  get doAllActiveFlowmonoControlsHaveValue() {
    const flowmonoControls = document.querySelectorAll('.flwmn-control');
    const activeFlowmonoControls: any[] = [];

    Array.from(flowmonoControls).forEach((control) => {
      if (
        JSON.parse(control.getAttribute('data-control')!).disabled === false
      ) {
        activeFlowmonoControls.push(control);
      }
    });

    return activeFlowmonoControls.every((control) => {
      return (
        JSON.parse(control.getAttribute('data-control')!).value !== null &&
        JSON.parse(control.getAttribute('data-control')!).value !== '' &&
        JSON.parse(control.getAttribute('data-control')!).value !== ' '
      );
    });
  }

  doAllFlowmonoControlsHaveAnyOfTheseActors(actorIds: number[]) {
    const tempObj: any = {};

    actorIds.forEach((actorId) => {
      const flowmonoControls = document.querySelectorAll('.flwmn-control');
      const tempControls: any = [];

      Array.from(flowmonoControls).forEach((control) => {
        if (
          JSON.parse(control.getAttribute('data-control')!).recipientId ===
          actorId
        ) {
          tempControls.push(control);
        }

        tempObj[actorId] = tempControls;
      });
    });

    const booleanResponse: boolean[] = [];

    for (const i in tempObj) {
      booleanResponse.push(tempObj[i].length > 0);
    }

    return booleanResponse.every((res) => res === true);
  }

  traverseObject(
    obj: AnyObject,
    predicate: (value: any, key?: string | number, obj?: AnyObject) => boolean
  ): AnyObject[] {
    const result: AnyObject[] = [];

    for (const key in obj) {
      if (Object.hasOwnProperty.call(obj, key)) {
        const value = obj[key];
        if (Array.isArray(value)) {
          // If the value is an array, recursively traverse it
          result.push(...this.traverseArray(value, predicate));
        } else if (typeof value === 'object' && value !== null) {
          // If the value is an object, recursively traverse its values
          result.push(...this.traverseObject(value, predicate));
        } else {
          // If the key-value pair matches the predicate, add it to the result
          if (predicate(value, key, obj)) {
            result.push(obj);
          }
        }
      }
    }

    return result;
  }

  fileIcon(type: string) {
    let file = '';
    if (type.includes('image')) {
      file = 'image';
    } else if (type.match(/spreadsheet|csv|excel/)) {
      file = 'excel';
    } else if (type.includes('pdf')) {
      file = 'pdf';
    } else if (type.match(/document|word/i)) {
      file = 'word';
    } else {
      file = 'unknown';
    }
    return `assets/icons/form-builder/analytics/files/${file}_icon.png`;
  }

  traverseArray(
    arr: any[],
    predicate: (value: any, key?: string | number, obj?: AnyObject) => boolean
  ): any[] {
    const result: any[] = [];

    for (const item of arr) {
      if (Array.isArray(item)) {
        // If the item is an array, recursively traverse it
        result.push(...this.traverseArray(item, predicate));
      } else if (typeof item === 'object' && item !== null) {
        // If the item is an object, recursively traverse its values
        result.push(...this.traverseObject(item, predicate));
      } else {
        // If the item matches the predicate, add it to the result
        if (predicate(item, undefined, arr)) {
          result.push(item);
        }
      }
    }

    return result;
  }

  getFormControlPartialPath(path: string[]) {
    let partialPath: string | null = null;

    if (path) {
      path.forEach((el, index) => {
        partialPath = index === 0 ? el : `${partialPath}.${el}`;
      });
    }

    return partialPath;
  }

  getFormControlFullPath(path: string[], property: FmbrPropertyInterface) {
    let fullPath: string = '';

    if (path) {
      path.forEach((el, index) => {
        fullPath = index === 0 ? el : `${fullPath}.${el}`;
      });

      fullPath = `${fullPath}.${property.propertyName}`;
    } else {
      fullPath = property.propertyName!;
    }

    return fullPath;
  }

  setStyleWithImportant(
    element: HTMLElement,
    style: string,
    styleValue: string,
    renderer: Renderer2
  ) {
    let currentStyle = element.getAttribute('style') || '';

    // Remove any existing declarations for the same style property
    const regex = new RegExp(`${style}\\s*:\\s*[^;]+;\\s*`, 'g');
    currentStyle = currentStyle.replace(regex, '');

    // Construct the new style declaration with !important
    const newStyle = `${style}: ${styleValue} !important;`;

    // Set the style attribute to the new style string
    // element.setAttribute('style', currentStyle + newStyle);

    renderer.setAttribute(element, 'style', currentStyle + newStyle);
  }

  setStylesWithImportant(
    element: HTMLElement,
    styles: { [key: string]: string },
    renderer: Renderer2
  ) {
    for (const style in styles) {
      if (Object.hasOwnProperty.call(styles, style)) {
        this.setStyleWithImportant(element, style, styles[style], renderer);
      }
    }
  }

  hexToRgb(hex: string, opacity: number): string | null {
    // Remove hash if present
    hex = hex.replace(/^#/, '');

    // Parse hex into RGB
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Validate opacity
    if (opacity < 0 || opacity > 100) {
      throw new Error('Opacity must be a value between 0 and 100.');
    }

    // Construct the RGB string
    const rgbString = `rgba(${r}, ${g}, ${b}, ${opacity / 100})`;

    return rgbString;
  }

  getSignatures(): string[] {
    return this.signatures;
  }

  addSignature(signature: string): void {
    this.signatures.push(signature);
  }

  // selfSignAndDownloadDocuments(
  //   instance: SignDocumentInstance,
  //   selectedDocument: any,
  //   selectedDocumentIndex: number,
  //   allDocuments: any[],
  //   signingRequestId: number,
  //   subscriberId: number,
  //   email: string,
  //   userId: string,
  //   bearerToken?: string
  // ) {
  //   let allDocumentsPayload: any[] = [];

  //   if (instance === 'selectedDocument') {
  //     const response: any = this.getAllDocumentAndControls(
  //       selectedDocument,
  //       selectedDocumentIndex,
  //       allDocuments
  //     );

  //     allDocumentsPayload = [
  //       {
  //         documentId: response?.document?.id,
  //         controls: response?.controls,
  //       },
  //     ];
  //   } else if (instance === 'allDocuments') {
  //     const response: any = [];

  //     allDocuments.forEach((documentEl, documentIndex) => {
  //       response.push(
  //         this.getAllDocumentAndControls(
  //           documentEl,
  //           documentIndex,
  //           allDocuments
  //         )
  //       );
  //     });

  //     response?.forEach((resEl: any) => {
  //       if (resEl?.length !== 0) {
  //         allDocumentsPayload.push({
  //           documentId: resEl?.document?.id,
  //           controls: [...resEl.controls],
  //         });
  //       }
  //     });
  //   }

  //   this.selfSignDocuments(
  //     allDocumentsPayload,
  //     signingRequestId,
  //     subscriberId,
  //     email,
  //     userId,
  //     bearerToken ? bearerToken : undefined
  //   );
  // }

  // getAllDocumentAndControls(
  //   documentEl: any,
  //   documentIndex: number,
  //   allDocuments: any[],
  //   includeDisabledControls: boolean = false
  // ) {
  //   let flowmonoControls: any = document.querySelectorAll(
  //     `[data-documentid='${documentEl.id}'] .flwmn-control`
  //   );

  //   if (includeDisabledControls === false) {
  //     flowmonoControls = Array.from(flowmonoControls)
  //       .map((controlEl) => {
  //         if (controlEl) {
  //           const controlData = JSON.parse(
  //             (controlEl as HTMLElement).getAttribute('data-control')!
  //           );

  //           if (controlData?.disabled === false) {
  //             return controlEl;
  //           }
  //         }
  //       })
  //       .filter((controlEl) => controlEl !== undefined);
  //   }

  //   const flowmonoControlsData: any[] = [];

  //   flowmonoControls.forEach((element: any) => {
  //     flowmonoControlsData.push(
  //       JSON.parse(element.getAttribute('data-control')!)
  //     );
  //   });

  //   const clientPageDimensions: {
  //     pageNumber: number;
  //     width: string;
  //     height: string;
  //   }[] = [];

  //   document
  //     .querySelectorAll(`[data-documentid='${documentEl.id}'] .flwmn-page`)
  //     ?.forEach((flowmonoPage: any) => {
  //       clientPageDimensions.push({
  //         pageNumber: parseInt(flowmonoPage.getAttribute('data-page-number')!),
  //         width: `${flowmonoPage.getBoundingClientRect().width}px`,
  //         height: `${flowmonoPage.getBoundingClientRect().height}px`,
  //       });
  //     });

  //   let resultFlowmonoControlsData: any;

  //   if (flowmonoControlsData) {
  //     resultFlowmonoControlsData = flowmonoControlsData.map((controlData) => {
  //       const clientPage = clientPageDimensions.find((page) => {
  //         return page.pageNumber === Number(controlData?.pageNumber);
  //       });

  //       const actualCoordinate = this.getControlsActualCoordinates(
  //         controlData,
  //         clientPage,
  //         documentIndex,
  //         allDocuments
  //       );

  //       const clientCoordinatesTranslate3dCoordinates =
  //         this.getTransformCoordinates(controlData?.coordinate.transform);

  //       const modifiedControl = {
  //         ...controlData,
  //         additionalStyles: controlData?.additionalStyles
  //           ? {
  //               fontFamily: controlData?.additionalStyles['font-family'],
  //               fontSize: controlData?.additionalStyles['font-size'],
  //               fontWeight: controlData?.additionalStyles['font-weight'],
  //               color: controlData?.additionalStyles['color'],
  //               textAlign: controlData?.additionalStyles['text-align'],
  //             }
  //           : null,
  //         actualCoordinate: {
  //           position: controlData?.coordinate.position,
  //           width: actualCoordinate.width,
  //           height: actualCoordinate.height,
  //           xPosition: actualCoordinate.xPosition,
  //           yPosition: actualCoordinate.yPosition,
  //         },
  //         clientCoordinate: {
  //           position: controlData?.coordinate.position,
  //           width: controlData?.coordinate.width,
  //           height: controlData?.coordinate.height,
  //           xPosition: `${clientCoordinatesTranslate3dCoordinates.x}px`,
  //           yPosition: `${clientCoordinatesTranslate3dCoordinates.y}px`,
  //         },
  //       };

  //       delete modifiedControl.coordinate;

  //       return modifiedControl;
  //     });

  //     return { document: documentEl, controls: resultFlowmonoControlsData };
  //   }
  // }

  // selfSignDocuments(
  //   allDocumentsPayload: any,
  //   signingRequestId: number,
  //   subscriberId: number,
  //   email: string,
  //   userId: string,
  //   bearerToken?: string
  // ) {
  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.SelfSignDocuments({
  //       payload: {
  //         signingRequestId: signingRequestId,
  //         subscriberId: subscriberId,
  //         email: email,
  //         allDocuments: allDocumentsPayload,
  //         userId: userId,
  //       },
  //       additionalPayload: bearerToken
  //         ? {
  //             bearerToken: bearerToken,
  //           }
  //         : undefined,
  //     })
  //   );
  // }

  // downloadDocuments(
  //   instance: DocumentDownloadInstance,
  //   selectedDocument: any,
  //   allDocuments: any[],
  //   signingRequestId: number,
  //   subscriberId: number,
  //   userId: string,
  //   bearerToken?: string
  // ) {
  //   const allDocumentIds: number[] = [];

  //   if (instance === 'selectedDocument') {
  //     allDocumentIds.push(selectedDocument?.id);
  //   } else if (instance === 'allDocuments') {
  //     allDocuments.forEach((document: any) => {
  //       allDocumentIds.push(document?.id);
  //     });
  //   }

  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.DownloadDocuments({
  //       payload: {
  //         signingRequestId: signingRequestId,
  //         documentIds: allDocumentIds,
  //         subscriberId: subscriberId,
  //         userId: userId,
  //       },
  //       additionalPayload: bearerToken
  //         ? {
  //             bearerToken: bearerToken,
  //           }
  //         : undefined,
  //     })
  //   );
  // }

  // shareSelfSignedDocuments({
  //   instance,
  //   selectedDocument,
  //   selectedDocumentIndex,
  //   allDocuments,
  //   signingRequestId,
  //   subscriberId,
  //   userId,
  //   bearerToken,
  //   recipientEmail,
  // }: {
  //   instance: SendDocumentsToRecipientsInstance;
  //   selectedDocument: any;
  //   selectedDocumentIndex: number;
  //   allDocuments: any[];
  //   signingRequestId: number;
  //   subscriberId: number;
  //   userId: string;
  //   bearerToken?: string;
  //   recipientEmail: string;
  // }) {
  //   // const allDocumentIds: number[] = [];

  //   // if (instance === 'selectedDocument') {
  //   //   allDocumentIds.push(selectedDocument?.id);
  //   // } else if (instance === 'allDocuments') {
  //   //   allDocuments.forEach((document: any) => {
  //   //     allDocumentIds.push(document?.id);
  //   //   });
  //   // }

  //   let allDocumentsPayload: any[] = [];

  //   if (instance === 'selectedDocument') {
  //     const response: any = this.getAllDocumentAndControls(
  //       selectedDocument,
  //       selectedDocumentIndex,
  //       allDocuments
  //     );

  //     allDocumentsPayload = [
  //       {
  //         documentId: response?.document?.id,
  //         controls: response?.controls,
  //       },
  //     ];
  //   } else if (instance === 'allDocuments') {
  //     const response: any = [];

  //     allDocuments.forEach((documentEl, documentIndex) => {
  //       response.push(
  //         this.getAllDocumentAndControls(
  //           documentEl,
  //           documentIndex,
  //           allDocuments
  //         )
  //       );
  //     });

  //     response?.forEach((resEl: any) => {
  //       if (resEl?.length !== 0) {
  //         allDocumentsPayload.push({
  //           documentId: resEl?.document?.id,
  //           controls: [...resEl.controls],
  //         });
  //       }
  //     });
  //   }

  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.ShareSelfSignedDocuments({
  //       payload: {
  //         signingRequestId: signingRequestId,
  //         controlRequests: allDocumentsPayload,
  //         recipientEmail: recipientEmail,
  //         allowRecipientsAddControls: false,
  //         signType: SignTypeEnum.SelfSign,
  //       },
  //     })
  //   );
  // }

  // saveAsDraft(
  //   allDocuments: any[],
  //   signingRequestId: number,
  //   subscriberId: number,
  //   userId: string,
  //   email: string,
  //   bearerToken?: string
  // ) {
  //   const allDocumentsPayload: any[] = [];
  //   const response: any = [];

  //   allDocuments.forEach((documentEl, documentIndex) => {
  //     response.push(
  //       this.getAllDocumentAndControls(
  //         documentEl,
  //         documentIndex,
  //         allDocuments,
  //         false
  //       )
  //     );
  //   });

  //   response?.forEach((resEl: any) => {
  //     if (resEl?.length !== 0) {
  //       allDocumentsPayload.push({
  //         documentId: resEl?.document?.id,
  //         controls: [...resEl.controls],
  //       });
  //     }
  //   });

  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.SaveAsDraft({
  //       payload: {
  //         subscriberId: subscriberId,
  //         signingRequestId: signingRequestId,
  //         email: email,
  //         documentControlsRequest: allDocumentsPayload,
  //         userId: userId,
  //       },
  //       additionalPayload: bearerToken
  //         ? {
  //             bearerToken: bearerToken,
  //           }
  //         : undefined,
  //     })
  //   );
  // }

  // sendToSignatories(
  //   allDocuments: any[],
  //   signingRequestId: number,
  //   subscriberId: number,
  //   userId: string,
  //   bearerToken?: string
  // ) {
  //   const allDocumentsPayload: any[] = [];
  //   const response: any = [];

  //   allDocuments.forEach((documentEl, documentIndex) => {
  //     response.push(
  //       this.getAllDocumentAndControls(documentEl, documentIndex, allDocuments)
  //     );
  //   });

  //   response?.forEach((resEl: any) => {
  //     if (resEl?.length !== 0) {
  //       allDocumentsPayload.push({
  //         documentId: resEl?.document?.id,
  //         controls: [...resEl.controls],
  //       });
  //     }
  //   });

  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.SendToSignatories({
  //       payload: {
  //         signingRequestId: signingRequestId,
  //         allDocuments: allDocumentsPayload,
  //         subscriberId: subscriberId,
  //         userId: userId,
  //       },
  //       additionalPayload: bearerToken
  //         ? {
  //             bearerToken: bearerToken,
  //           }
  //         : undefined,
  //     })
  //   );
  // }

  // onAcceptAndSignDocuments(
  //   allDocuments: any[],
  //   signingRequestId: number,
  //   recipientEmail: string,
  //   allowRecipientsAddControls: boolean = false,
  //   ipAddress: string,
  //   location: string
  // ) {
  //   const allDocumentsPayload: any[] = [];
  //   const response: any = [];

  //   allDocuments.forEach((documentEl, documentIndex) => {
  //     response.push(
  //       this.getAllDocumentAndControls(documentEl, documentIndex, allDocuments)
  //     );
  //   });

  //   response?.forEach((resEl: any) => {
  //     if (resEl?.length !== 0) {
  //       let controls: any[] = [...resEl.controls];

  //       if (allowRecipientsAddControls === true) {
  //         controls = controls.map((control) => {
  //           if (control.recipientEmail === null) {
  //             control.recipientEmail = recipientEmail;
  //           }

  //           return control;
  //         });
  //       }

  //       allDocumentsPayload.push({
  //         documentId: resEl?.document?.id,
  //         controls: controls,
  //       });
  //     }
  //   });

  //   this.helperService.manageDeveloperTokenAndCallFunction(
  //     this.acceptAndSignDocuments.bind(this, {
  //       controlRequestsPayload: allDocumentsPayload,
  //       signingRequestId: signingRequestId,
  //       recipientEmail: recipientEmail,
  //       allowRecipientsAddControls: allowRecipientsAddControls,
  //       ipAddress: ipAddress,
  //       location: location,
  //     })
  //   );
  // }

  // acceptAndSignDocuments({
  //   controlRequestsPayload,
  //   signingRequestId,
  //   recipientEmail,
  //   allowRecipientsAddControls,
  //   ipAddress,
  //   location,
  // }: {
  //   controlRequestsPayload: any[];
  //   signingRequestId: number;
  //   recipientEmail: string;
  //   allowRecipientsAddControls: boolean;
  //   ipAddress: string;
  //   location: string;
  // }) {
  //   this.store.dispatch(EditorActions.IsLoading({ payload: true }));

  //   this.store.dispatch(
  //     EditorActions.SignAndAccept({
  //       payload: {
  //         signingRequestId: signingRequestId,
  //         controlRequests: controlRequestsPayload,
  //         recipientEmail: recipientEmail,
  //         allowRecipientsAddControls: allowRecipientsAddControls,
  //         signType: SignTypeEnum.SignWithOthers,
  //         ipAddress: ipAddress,
  //         location: location,
  //       },
  //     })
  //   );
  // }
}
