<ng-container *ngIf="(isAuthLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<section id="new-register">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>

        <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container>
          <div class="auth-login-form-container">
            <div class="welcome-message">
              <ng-container *ngIf="page === 1">
                <p class="flwmn-heading-4 flwmn-text-black fw-700">
                  Provide additional information
                </p>

                <p class="flwmn-para-3 flwmn-text-black fw-400">
                  Help us to know you better
                </p>
              </ng-container>
            </div>

            <div class="auth-form-container">
              <form [formGroup]="registrationForm" (submit)="onSubmit()">
                <div class="registration-form-page-1">
                  <div class="registration-form-row">
                    <div class="input-style1 input-md auth-input-mb">
                      <label class="input-label">First name</label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <input
                          type="text"
                          matInput
                          formControlName="firstname"
                        />

                        <mat-error
                          *ngIf="registrationFormControls.firstname.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('firstname') }}
                        </mat-error>
                      </mat-form-field>
                    </div>

                    <div class="input-style1 input-md auth-input-mb">
                      <label class="input-label">Last name</label>

                      <mat-form-field
                        appearance="outline"
                        [style.width.%]="100"
                      >
                        <input
                          type="text"
                          matInput
                          formControlName="lastname"
                        />

                        <mat-error
                          *ngIf="registrationFormControls.lastname.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('lastname') }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="input-style1 input-md auth-input-mb">
                    <label class="input-label">Company name</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input
                        #email
                        type="text"
                        matInput
                        formControlName="company_name"
                        (input)="
                          registrationForm.patchValue({
                            company_name: email.value.replaceAll(' ', '')
                          })
                        "
                      />

                      <mat-error
                        *ngIf="registrationFormControls.company_name.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('company_name') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="input-style1 input-md auth-input-mb">
                    <label class="input-label">Contact number</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input
                        #individualPhoneNumber
                        type="tel"
                        inputmode="tel"
                        matInput
                        formControlName="phone_number"
                        (input)="
                          registrationForm.patchValue({
                            phone_number:
                              individualPhoneNumber.value.replaceAll(' ', '')
                          })
                        "
                      />
                      <mat-error
                        *ngIf="registrationFormControls.phone_number.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('phone_number') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <ng-template #visibleEyeIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </ng-template>

                  <ng-template #hiddenEyeIcon>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6.31325 9.68665L1.33325 14.6666"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M14.6668 1.33331L9.68677 6.31331"
                        stroke="var(--flwmn-primary-color)"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </ng-template>

                  <div class="input-style1 input-md auth-input-mb">
                    <label class="input-label">Password</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input
                        matInput
                        [type]="show ? 'text' : 'password'"
                        formControlName="password"
                      />

                      <span matSuffix class="img-fluid" (click)="show = !show">
                        <ng-container *ngIf="show">
                          <ng-container
                            *ngTemplateOutlet="visibleEyeIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngIf="!show">
                          <ng-container
                            *ngTemplateOutlet="hiddenEyeIcon"
                          ></ng-container>
                        </ng-container>
                      </span>

                      <mat-error
                        *ngIf="registrationFormControls.password.invalid"
                      >
                        <img
                          src="/assets/icons/error.svg"
                          alt=""
                          class="img-fluid error-icon"
                        />
                        {{ getErrorMessage('password') }}
                      </mat-error>
                    </mat-form-field>
                  </div>

                  <div class="input-style1 input-md auth-input-mb">
                    <label class="input-label">Repeat password</label>

                    <mat-form-field appearance="outline" [style.width.%]="100">
                      <input
                        matInput
                        [type]="show ? 'text' : 'password'"
                        formControlName="repeatPassword"
                      />

                      <span matSuffix class="img-fluid" (click)="show = !show">
                        <ng-container *ngIf="show">
                          <ng-container
                            *ngTemplateOutlet="visibleEyeIcon"
                          ></ng-container>
                        </ng-container>

                        <ng-container *ngIf="!show">
                          <ng-container
                            *ngTemplateOutlet="hiddenEyeIcon"
                          ></ng-container>
                        </ng-container>
                      </span>

                      <!-- <mat-error
                          *ngIf="registrationFormControls.password.invalid"
                        >
                          <img
                            src="/assets/icons/error.svg"
                            alt=""
                            class="img-fluid error-icon"
                          />
                          {{ getErrorMessage('repeatPassword') }}
                        </mat-error> -->
                    </mat-form-field>

                    <div class="check-box-wrapper mb-3">
                      <div class="password-check">
                        <span class="check-container">
                          <span
                            class="box-check"
                            [ngClass]="
                              uppercasePattern.test(passwordValue) &&
                              lowercasePattern.test(passwordValue)
                                ? 'check'
                                : ''
                            "
                          ></span>
                        </span>

                        <span>
                          <p class="flwmn-para-1 py-0 my-0">
                            Includes both lowercase (a-z) and uppercase (A-Z)
                            letters
                          </p>
                        </span>
                      </div>

                      <div class="password-check">
                        <span class="check-container">
                          <span
                            class="box-check"
                            [ngClass]="
                              numberPattern.test(passwordValue) ? 'check' : ''
                            "
                          ></span>
                        </span>

                        <span>
                          <p class="flwmn-para-1 py-0 my-0">
                            At least one numeric digit
                          </p>
                        </span>
                      </div>

                      <div class="password-check">
                        <span class="check-container">
                          <span
                            class="box-check"
                            [ngClass]="
                              specialCharPattern.test(passwordValue)
                                ? 'check'
                                : ''
                            "
                          ></span>
                        </span>

                        <span>
                          <p class="flwmn-para-1 py-0 my-0">
                            Minimum of 1 Special character
                          </p>
                        </span>
                      </div>

                      <div class="password-check">
                        <span class="check-container">
                          <span
                            class="box-check"
                            [ngClass]="passwordValue?.length > 7 ? 'check' : ''"
                          ></span>
                        </span>

                        <span>
                          <p class="flwmn-para-1 py-0 my-0">
                            Minimum of 8 Characters
                          </p>
                        </span>
                      </div>

                      <div class="password-check">
                        <span class="check-container">
                          <span
                            class="box-check"
                            [ngClass]="
                              passwordValue ===
                                registrationFormControls.repeatPassword.value &&
                              passwordValue
                                ? 'check'
                                : ''
                            "
                          ></span>
                        </span>

                        <span>
                          <p class="flwmn-para-1 py-0 my-0">
                            Check password match
                          </p>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <ng-template #visibleEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <ng-template #hiddenEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.31325 9.68665L1.33325 14.6666"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 1.33331L9.68677 6.31331"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <button
                  matRipple
                  type="submit"
                  class="btn flwmn-btn-linear-gradient flwmn-btn-md flwmn-btn-full login-btn rounded-pill mt-3"
                  [disabled]="registrationForm.invalid"
                >
                  Continue
                </button>
              </form>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>

    <!-- Carousel -->
    <div class="carouse-box">
      <app-auth-carousel></app-auth-carousel>
    </div>
  </div>
</section>
