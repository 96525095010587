<ng-container *ngIf="(isLoading | async) !== false">
  <div class="general-loader-container">
    <div class="general-loader-backdrop"></div>

    <div class="general-loader-content">
      <app-general-loader></app-general-loader>
    </div>
  </div>
</ng-container>

<div *ngIf="verifyStatus !== false" class="lazy-loading-module-loader">
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<!-- 
<section id="login">
  <div class="wallpaper-container">
    <div class="wallpaper-illustrations-container">
      <img
        src="/assets/images/auth/auth-wallpaper-illustration-left.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-left"
      />

      <img
        src="/assets/images/auth/auth-wallpaper-illustration-right.svg"
        alt="Flowmono wallpaper illustration"
        class="illustration-right"
      />
    </div>
  </div>

  <div class="page">
    <div class="page-inner">
      <div class="body">
        <div class="logo-container">
          <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>

          <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo-white.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>
        </div>

        <ng-container
          *ngIf="verifyStatus === false && isDeviceVerified === false"
        >
          <div class="content-section">
            <div class="form-section">
              <h5 class="flwmn-heading-5 fw-700 header-text">Welcome back!</h5>

              <form [formGroup]="loginForm" (submit)="onSubmit()">
                <div class="input-style1 input-md mb-1">
                  <label class="input-label">Email Address</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      #email
                      type="email"
                      inputmode="email"
                      matInput
                      formControlName="email"
                      (input)="
                        loginForm.patchValue({
                          email: email.value.replaceAll(' ', '')
                        })
                      "
                    />

                    <mat-error *ngIf="loginFormControls.email.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('email') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <ng-template #visibleEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <ng-template #hiddenEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.31325 9.68665L1.33325 14.6666"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 1.33331L9.68677 6.31331"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <div class="input-style1 input-md mb-1">
                  <label class="input-label">Password</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      matInput
                      [type]="show ? 'text' : 'password'"
                      formControlName="password"
                    />

                    <span matSuffix class="img-fluid" (click)="show = !show">
                      <ng-container *ngIf="show">
                        <ng-container
                          *ngTemplateOutlet="visibleEyeIcon"
                        ></ng-container>
                      </ng-container>

                      <ng-container *ngIf="!show">
                        <ng-container
                          *ngTemplateOutlet="hiddenEyeIcon"
                        ></ng-container>
                      </ng-container>
                    </span>

                    <mat-error *ngIf="loginFormControls.password.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('password') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <a
                  routerLink="/forgot-password"
                  class="flwmn-caption forgot-password-text"
                  >Forgot password?
                </a>

                <button
                  matRipple
                  type="submit"
                  class="btn flwmn-btn-primary flwmn-btn-md flwmn-btn-full login-btn"
                  [disabled]="loginForm.invalid"
                >
                  Login
                </button>

                <div class="third-party-container">
                  <p class="flwmn-caption fw-600 mb-0 alternate-login-text">
                    Or login with:
                  </p>

                  <div class="third-party-options">
                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithGoogle()"
                      matTooltip="Sign in with Google"
                    >
                      <img
                        src="assets/icons/auth/google-third-party-auth.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </div>

                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithLinkedIn()"
                      matTooltip="Sign in with LinkedIn"
                    >
                      <img
                        src="assets/icons/auth/linkedin-third-party-auth.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </div>

                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithMicrosoft()"
                      matTooltip="Sign in with Microsoft"
                    >
                      <img
                        src="assets/icons/auth/azure-ad-third-party-auth.svg"
                        alt=""
                        class="img-fluid"
                      />
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="isFromDeveloperWebsite">
                  <p class="flwmn-para-1 bottom-text">
                    You can
                    <a
                      [routerLink]="['/register']"
                      [queryParams]="{ developer: true }"
                      >Sign Up</a
                    >
                    for free if you are new here
                  </p>
                </ng-container>

                <ng-container *ngIf="!isFromDeveloperWebsite">
                  <p class="flwmn-para-1 bottom-text">
                    You can <a [routerLink]="['/register']">Sign Up</a> for free
                    if you are new here
                  </p>
                </ng-container>
              </form>
            </div>

            <div class="illustration-section">
              <img
                src="/assets/images/auth/auth-right-illustration-1.png"
                alt="Illustration Image"
                class="illustration"
              />
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="verifyStatus !== false || isDeviceVerified === true"
        >
          <div class="content-section-verify">
            <div class="form-section">
              <div class="device-verification-image-container">
                <img
                  src="/assets/icons/auth/password.svg"
                  alt="Device Verification"
                />
              </div>

              <h5 class="flwmn-heading-5 fw-700 header-text">
                Device Verification
              </h5>

              <p class="flwmn-para-1 subheader-text">
                We noticed you are logging into a new device! We have sent an
                email to you for confirmation.
              </p>

              <div>
                <h5
                  class="flwmn-heading-5 fw-700 header-text flwmn-text-primary"
                >
                  {{ minutes }}:{{ seconds | number: '2.0' }}
                </h5>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <footer>
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>
  </div>
</section>
 -->
<section id="new-login">
  <div class="logo-overlay-container">
    <div class="img-positioning-container">
      <app-svg
        src="/assets/images/auth/left-illustration.svg"
        svgPathStroke="#e8d4ff"
        svgPathFill="#e8d4ff"
        class="logo-illustration-left"
      ></app-svg>
    </div>
  </div>

  <div class="all-content-container">
    <div class="main-login hide-scroll-bars">
      <div class="logo-container">
        <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo White"
            class="logo"
          />
        </a>

        <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
          <img
            src="/assets/logo/flowmono-logo.svg"
            alt="Flowmono Logo"
            class="logo"
          />
        </a>
      </div>

      <div class="login-page-content-inner">
        <ng-container
          *ngIf="verifyStatus === false && isDeviceVerified === false"
        >
          <div class="auth-login-form-container">
            <div class="welcome-message">
              <p class="flwmn-heading-4 flwmn-text-black fw-700">
                Welcome back!
              </p>
              <span>
                <app-svg src="/assets/images/auth/hand-wave.svg"></app-svg>
              </span>
            </div>

            <div class="auth-form-container">
              <form [formGroup]="loginForm" (submit)="onSubmit()">
                <div class="input-style1 input-md auth-input-mb">
                  <label class="input-label">Email address</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      #email
                      type="email"
                      inputmode="email"
                      matInput
                      formControlName="email"
                      (input)="
                        loginForm.patchValue({
                          email: email.value.replaceAll(' ', '')
                        })
                      "
                    />

                    <mat-error *ngIf="loginFormControls.email.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('email') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <ng-template #visibleEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.3866 8.00001C10.3866 9.32001 9.31995 10.3867 7.99995 10.3867C6.67995 10.3867 5.61328 9.32001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C9.31995 5.61334 10.3866 6.68001 10.3866 8.00001Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.9999 13.5133C10.3532 13.5133 12.5466 12.1266 14.0732 9.72665C14.6732 8.78665 14.6732 7.20665 14.0732 6.26665C12.5466 3.86665 10.3532 2.47998 7.9999 2.47998C5.64656 2.47998 3.45323 3.86665 1.92656 6.26665C1.32656 7.20665 1.32656 8.78665 1.92656 9.72665C3.45323 12.1266 5.64656 13.5133 7.9999 13.5133Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <ng-template #hiddenEyeIcon>
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.68661 6.31334L6.31328 9.68668C5.87995 9.25334 5.61328 8.66001 5.61328 8.00001C5.61328 6.68001 6.67995 5.61334 7.99995 5.61334C8.65995 5.61334 9.25328 5.88001 9.68661 6.31334Z"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.8799 3.84669C10.7132 2.96669 9.3799 2.48669 7.9999 2.48669C5.64656 2.48669 3.45323 3.87336 1.92656 6.27336C1.32656 7.21336 1.32656 8.79336 1.92656 9.73336C2.45323 10.56 3.06656 11.2734 3.73323 11.8467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.61328 13.02C6.37328 13.34 7.17995 13.5133 7.99995 13.5133C10.3533 13.5133 12.5466 12.1267 14.0733 9.72668C14.6733 8.78668 14.6733 7.20668 14.0733 6.26668C13.8533 5.92002 13.6133 5.59335 13.3666 5.28668"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.34 8.46667C10.1666 9.40668 9.39996 10.1733 8.45996 10.3467"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6.31325 9.68665L1.33325 14.6666"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M14.6668 1.33331L9.68677 6.31331"
                      stroke="var(--flwmn-primary-color)"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </ng-template>

                <div class="input-style1 input-md auth-input-mb">
                  <label class="input-label">Password</label>

                  <mat-form-field appearance="outline" [style.width.%]="100">
                    <input
                      matInput
                      [type]="show ? 'text' : 'password'"
                      formControlName="password"
                    />

                    <span matSuffix class="img-fluid" (click)="show = !show">
                      <ng-container *ngIf="show">
                        <ng-container
                          *ngTemplateOutlet="visibleEyeIcon"
                        ></ng-container>
                      </ng-container>

                      <ng-container *ngIf="!show">
                        <ng-container
                          *ngTemplateOutlet="hiddenEyeIcon"
                        ></ng-container>
                      </ng-container>
                    </span>

                    <mat-error *ngIf="loginFormControls.password.invalid">
                      <img
                        src="/assets/icons/error.svg"
                        alt=""
                        class="img-fluid error-icon"
                      />
                      {{ getErrorMessage('password') }}
                    </mat-error>
                  </mat-form-field>
                </div>

                <span
                  role="button"
                  routerLink="/forgot-password"
                  class="flwmn-caption forgot-pass-link"
                  >Forgot password?
                </span>

                <button
                  matRipple
                  type="submit"
                  class="btn flwmn-btn-linear-gradient flwmn-btn-md flwmn-btn-full login-btn rounded-pill mt-3"
                  [disabled]="loginForm.invalid"
                >
                  Log in
                </button>

                <div class="text-divider">OR</div>

                <div class="third-party-container">
                  <div class="third-party-options">
                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithGoogle()"
                    >
                      <span>
                        <app-svg
                          src="/assets/icons/auth/google-logo-icon.svg"
                          svgHeight="1.25rem"
                        ></app-svg>
                      </span>

                      <span class="fw-600">Login with Google</span>
                    </div>

                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithLinkedIn()"
                    >
                      <span>
                        <app-svg
                          src="assets/icons/auth/linkedin-logo-icon.svg"
                          svgHeight="1.5rem"
                        ></app-svg>
                      </span>

                      <span class="fw-600">Login with LinkedIn</span>
                    </div>

                    <div
                      class="third-party-option"
                      matRipple
                      (click)="onLoginWithMicrosoft()"
                    >
                      <app-svg
                        src="assets/icons/auth/azure-ad-third-party-auth.svg"
                        svgHeight="1.25rem"
                      ></app-svg>

                      <span class="fw-600">Login with Microsoft</span>
                    </div>
                  </div>
                </div>

                <ng-container *ngIf="isFromDeveloperWebsite">
                  <p class="flwmn-para-1 no-account-text">
                    Don't have an account?
                    <a
                      [routerLink]="['/register']"
                      [queryParams]="{ developer: true }"
                      >Sign Up</a
                    >
                  </p>
                </ng-container>

                <ng-container *ngIf="!isFromDeveloperWebsite">
                  <p class="flwmn-para-1 no-account-text">
                    Don't have an account?
                    <a [routerLink]="['/register']">Sign Up</a>
                  </p>
                </ng-container>
              </form>
            </div>
          </div>
        </ng-container>

        <ng-container
          *ngIf="verifyStatus !== false || isDeviceVerified === true"
        >
          <div class="content-section-verify">
            <div class="form-section">
              <div class="device-verification-image-container">
                <img
                  src="/assets/icons/auth/password.svg"
                  alt="Device Verification"
                />
              </div>

              <h5 class="flwmn-heading-5 fw-700 header-text">
                Device Verification
              </h5>

              <p class="flwmn-para-1 subheader-text">
                We noticed you are logging into a new device! We have sent an
                email to you for confirmation.
              </p>

              <div>
                <h5
                  class="flwmn-heading-5 fw-700 header-text flwmn-text-primary"
                >
                  {{ minutes }}:{{ seconds | number: '2.0' }}
                </h5>
              </div>
            </div>
          </div>
        </ng-container>
      </div>

      <footer class="footer-copyright">
        <p class="flwmn-para-1 footer-text">
          &copy; {{ year }} All rights reserved | Powered by Flowmono and Revent
          Technologies.
        </p>
      </footer>
    </div>

    <!-- Carousel -->
    <div class="carouse-box">
      <app-auth-carousel></app-auth-carousel>
    </div>
  </div>
</section>

<!-- <section id="new-login" *ngIf="true">
  <div class="main-login">
    <div class="logo-overlay-container">
      <div class="img-positioning-container">
        <app-svg
          src="/assets/images/auth/left-illustration.svg"
          svgPathStroke="#e8d4ff"
          svgPathFill="#e8d4ff"
          class="logo-illustration-left"
        ></app-svg>
      </div>
    </div>

    <div class="login-page-content">
      <div class="login-page-content-inner">
        <div class="logo-container">
          <a routerLink="/" *ngIf="!isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo.svg"
              alt="Flowmono Logo White"
              class="logo"
            />
          </a>

          <a routerLink="/developer" *ngIf="isFromDeveloperWebsite">
            <img
              src="/assets/logo/flowmono-logo.svg"
              alt="Flowmono Logo"
              class="logo"
            />
          </a>
        </div>

        <ng-container
          *ngIf="verifyStatus === false && isDeviceVerified === false"
        >
          <div class="auth-login-form-container">
            <div class="welcome-message">
              <p class="flwmn-heading-3 flwmn-text-black fw-600">
                Welcome back!
              </p>
              <span>
                <app-svg src="/assets/images/auth/hand-wave.svg"></app-svg>
              </span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="carouse-box">
    <app-auth-carousel></app-auth-carousel>
  </div>
</section> -->
