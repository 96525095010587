import { createAction, props } from '@ngrx/store';
import { organizationReducerState } from './organization.reducer';

export const ResetStore = createAction('[Organization] Reset Store');

export const IsLoading = createAction(
  '[Organization] Is Loading',
  props<{
    payload: boolean;
  }>()
);

export const ToggleLoader = createAction(
  '[Organization] Toggle Loader',
  props<{
    payload: boolean;
  }>()
);

export const CreateDivision = createAction(
  '[Organization] Create Division',
  props<{
    payload: {
      divisionName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateDivision = createAction(
  '[Organization] Update Division',
  props<{
    payload: {
      divisionId: number;
      divisionName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateDivision = createAction(
  '[Organization] Activate Division',
  props<{
    payload: {
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateDivision = createAction(
  '[Organization] Deactivate Division',
  props<{
    payload: {
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetDivisionById = createAction(
  '[Organization] Get Division By Id',
  props<{
    payload: {
      divisionId: number;
    };
  }>()
);

export const GetAllDivisions = createAction(
  '[Organization] Get All Divisions',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllDivisions = createAction(
  '[Organization] Save All Divisions',
  props<{
    payload: organizationReducerState['divisions'];
  }>()
);

// Department
export const CreateDepartment = createAction(
  '[Organization] Create Department',
  props<{
    payload: {
      departmentName: string;
      divisionId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateDepartment = createAction(
  '[Organization] Update Department',
  props<{
    payload: {
      departmentId: number;
      divisionId: number;
      departmentName: string;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateDepartment = createAction(
  '[Organization] Activate Department',
  props<{
    payload: {
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateDepartment = createAction(
  '[Organization] Deactivate Department',
  props<{
    payload: {
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetDepartmentById = createAction(
  '[Organization] Get Department By Id',
  props<{
    payload: {
      departmentId: number;
    };
  }>()
);

export const GetAllDepartments = createAction(
  '[Organization] Get All Departments',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllDepartments = createAction(
  '[Organization] Save All Departments',
  props<{
    payload: organizationReducerState['departments'];
  }>()
);

export const CreateUnit = createAction(
  '[Organization] Create Unit',
  props<{
    payload: {
      unitName: string;
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const UpdateUnit = createAction(
  '[Organization] Update Unit',
  props<{
    payload: {
      unitId: number;
      unitName: string;
      departmentId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const ActivateUnit = createAction(
  '[Organization] Activate Unit',
  props<{
    payload: {
      unitId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const DeactivateUnit = createAction(
  '[Organization] Deactivate Unit',
  props<{
    payload: {
      unitId: number;
    };
    paginationPayload: {
      skip: number;
      take: number;
    };
  }>()
);

export const GetUnitById = createAction(
  '[Organization] Get Unit By Id',
  props<{
    payload: {
      unitId: number;
    };
  }>()
);

export const GetAllUnits = createAction(
  '[Organization] Get All Units',
  props<{
    payload: {
      skip: number;
      take: number;
    };
  }>()
);

export const SaveAllUnits = createAction(
  '[Organization] Save All Units',
  props<{
    payload: organizationReducerState['units'];
  }>()
);

export const CheckUpDomainName = createAction(
  '[Organization] Check Up Domain Name',
  props<{
    payload: {
      email: string;
    };
  }>()
);

export const UpdateSubscriberDomains = createAction(
  '[Organization] Update Subscriber Domains',
  props<{
    payload: {
      subscriberDomains: {
        id: number;
        domainName: string;
        isDeleted: boolean;
        subscriberId: number;
        userId: string;
      }[];
    };
  }>()
);

export const DeleteSubscriberDomain = createAction(
  '[Organization] Delete Subscriber Domain',
  props<{
    payload: {
      id: number;
    };
    additionalPayload: {
      index: number;
    };
  }>()
);
