import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidateNamePipe } from './validate-name.pipe';
import { ReverseIterablePipe } from './reverse-iterable.pipe';
import { MapPipe } from './map.pipe';
import { DocumentSVGPipe } from './document-svg.pipe';
import { PlanPipe } from './plan.pipe';
import { StringifyNumberPipe } from './stringify-number.pipe';
import { SafeHtmlPipe } from './safehtml.pipe';

const Pipes = [
  ValidateNamePipe,
  ReverseIterablePipe,
  MapPipe,
  DocumentSVGPipe,
  PlanPipe,
  StringifyNumberPipe,
  SafeHtmlPipe,
];
@NgModule({
  declarations: [Pipes, SafeHtmlPipe],
  imports: [CommonModule],
  exports: [Pipes, SafeHtmlPipe],
})
export class PipesModule {}
