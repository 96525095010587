import {
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as AuthActions from 'src/app/@core/stores/auth/auth.actions';
import * as authSelectors from 'src/app/@core/stores/auth/auth.selectors';
import { AuthEffects } from 'src/app/@core/stores/auth/auth.effects';
import * as GeneralActions from 'src/app/@core/stores/general/general.actions';
import * as generalSelectors from 'src/app/@core/stores/general/general.selectors';
import { Observable, Subscription } from 'rxjs';
import * as SubscriptionActions from 'src/app/@core/stores/subscription-plan/subscription-plan.actions';
import * as subscriptionSelectors from 'src/app/@core/stores/subscription-plan/subscription-plan.selectors';
import {
  SocialAuthService,
  GoogleLoginProvider,
  FacebookLoginProvider,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { ThirdPartyErrorComponent } from '../modals/third-party-error/third-party-error.component';
import {
  SubscriberTypeEnum,
  SubscriptionFrequency,
  PricingPlanType,
  SubscriptionPlanTypeEnum,
} from 'src/app/@core/enums';
import {
  getActiveSubscriptionPlanForRegistration,
  regexValidator,
  validateFileType,
} from 'src/app/@core/utils/helpers';
import { ThirdPartyTypeEnum } from 'src/app/@core/enums/third-party-type.enum';
import { filter, map } from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { HelperService } from 'src/app/@core/services/helper.service';
import { Notification } from 'src/app/@core/interfaces';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { INDUSTRIES, REFERRER_SOURCES } from 'src/app/@core/constants';
import { ThemeService } from 'src/app/@core/services/theme.service';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { AuthService } from 'src/app/@core/services/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit, OnDestroy {
  isAuthLoading!: Observable<boolean>;
  isSubscriptionLoading!: Observable<boolean>;
  year!: number;
  page: 1 | 1.5 | 2 = 1;
  totalNumberOfPages: number = 2;
  // progressBarValue: string = '0';
  registrationForm!: FormGroup;
  show: boolean = false;
  profilePicturePreview!: string;
  selectedTheme: any = 'theme1';
  selectedThemeColor: any = '#4E33FF';
  allThemes!: any;
  registrationType!: 'individual' | 'corporate';
  doesOrgDomainNameExist: boolean = false;
  latitude: number = 0;
  longitude: number = 0;
  linkedInAuthUrl: any;
  doesOldRecipientExist: boolean = false;
  discoveredCompany!: any;
  allUsers!: any[];
  allCountries: Observable<string[] | null>;
  allFilteredCountries: Observable<string[] | null>;
  allStates: Observable<
    | {
        name: string;
        state_code: string;
      }[]
    | null
  >;
  allFilteredStates: Observable<
    | {
        name: string;
        state_code: string;
      }[]
    | null
  >;
  allCountryCodes: Observable<any[] | null>;
  allFilteredCountryCodes: Observable<any[] | null>;
  subscription!: any;
  pricing: any;
  country: 'Nigeria' | 'Others' = 'Others';
  industries = INDUSTRIES;
  referrerSources = REFERRER_SOURCES;
  currentScrollSnapSectionInView: number = 1;
  totalScrollSnapSections!: number;
  scrollSnapSectionsObserver: IntersectionObserver;
  isFromDeveloperWebsite: boolean = false;

  // Password validations
  uppercasePattern = new RegExp('^(?=.*?[A-Z])');
  lowercasePattern = new RegExp('(?=.*?[a-z])');
  numberPattern = new RegExp('(?=.*?[0-9])');
  specialCharPattern = new RegExp('(?=.*?[#?!@$%^&*-])');

  private _subscription: Subscription = new Subscription();

  @ViewChild('profilePictureUpload', { static: false })
  profilePictureUpload: ElementRef;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    public dialog: MatDialog,
    private store: Store<fromApp.AppState>,
    private authEffects: AuthEffects,
    private socialAuthService: SocialAuthService,
    private helperService: HelperService,
    // private renderer: Renderer2,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private themeService: ThemeService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private authService: AuthService
  ) {
    // this.configurePage();

    this._subscription.add(
      this.route.queryParams.subscribe((res) => {
        if (res?.developer) {
          this.isFromDeveloperWebsite = res?.developer;
        }
      })
    );
  }

  ngOnInit(): void {
    this.themeService.removeWebappThemes();

    this.isAuthLoading = this.store.pipe(
      select(authSelectors.getAuthIsLoading)
    );

    this.isSubscriptionLoading = this.store.pipe(
      select(subscriptionSelectors.getSubscriptionPlanIsLoading)
    );
    //
    // this.socialAuthService.signOut();

    this.year = new Date().getFullYear();

    this.createRegistrationForm();

    this.getIpAddressDetails();

    this.listenToGetIpAddressDetailsSelector();

    this.getLocation();

    this.listenToCheckUpDomainNameEffects();

    this.listenToCheckEmailEffects();

    this.listenToAuthRegisterEffects();

    //this.listenToLinkedInRegisterEffects();

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getCountryAndStates.bind(this)
    );

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getCountryCodes.bind(this)
    );

    // this.listenToIpAdrressSelector(); //TODO Usefull. Don't delete

    // this.listenToGetCountryFromIpAddressSelector(); //TODO Usefull. Don't delete

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.getActiveSubscriptionPlans.bind(this)
    );

    this.listenToGetAllActiveSubscriptionPlansForWebsite();

    this.authService.listenToAzureADLoginSuccess();

    this.manageAzureADLoginAfterRedirect();
  }

  // ngAfterViewInit(): void {
  //   this.manageScrollSnapSectionsNumbering();

  //   const timeout = setTimeout(() => {
  //     this.handleManageRoutePills();

  //     clearTimeout(timeout);
  //   }, 100);
  // }

  // handleManageRoutePills() {
  //   let element;

  //   if (location?.pathname === '/register/individual') {
  //     element = document.querySelector(
  //       `#register [data-registration-type='individual']`
  //     );
  //   } else if (location?.pathname === '/register/corporate') {
  //     element = document.querySelector(
  //       `#register [data-registration-type='corporate']`
  //     );
  //   }

  //   this.manageRoutePills({ target: element });
  // }

  listenToCheckUpDomainNameEffects() {
    this._subscription.add(
      this.authEffects.checkUpDomainName$.subscribe((resData) => {
        if (resData?.resData !== null) {
          if (resData?.resData.entity?.existingDomain !== null) {
            this.doesOrgDomainNameExist = true;
          } else {
            this.doesOrgDomainNameExist = false;
          }

          if (resData?.alternatePayload) {
            if (this.doesOrgDomainNameExist === false) {
              const userData = resData?.alternatePayload;

              this.thirdPartyDispatch(
                {
                  firstName: userData?.firstName,
                  lastName: userData?.lastName,
                  email: userData?.email,
                  photoUrl: userData.photoUrl,
                },
                userData.thirdPartyType
              );
            } else {
              this.discoveredCompany = resData?.resData?.entity?.existingDomain;
              this.allUsers = Object.values(resData.resData?.entity?.usersList);

              const userData = resData?.alternatePayload;

              this.registrationForm.patchValue({
                email: userData?.email,
                password: ``,
                firstname: userData?.firstName,
                lastname: userData?.lastName,
              });

              this.goToPage(1.5);
            }
          } else {
            this.actionAfterDomainNameCheck(resData?.resData?.entity);
          }
        }
      })
    );
  }

  listenToCheckEmailEffects() {
    this._subscription.add(
      this.authEffects.checkEmail$.subscribe((resData) => {
        if (resData !== null) {
          if (resData?.succeeded === true) {
            this.continueToPage2();
          }
        }
      })
    );
  }

  listenToAuthRegisterEffects() {
    this._subscription.add(
      this.authEffects.authRegister$.subscribe((resData: any) => {
        if (resData !== null) {
          if (resData.succeeded === true) {
            const email = resData?.entity?.user?.email;

            if (email) {
              this.router.navigate([`register/welcome-onboard/${email}`]);
            }
            // this.openSignupCompletedModal();
          }
        }
      })
    );
  }

  onFilterCountry(event: any) {
    const inputValue = event.toLowerCase();

    this.allFilteredCountries = this.allCountries.pipe(
      map((arr: any) => {
        const result = arr.filter((country: any) => {
          return country?.toLowerCase().includes(inputValue);
        });

        return result;
      })
    );
  }

  onFilterCountryCode(event: any) {
    const inputValue = event.toLowerCase();

    this.allFilteredCountryCodes = this.allCountryCodes.pipe(
      map((arr: any) => {
        const result = arr.filter((countryCode: any) => {
          return (
            countryCode?.code
              ?.toLowerCase()
              .substring(0, 4)
              .includes(inputValue.toLowerCase()) ||
            countryCode?.country
              ?.toLowerCase()
              .substring(0, 5)
              .includes(inputValue.toLowerCase())
          );
        });

        return result;
      })
    );
  }

  onFilterStates(event: any) {
    const inputValue = event.toLowerCase();

    this.allFilteredStates = this.allStates.pipe(
      map((arr: any) => {
        const result = arr.filter((states: any) => {
          return states?.name?.toLowerCase().includes(inputValue);
        });

        return result;
      })
    );
  }

  getCountryAndStates() {
    this.allCountries = this.store.pipe(
      select(generalSelectors.getAllCountries)
    );

    this.allFilteredCountries = this.store.pipe(
      select(generalSelectors.getAllCountries)
    );

    this._subscription.add(
      this.allCountries.subscribe((countries) => {
        if (countries === null) {
          this.store.dispatch(GeneralActions.GetCountries());
        }
      })
    );

    this.allStates = this.store.pipe(select(generalSelectors.getAllStates));

    this.allFilteredStates = this.store.pipe(
      select(generalSelectors.getAllStates)
    );
    // this.allCities = this.store.pipe(select(generalSelectors.getAllCities));
  }

  getCountryCodes() {
    this.allCountryCodes = this.store.pipe(
      select(generalSelectors.getAllCountryCodes)
    );

    this.allFilteredCountryCodes = this.store.pipe(
      select(generalSelectors.getAllCountryCodes)
    );

    this._subscription.add(
      this.allCountryCodes.subscribe((countryCodes) => {
        if (countryCodes === null) {
          this.store.dispatch(GeneralActions.GetCountryCodes());
        }
      })
    );
  }

  onSelectCountry(event: MatAutocompleteSelectedEvent) {
    this.store.dispatch(GeneralActions.SaveStates({ payload: null }));

    this.store.dispatch(
      GeneralActions.GetStates({ payload: event.option.value })
    );

    // this.store.dispatch(GeneralActions.GetCities({ payload: event.value }));
  }

  onSelectCountryCode(event: MatAutocompleteSelectedEvent) {
    // clear pre-existing text, in case country code is changed
    this.registrationForm.value.phone_number = '';

    this.registrationForm.patchValue({
      phone_number: event.option.value,
    });
  }

  listenToGetAllActiveSubscriptionPlansForWebsite() {
    this._subscription.add(
      this.store
        .pipe(
          select(subscriptionSelectors.getAllActiveSubscriptionPlansForWebsite)
        )
        .subscribe((resData) => {
          if (resData !== null) {
            this.subscription = getActiveSubscriptionPlanForRegistration(
              resData
              // this.registrationType
            );

            this.subscription?.subscriptionPlanPricings?.forEach(
              (subscriptionPlanPricing: any) => {
                if (
                  this.country === 'Nigeria' &&
                  subscriptionPlanPricing.currencyCode === 'NGN' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                } else if (
                  this.country === 'Others' &&
                  subscriptionPlanPricing.currencyCode === 'USD' &&
                  subscriptionPlanPricing.pricingPlanType ===
                    PricingPlanType.monthly
                ) {
                  this.pricing = subscriptionPlanPricing;
                }
              }
            );
          }
          // else {
          //   this.helperService.manageDeveloperTokenAndCallFunction(
          //     this.getActiveSubscriptionPlans.bind(this)
          //   );
          // }
        })
    );
  }

  getActiveSubscriptionPlans() {
    this.store.dispatch(SubscriptionActions.IsLoading({ payload: true }));

    this.store.dispatch(
      SubscriptionActions.GetActiveSubscriptionPlansForWebsite()
    );
  }

  // listenToLinkedInRegisterEffects() {
  //
  //   this.linkedInRegisterEffectsSub = this.authEffects.authLinkedInSignUp$.subscribe(
  //     (resData) => {
  //       if (resData !== null) {
  //         if (resData.succeeded === true) {
  //           this.openSignupCompletedModal();
  //         }
  //       }
  //     }
  //   );
  // }
  // getOnyxDocAuth(callbackFunction: any) {
  //   this.store.dispatch(AuthActions.IsLoading({ payload: true }));

  //   if (localStorage.getItem('OnyxDoc_auth') === null) {
  //     this.store.dispatch(AuthActions.InitializeApp_DeveloperToken());

  //     this.getBearerTokenStatusSub = this.store
  //       .pipe(select(authSelectors.getDeveloperTokenStatus))
  //       .subscribe((status) => {
  //         if (status && status === true) {
  //           callbackFunction();
  //         }
  //       });
  //   } else {
  //     callbackFunction();
  //   }
  // }

  getIpAddressDetails() {
    this.store.dispatch(GeneralActions.GetIpAddressDetails());
  }

  listenToGetIpAddressDetailsSelector() {
    this._subscription.add(
      this.store
        .pipe(select(generalSelectors.getIpAddressDetails))
        .subscribe((resData: any) => {
          if (resData !== null) {
            this.country = resData?.country_name;
          }
        })
    );
  }

  getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
      });
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  }

  // checkRegistrationType() {
  //   if (this.router.url === '/register/individual') {
  //     this.registrationType = 'individual';
  //   } else if (this.router.url === '/register/corporate') {
  //     this.registrationType = 'corporate';
  //   }
  // }

  // configurePage() {
  //   this.checkRegistrationType();

  //   // if (this.registrationType === 'individual') {
  //   //   this.totalNumberOfPages = 1;
  //   // } else if (this.registrationType === 'corporate') {
  //   //   this.totalNumberOfPages = 2;
  //   // }
  // }

  createRegistrationForm() {
    this.registrationForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [
        null,
        [
          Validators.required,
          regexValidator(new RegExp('^(?=.*?[A-Z])'), {
            uppercaseLetter: true,
          }),
          regexValidator(new RegExp('(?=.*?[a-z])'), { lowercaseLetter: true }),
          regexValidator(new RegExp('(?=.*?[0-9])'), { number: true }),
          regexValidator(new RegExp('(?=.*?[#?!@$%^&*-])'), {
            specialCharacter: true,
          }),
          regexValidator(new RegExp('.{8,}$'), { minimum: true }),
          // Validators.pattern(
          //   '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$'
          // ),
        ],
      ],

      repeatPassword: [null, [Validators.required]],

      firstname: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      lastname: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z]+$')],
      ],
      code: [null],
      phone_number: [
        '',
        [Validators.required, Validators.pattern('^[+0-9]{7,15}$')],
      ],
      job_title: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z\\s]+$')],
      ],
      company_name: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z0-9\\s]+$')],
      ],
      rc_number: [null, [Validators.required]],
      staff_size: ['', [Validators.required]],
      company_document: [null, [Validators.required]],
      industry: [null, [Validators.required]],
      address: [null, [Validators.required]],
      country: [null, [Validators.required, Validators.pattern('^[A-Za-z]+$')]],
      state: [
        null,
        [Validators.required, Validators.pattern('^[A-Za-z\\s]+$')],
      ],

      city: [null],
      profile_picture: [null],
      referrer: [null, [Validators.required]],
      subscription_purpose: [null, [Validators.required]],
      themeColor: ['theme1', [Validators.required]],
      invitees: this.fb.array([]),
    });
  }

  get registrationFormControls() {
    return this.registrationForm.controls;
  }

  get inviteesFormArray(): FormArray {
    return this.registrationForm.get('invitees') as FormArray;
  }

  get passwordValue() {
    return this.registrationForm.controls['password'].value;
  }

  getErrorMessage(instance: string) {
    if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('required')
    ) {
      return 'Please enter your email';
    } else if (
      instance === 'email' &&
      this.registrationFormControls.email.hasError('email')
    ) {
      return 'Sorry, this is not a valid email';
    } else if (
      instance === 'password' &&
      this.registrationFormControls.password.hasError('required')
    ) {
      return 'Please enter your password';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('uppercaseLetter')
    ) {
      return 'Your password must have at least 1 uppercase letter';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('lowercaseLetter')
    ) {
      return 'Your password must have at least 1 lowercase letter.';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('number')
    ) {
      return 'Your password must have at least a digit (0-9)';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('specialCharacter')
    ) {
      return 'Your password must have at least a special character';
    } else if (
      instance === 'password' &&
      this.registrationFormControls['password'].hasError('minimum')
    ) {
      return 'Your password must have at least a minimum of 8 characters.';
    }
    // else if (
    //   instance === 'password' &&
    //   this.registrationFormControls.password.hasError('pattern')
    // ) {
    //   return 'Your password must have at least 1 uppercase, digit (0-9), special character, and a minimum of 8 characters.';
    // }
    else if (
      instance === 'firstname' &&
      this.registrationFormControls.firstname.hasError('required')
    ) {
      return 'Please enter your first name';
    } else if (
      instance === 'firstname' &&
      this.registrationFormControls.firstname.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'lastname' &&
      this.registrationFormControls.lastname.hasError('required')
    ) {
      return 'Please enter your last name';
    } else if (
      instance === 'lastname' &&
      this.registrationFormControls.lastname.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'code' &&
      this.registrationFormControls.code.hasError('required')
    ) {
      return 'Required';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('required')
    ) {
      return 'Please enter your phone number';
    } else if (
      instance === 'phone_number' &&
      this.registrationFormControls.phone_number.hasError('pattern')
    ) {
      return 'Incorrect phone number format';
    } else if (
      instance === 'job_title' &&
      this.registrationFormControls.job_title.hasError('required')
    ) {
      return 'Please enter your job title';
    } else if (
      instance === 'job_title' &&
      this.registrationFormControls.job_title.hasError('pattern')
    ) {
      return 'Incorrect name format';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('required')
    ) {
      return 'Please enter your company name';
    } else if (
      instance === 'company_name' &&
      this.registrationFormControls.company_name.hasError('pattern')
    ) {
      return 'Incorrect format';
    } else if (
      instance === 'rc_number' &&
      this.registrationFormControls.rc_number.hasError('required')
    ) {
      return `Please enter your company's RC Number`;
    } else if (
      instance === 'staff_size' &&
      this.registrationFormControls.staff_size.hasError('required')
    ) {
      return 'Please select your staff size';
    } else if (
      instance === 'company_document' &&
      this.registrationFormControls.company_document.hasError('required')
    ) {
      return `Please upload your Company's Registration Document`;
    } else if (
      instance === 'industry' &&
      this.registrationFormControls.industry.hasError('required')
    ) {
      return 'Please select your industry';
    } else if (
      instance === 'address' &&
      this.registrationFormControls.address.hasError('required')
    ) {
      return 'Please enter your address';
    } else if (
      instance === 'country' &&
      this.registrationFormControls.country.hasError('required')
    ) {
      return 'Please select your country';
    } else if (
      instance === 'country' &&
      this.registrationFormControls.country.hasError('pattern')
    ) {
      return 'Incorrect format';
    } else if (
      instance === 'state' &&
      this.registrationFormControls.state.hasError('required')
    ) {
      return 'Please select your state';
    } else if (
      instance === 'state' &&
      this.registrationFormControls.state.hasError('pattern')
    ) {
      return 'Incorrect format';
    } else if (
      instance === 'city' &&
      this.registrationFormControls.city.hasError('required')
    ) {
      return 'Please select your city';
    } else if (
      instance === 'referrer' &&
      this.registrationFormControls.referrer.hasError('required')
    ) {
      return 'Please enter your answer';
    } else if (
      instance === 'subscription_purpose' &&
      this.registrationFormControls.subscription_purpose.hasError('required')
    ) {
      return 'Please enter your answer';
    } else {
      return;
    }
  }

  manageScrollSnapSectionsNumbering() {
    document
      .querySelectorAll('.scroll-snap-section')
      .forEach((scrollSnapSection, index) => {
        if (
          scrollSnapSection.hasAttribute('data-scroll-snap-section-number') ===
          true
        ) {
          scrollSnapSection.removeAttribute('data-scroll-snap-section-number');
        }
      });

    const timeout = setTimeout(() => {
      document
        .querySelectorAll('.scroll-snap-section')
        .forEach((scrollSnapSection, index) => {
          scrollSnapSection.setAttribute(
            'data-scroll-snap-section-number',
            `${index + 1}`
          );
        });

      this.setTotalScrollSnapSections();

      this.listenToScrollSnapSectionIntersectionChange();

      clearTimeout(timeout);
    }, 10);
  }

  setTotalScrollSnapSections() {
    this.totalScrollSnapSections = document.querySelectorAll(
      '.scroll-snap-section'
    ).length;
  }

  listenToScrollSnapSectionIntersectionChange(): void {
    const scrollSnapSections = document.querySelector('.scroll-snap-sections')!;

    const IntersectionObserverOptions: IntersectionObserverInit = {
      root: scrollSnapSections,
      rootMargin: '-50%',
    };

    this.scrollSnapSectionsObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting === true) {
            document
              .querySelectorAll('.scroll-snap-section')
              .forEach((scrollSnapSection) => {
                if (scrollSnapSection.classList.contains('in-view')) {
                  scrollSnapSection.classList.remove('in-view');
                }
              });

            entry.target.classList.add('in-view');

            this.currentScrollSnapSectionInView = Number(
              entry.target.getAttribute('data-scroll-snap-section-number')
            );
          }
        });
      },
      IntersectionObserverOptions
    );

    document
      .querySelectorAll('.scroll-snap-section')
      .forEach((scrollSnapSection) => {
        this.scrollSnapSectionsObserver.observe(scrollSnapSection);
      });
  }

  keyPress(event: KeyboardEvent) {
    if (this.page === 2 && event.code.toLocaleLowerCase() === 'enter') {
      const scrollSnapSectionInViewNumber = Number(
        document
          .querySelector('.in-view')!
          .getAttribute('data-scroll-snap-section-number')
      );

      const nextScrollSnapSection = document.querySelector(
        `[data-scroll-snap-section-number='${
          scrollSnapSectionInViewNumber + 1
        }']`
      );

      if (nextScrollSnapSection) {
        nextScrollSnapSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  navigateTo(event: any) {
    document
      .querySelectorAll('.scroll-snap-section')
      .forEach((scrollSnapSection, index) => {
        scrollSnapSection.removeAttribute(`data-scroll-snap-section-number`);
      });

    // this.manageRoutePills(event);

    const timeout = setTimeout(() => {
      this.router.navigate([
        `/register/${(event?.target as HTMLElement)?.getAttribute(
          'data-registration-type'
        )}`,
      ]);

      clearTimeout(timeout);
    }, 250); /**Wait until glider animation is done before navigating**/
  }

  // manageRoutePills(event: any) {
  //   const routePill = event.target as HTMLElement;

  //   if (routePill) {
  //     const glider = document.querySelector('#register .glider');

  //     const allRoutePills = document.querySelectorAll('#register .route-pill')!;

  //     allRoutePills?.forEach((routePill) => {
  //       if (routePill.classList.contains('checked')) {
  //         routePill.classList.remove('checked');
  //       }
  //     });

  //     this.renderer?.addClass(routePill, 'checked');

  //     this.renderer?.setStyle(
  //       glider,
  //       'width',
  //       `${routePill?.getBoundingClientRect().width}px`
  //     );

  //     this.renderer?.setStyle(
  //       glider,
  //       'height',
  //       `${routePill?.getBoundingClientRect().height}px`
  //     );

  //     this.renderer?.setStyle(
  //       glider,
  //       'transform',
  //       `translateX(${routePill?.offsetLeft - 8}px)` // Minus the left padding of the route pill container
  //     );
  //   }
  // }

  onClickPage2BackButton() {
    if (this.currentScrollSnapSectionInView > 1) {
      document
        .querySelector(
          `[data-scroll-snap-section-number='${
            this.currentScrollSnapSectionInView - 1
          }']`
        )
        ?.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.page = 1;
    }
  }

  // validateIndividualSignUpBtn() {
  //   if (
  //     this.registrationFormControls.email.invalid ||
  //     this.registrationFormControls.password.invalid ||
  //     this.registrationFormControls.firstname.invalid ||
  //     this.registrationFormControls.lastname.invalid ||
  //     this.registrationFormControls.phone_number.invalid ||
  //     this.registrationFormControls.referrer.invalid
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  // validateFirstNameAndLastNameForPage1_5() {
  //   if (
  //     this.registrationFormControls.firstname.invalid ||
  //     this.registrationFormControls.lastname.invalid
  //   ) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  validateContinueBtn() {
    if (
      this.registrationFormControls.email.invalid ||
      this.registrationFormControls.password.invalid ||
      this.registrationFormControls.repeatPassword.invalid ||
      this.registrationFormControls.firstname.invalid ||
      this.registrationFormControls.lastname.invalid ||
      this.registrationFormControls.phone_number.invalid ||
      this.registrationFormControls.password.value !==
        this.registrationFormControls.repeatPassword.value
    ) {
      return true;
    } else {
      return false;
    }
  }

  validateSignUpBtn() {
    if (this.registrationType === 'individual') {
      if (
        this.registrationFormControls.referrer.invalid ||
        // this.registrationFormControls.profile_picture.invalid ||
        this.registrationFormControls.themeColor.invalid
      ) {
        return true;
      } else {
        return false;
      }
    } else if (this.registrationType === 'corporate') {
      if (
        this.registrationFormControls.referrer.invalid ||
        this.registrationFormControls.company_name.invalid ||
        this.registrationFormControls.country.invalid ||
        this.registrationFormControls.state.invalid ||
        // this.registrationFormControls.profile_picture.invalid ||
        this.registrationFormControls.themeColor.invalid ||
        this.inviteesFormArray.invalid
      ) {
        return true;
      } else {
        return false;
      }
    }
  }

  onClickContinue() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.checkEmail.bind(this)
    );
  }

  checkEmail() {
    this.store.dispatch(
      AuthActions.CheckEmail({
        payload: { contactEmail: this.registrationForm.value.email },
      })
    );
  }

  continueToPage2() {
    this.initiateCheckUpDomainName();
  }

  initiateJoinExistingOrganization(id: number) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.joinExistingOrganization.bind(this, id)
    );
  }

  joinExistingOrganization(id: number) {
    this.store.dispatch(
      AuthActions.JoinExistingOrganization({
        payload: {
          email: this.registrationForm.value.email,
          password: this.registrationForm.value.password,
          firstName: this.registrationForm.value.firstname,
          lastName: this.registrationForm.value.lastname,
          subscriberId: id,
        },
      })
    );
  }

  addInvitee() {
    this.inviteesFormArray.push(this.createInvitee());
  }

  removeInvitee(index: number) {
    this.inviteesFormArray.removeAt(index);
  }

  initiateCheckUpDomainName(optionalData?: {
    isThirdParty: boolean;
    thirdPartyType: ThirdPartyTypeEnum;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl: string;
  }) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.CheckUpDomainName.bind(this, optionalData)
    );
  }

  CheckUpDomainName(optionalData?: {
    isThirdParty: boolean;
    thirdPartyType: ThirdPartyTypeEnum;
    firstName: string;
    lastName: string;
    email: string;
    photoUrl: string;
  }) {
    this.store.dispatch(
      AuthActions.CheckUpDomainName({
        payload: {
          email: (
            optionalData?.email ?? (this.registrationForm.value.email as string)
          )
            .toLowerCase()
            .trim(),
        },
        alternatePayload: optionalData
          ? {
              isThirdParty: optionalData.isThirdParty!,
              thirdPartyType: optionalData.thirdPartyType!,
              firstName: optionalData.firstName!,
              lastName: optionalData.lastName!,
              email: optionalData.email!,
              photoUrl: optionalData.photoUrl!,
            }
          : null,
      })
    );
  }

  actionAfterDomainNameCheck(resData: any) {
    if (resData.subscriberType === 'Individual') {
      this.registrationType = 'individual';
    } else if (resData.subscriberType === 'Corporate') {
      this.registrationType = 'corporate';
    }

    // console.log(this.registrationType);

    // this.helperService.manageDeveloperTokenAndCallFunction(
    //   this.getActiveSubscriptionPlans.bind(this)
    // );

    this.manageScrollSnapSectionsNumbering();

    if (this.doesOrgDomainNameExist === true) {
      this.discoveredCompany = resData?.existingDomain;
      this.allUsers = Object.values(resData?.usersList);

      this.goToPage(1.5);
    } else if (this.doesOrgDomainNameExist === false) {
      this.goToPage(2);
    }

    // if (this.registrationType === 'individual') {
    //   if (resData.subscriberType === 'Corporate') {
    //     const notification: Notification = {
    //       state: 'warning',
    //       message:
    //         'Please use a non corporate email to signup as an individual',
    //     };

    //     this.notificationService.openNotification(
    //       notification,
    //       'flwmn-notification-warning'
    //     );

    //     return;
    //   } else if (resData.subscriberType === 'Individual') {
    //     // this.onSubmit();
    //     this.goToPage(2);
    //   }
    // } else if (this.registrationType === 'corporate') {
    //   if (resData.subscriberType === 'Individual') {
    //     const notification: Notification = {
    //       state: 'warning',
    //       message: 'Please use a corporate email to continue corporate signup',
    //     };

    //     this.notificationService.openNotification(
    //       notification,
    //       'flwmn-notification-warning'
    //     );
    //   } else if (resData.subscriberType === 'Corporate') {
    //     if (this.doesOrgDomainNameExist === true) {
    //       this.discoveredCompany = resData?.existingDomain;
    //       this.allUsers = Object.values(resData?.usersList);

    //       this.goToPage(1.5);
    //     } else if (this.doesOrgDomainNameExist === false) {
    //       this.goToPage(2);
    //     }
    //   }
    // }
  }

  goToPage(page: 1 | 1.5 | 2) {
    this.page = page;
    window.scrollTo(0, 0);
  }

  // goToIndividualSignUp() {
  //   this.goToPage(1);
  //   this.router.navigate(['/register/individual']);
  // }

  // goToCorporateSignUp() {
  //   this.goToPage(1);
  //   this.router.navigate(['/register/corporate']);
  // }

  // getProgressBarValue() {
  //   const breakpoint = 100 / (this.totalNumberOfPages - 1);
  //   this.progressBarValue = ((this.page - 1) * breakpoint).toString();

  //   return this.progressBarValue;
  // }

  onUploadProfilePicture(event: Event) {
    const _event: any = event.target as HTMLInputElement;

    const file = _event.files[0];

    if (file && file.size <= 1000000) {
      if (
        validateFileType(
          [file],
          ['image/jpeg', 'image/jpg', 'image/png', 'image/webp']
        )
      ) {
        const reader = new FileReader();

        reader.onload = () => {
          const fullBase64String = reader.result!.toString();
          const base64String = fullBase64String.split(',');

          this.registrationForm.patchValue({
            profile_picture: base64String[1],
          });

          this.registrationForm
            .get('profile_picture')!
            .updateValueAndValidity();

          this.profilePicturePreview = fullBase64String;
        };

        reader.readAsDataURL(file);
      } else {
        this.profilePictureUpload.nativeElement.value = null;

        const notification: Notification = {
          state: 'warning',
          message: 'Unsupported file format: Please select an Image file',
        };

        this.notificationService.openNotification(
          notification,
          'flwmn-notification-warning'
        );
      }
    } else {
      this.profilePictureUpload.nativeElement.value = null;

      const notification: Notification = {
        state: 'warning',
        message: 'The maximum image size is 1MB',
      };

      this.notificationService.openNotification(
        notification,
        'flwmn-notification-warning'
      );
    }
  }

  onSelectTheme(event: any) {
    this.selectedTheme = event.target.getAttribute('data-theme');
    this.selectedThemeColor = event.target.getAttribute('data-theme-color');

    this.allThemes = document.querySelectorAll('.theme');

    this.allThemes.forEach((theme: HTMLElement) => {
      if (theme.classList.contains('selected')) {
        theme.classList.remove('selected');
      }
    });

    event.target.classList.add('selected');

    this.registrationForm.patchValue({ themeColor: this.selectedTheme });
    this.registrationForm.updateValueAndValidity();
  }

  createInvitee() {
    return this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      role: [null, [Validators.required]],
    });
  }

  onSignUpWithGoogle() {
    this.socialAuthService
      .signIn(GoogleLoginProvider.PROVIDER_ID)
      .then((userData) => {
        if (userData.email) {
          this.helperService.manageDeveloperTokenAndCallFunction(
            this.signUpWithGoogle.bind(this, userData)
          );
        } else {
          this.onThirdPartySignUpError('Google');
        }
      });
  }

  signUpWithGoogle(userData: SocialUser) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.registrationType === 'individual';

    this.thirdPartyDispatch(userData, ThirdPartyTypeEnum.Google);
  }

  onSignUpWithFacebook() {
    // const profile: string = 'string';
    this.socialAuthService
      .signIn(FacebookLoginProvider.PROVIDER_ID)
      .then((userData) => {
        if (userData.email) {
          this.helperService.manageDeveloperTokenAndCallFunction(
            this.signUpWithFacebook.bind(this, userData)
          );
        } else {
          this.onThirdPartySignUpError('Facebook');
        }
      });
  }

  signUpWithFacebook(userData: SocialUser) {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.registrationType === 'individual';

    this.thirdPartyDispatch(userData, ThirdPartyTypeEnum.Facebook);
  }

  thirdPartyDispatch(userData: any, thirdPartyType: ThirdPartyTypeEnum) {
    this.store.dispatch(
      AuthActions.RegisterStart({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            subscriberName: `${userData.firstName} ${userData.lastName}`,
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType:
              this.subscription?.subscriptionType ??
              SubscriptionPlanTypeEnum.Standard,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            // numberOfUsers:
            //   this.subscription?.subscriptionType === 2
            //     ? this.subscription?.numberOfUsers
            //     : 1,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            name: `${userData.firstName} ${userData.lastName}`,
            subscriberType: SubscriberTypeEnum.individual,
            phoneNumber: '',
            city: '',
            themeColor: '',
            state: '',
            country: '',
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
            companyName: `${userData?.firstName} ${userData?.lastName}`,
            contactEmail: userData?.email,
            subscriberCode: '',
            staffSize: '',
            industry: '',
            location: '',
            referrer: 5,
            subscriptionPurpose: '',
            address: '',
            firstName: userData?.firstName,
            lastName: userData?.lastName,
            password: '',
            email: userData?.email,
            jobTitle: '',
            invitedRecipients: null,
            profilePicture: userData?.photoUrl,
            rcNumber: null,
            companyDocument: null,
            thirdPartyType: thirdPartyType,
            allowUsersOutsideDomainToJoinOrganization: false,
            deviceType: 0,
          },
        },
      })
    );
  }

  onSignUpWithLinkedIn() {
    this.helperService.manageDeveloperTokenAndCallFunction(
      this.signUpWithLinkedIn.bind(this)
    );
  }

  signUpWithLinkedIn() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.registrationType === 'individual';

    this.store.dispatch(AuthActions.LinkedInSignUp());
  }

  onSignUpWithMicrosoft() {
    this.authService.initializeLoginWithAzureAD();
  }

  manageAzureADLoginAfterRedirect() {
    this._subscription.add(
      this.msalBroadcastService.inProgress$
        .pipe(
          filter(
            (status: InteractionStatus) => status === InteractionStatus.None
          )
        )
        .subscribe(() => {
          const userData = this.msalService.instance.getActiveAccount();

          if (userData && this.subscription && this.pricing) {
            this.store.dispatch(AuthActions.IsLoading({ payload: true }));

            this.registrationType === 'individual';

            this.initiateCheckUpDomainName({
              isThirdParty: true,
              thirdPartyType: ThirdPartyTypeEnum.Microsoft,
              firstName: userData?.name?.split(' ')[0]!,
              lastName: userData?.name?.split(' ')[1]!,
              email: userData?.username,
              photoUrl: '',
            });
          }
        })
    );
  }

  onThirdPartySignUpError(thirdPartyType: string) {
    this.dialog.open(ThirdPartyErrorComponent, {
      data: thirdPartyType,
      disableClose: true,
      panelClass: 'flwmn-dialog',
      backdropClass: 'flwmn-dialog-backdrop',
    });
  }

  //TODO Usefull. Don't delete
  // listenToIpAdrressSelector() {
  //   this.ipAddressSelectorSub = this.store
  //     .pipe(select(generalSelectors.getIpAddress))
  //     .subscribe((ipAddress) => {
  //       if (ipAddress !== null) {
  //         // this.store.dispatch(GeneralActions.GetCountryFromIpAddress());
  //       }
  //     });
  // }

  //TODO Usefull. Don't delete
  // listenToGetCountryFromIpAddressSelector() {
  //   this.getCountryFromIpAddressSelectorSub = this.store
  //     .pipe(select(generalSelectors.getCountryFromIpAddress))
  //     .subscribe((resData: any) => {
  //       if (resData !== null) {
  //         this.country = resData;
  //       }
  //     });
  // }

  onSubmit() {
    this.store.dispatch(AuthActions.IsLoading({ payload: true }));

    this.helperService.manageDeveloperTokenAndCallFunction(
      this.register.bind(this)
    );
  }

  register() {
    const inviteesDictionary = this.registrationForm.value.invitees.reduce(
      (previousValue: any, currentValue: any) => ({
        ...previousValue,
        [currentValue.email]: currentValue.role,
      }),
      {}
    );

    let deviceGuid = localStorage.getItem('deviceGuid');
    if (deviceGuid === undefined || deviceGuid === null) {
      deviceGuid = this.helperService.generateGUID();
      localStorage.setItem('deviceGuid', deviceGuid);
    }

    this.store.dispatch(
      AuthActions.RegisterStart({
        payload: {
          subscription: {
            subscriberId: this.subscription?.subscriberId,
            productId: this.subscription?.productId,
            subscriptionPlanPricingId: this.pricing?.id,
            // subscriberName: this.pricing?.subscriberName,
            subscriberName:
              this.registrationType === 'corporate'
                ? this.registrationForm.value.company_name
                : `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            subscriptionPlanId: this.subscription?.id,
            initialSubscriptionPlanId: 0,
            renewedSubscriptionPlanId: 0,
            subscriptionType:
              this.subscription?.subscriptionType ??
              SubscriptionPlanTypeEnum.Standard,
            currencyId: this.pricing?.currencyId,
            paymentChannelId: 0,
            currencyCode: this.pricing?.currencyCode,
            noOfMonthOrYears: 1,
            subscriptionFrequency: SubscriptionFrequency.monthly,
            paymentPeriod: SubscriptionFrequency.monthly,
            numberOfUsers: this.subscription?.numberOfUsers,
            // numberOfUsers:
            //   this.subscription?.subscriptionType === 2
            //     ? this.subscription?.numberOfUsers
            //     : 1,
            amount: this.pricing?.amount,
            isFreeTrial: true,
            userId: '',
            autoRenew: false,
          },
          subscriber: {
            name:
              this.registrationType === 'corporate'
                ? this.registrationForm.value.company_name
                : `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            subscriberType:
              this.registrationType === 'individual'
                ? SubscriberTypeEnum.individual
                : SubscriberTypeEnum.corporate,
            phoneNumber: this.registrationForm.value.phone_number,
            city: this.registrationForm.value.city,
            themeColor: this.registrationForm.value.themeColor,
            state: this.registrationForm.value.state,
            country: this.registrationForm.value.country,
            latitude: `${this.latitude}`,
            longitude: `${this.longitude}`,
            companyName:
              this.registrationType === 'corporate'
                ? this.registrationForm.value.company_name
                : `${this.registrationForm.value.firstname} ${this.registrationForm.value.lastname}`,
            contactEmail: this.registrationForm.value.email,
            subscriberCode: '',
            staffSize: `${this.registrationForm.value.staff_size}`,
            industry: this.registrationForm.value.industry,
            location: '',
            referrer: this.registrationForm.value.referrer,
            subscriptionPurpose:
              this.registrationForm.value.subscription_purpose,
            address: this.registrationForm.value.address,
            firstName: this.registrationForm.value.firstname,
            lastName: this.registrationForm.value.lastname,
            password: this.registrationForm.value.password,
            email: (this.registrationForm.value.email as string)
              .toLowerCase()
              .trim(),
            jobTitle: this.registrationForm.value.job_title,
            invitedRecipients:
              this.registrationType === 'corporate' ? inviteesDictionary : null,
            profilePicture: this.registrationForm.value.profile_picture,
            rcNumber: null,
            companyDocument: null,
            allowUsersOutsideDomainToJoinOrganization: false,
            // rcNumber: this.registrationForm.value.rc_number,
            // companyDocument: this.registrationForm.value.company_document,
            browser: `${this.helperService.getBrowserName()}${deviceGuid}`,
            deviceId: '',
            deviceName: '',
            deviceType: 0,
          },
        },
      })
    );
  }

  ngOnDestroy() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }

    if (this.scrollSnapSectionsObserver) {
      document
        .querySelectorAll('.scroll-snap-section')
        .forEach((scrollSnapSection) => {
          this.scrollSnapSectionsObserver.unobserve(scrollSnapSection);
        });
    }
  }
}
