import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Subject, Subscription } from 'rxjs';
import { DefaultControlTypeStyles } from 'src/app/@core/constants';
import {
  ControlModeEnum,
  ControlTypeEnum,
  EditorModeEnum,
  EditorSignTypeEnum,
} from 'src/app/@core/enums';
import { ControlMode, EditorMode, EditorSignType } from 'src/app/@core/types';
import { ControlMenuBarComponent } from '../control-menu-bar/control-menu-bar.component';
import { CheckboxComponent } from '../controls/checkbox/checkbox.component';
import { DateComponent } from '../controls/date/date.component';
import { SignatureComponent } from '../controls/signature/signature.component';
import { TextComponent } from '../controls/text/text.component';

@Component({
  selector: 'app-control-builder',
  templateUrl: './control-builder.component.html',
  styleUrls: ['./control-builder.component.scss'],
})
export class ControlBuilderComponent
  implements OnInit, AfterViewInit, AfterContentInit, OnDestroy
{
  hoverState = new Subject<'mouseover' | 'mouseout'>();
  controlIsActive: boolean = false;
  resizeBound: any;
  stopResizeBound: any;
  documentPageContainerResizeObserver: any;
  isMouseDown: boolean = false;
  dragActive = false;
  currentX: any;
  currentY: any;
  initialX: any;
  initialY: any;
  initialLeft: any;
  initialTop: any;
  pageGridWidth = 8; //Formerly 16
  pageGridHeight = 8; //Formerly 16
  gridSize = 4;
  documentPageContainerOriginalWidth: number;
  documentPageContainerOriginalHeight: number;
  activeResizeHandle: HTMLElement | null = null;
  resize_MinimumSize: number = 15;
  resize_InitialClientX: number;
  resize_InitialClientY: number;
  resize_InitialControlWidth: number;
  resize_InitialControlHeight: number;
  resize_InitialControlLeft: number;
  resize_InitialControlTop: number;
  controlResize = new Subject();
  editorSignTypeEnum = EditorSignTypeEnum;
  controlModeEnum = ControlModeEnum;
  flwmnControlGridOverlay: HTMLElement;

  private subscription: Subscription = new Subscription();

  private componentRef: ComponentRef<any>;

  @Input() control: any;
  @Input() editorMode: EditorMode;
  @Input() controlMode: ControlMode;
  @Input() signType: EditorSignType;
  // @Input() actorMenu: boolean = false;
  @Input() showControlMenuBar: boolean;
  @Input() dropEvent: any;
  @Input() centerControlOnDrop: boolean = true;
  @Input() id!: string;
  @Input() form!: FormGroup;
  // @Input() allActorDetails: {
  //   actorListName: string;
  //   actorData: {
  //     actorId: number;
  //     actorFirstName: string;
  //     actorLastName: string;
  //     actorEmail: string;
  //   }[];
  // } | null = null;

  @Output() updatedControl = new EventEmitter<any>();
  @Output() formControlEmitter = new EventEmitter<any>();
  @Output() duplicateControl = new EventEmitter<any>();
  @Output() removeControl = new EventEmitter<any>();

  @ViewChild('controlOverlay', { static: false })
  controlOverlay?: ElementRef;

  @ViewChild('viewContainerRef', { read: ViewContainerRef })
  public viewContainerRef!: ViewContainerRef;

  // @ViewChild('removeControlIconContainer', { static: false })
  // removeControlIconContainer?: ElementRef;

  @ViewChild(ControlMenuBarComponent, { static: false })
  controlMenuBarComponent?: ControlMenuBarComponent;

  // @ViewChildren('resizeHandle')
  // resizeHandle?: QueryList<ElementRef>;

  @ViewChild('topLeftResizeHandle', { static: false })
  topLeftResizeHandle?: ElementRef;

  @ViewChild('topRightResizeHandle', { static: false })
  topRightResizeHandle?: ElementRef;

  @ViewChild('bottomLeftResizeHandle', { static: false })
  bottomLeftResizeHandle?: ElementRef;

  @ViewChild('bottomRightResizeHandle', { static: false })
  bottomRightResizeHandle?: ElementRef;

  constructor(
    public elementRef: ElementRef,
    private renderer: Renderer2,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (
      // this.editorMode === EditorModeEnum.Internal ||
      // this.editorMode === EditorModeEnum.PatchProcessConfigurationForm
      this.controlMode === ControlModeEnum.Internal
    ) {
      this.setOriginalDocumentPageContainerDimensions();
    }

    this.setControlStyles();

    this.listenToFormValueChangesSubscription();

    this.manageControlValidators();

    this.updateControlWithElementRef();

    if (
      // this.editorMode === EditorModeEnum.Internal ||
      // this.editorMode === EditorModeEnum.PatchProcessConfigurationForm
      this.controlMode === ControlModeEnum.Internal
    ) {
      this.setControlsInitialPosition();
    }
  }

  ngAfterViewInit(): void {
    this.flwmnControlGridOverlay = (
      this.elementRef.nativeElement as HTMLElement
    )?.parentElement?.children[0] as HTMLElement;

    this.generateControlFromControlType();

    this.setupWindowMouseAndTouchEventListeners();

    this.handleFlowmonoPageClick();

    this.setupElementRefMouseEventListeners();

    this.listenToFormValueChanges();

    this.listenToControlValueChanges();

    this.reassignActor();
  }

  ngAfterContentInit(): void {
    this.setupResizeFunctionForDocumentPageContainers();
  }

  setOriginalDocumentPageContainerDimensions() {
    const targetElement = this.dropEvent?.target as HTMLElement;

    this.documentPageContainerOriginalWidth =
      targetElement?.getBoundingClientRect().width;

    this.documentPageContainerOriginalHeight =
      targetElement?.getBoundingClientRect().height;

    // this.documentPageContainerOriginalWidth = targetElement?.clientWidth;

    // this.documentPageContainerOriginalHeight = targetElement?.clientHeight;
  }

  setControlStyles() {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'width',
      !this.control?.coordinate?.width
        ? DefaultControlTypeStyles[this.control.type].width
        : this.control?.coordinate?.width
    );

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'height',
      !this.control?.coordinate?.height
        ? DefaultControlTypeStyles[this.control.type].height
        : this.control?.coordinate?.height
    );

    if (
      this.control.type === ControlTypeEnum.text ||
      this.control.type === ControlTypeEnum.date ||
      this.control.type === ControlTypeEnum.name ||
      this.control.type === ControlTypeEnum.email ||
      this.control.type === ControlTypeEnum.jobTitle
    ) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'font-family',
        !this.control?.additionalStyles['font-family']
          ? DefaultControlTypeStyles[this.control.type]['font-family']
          : this.control?.additionalStyles['font-family']
      );

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'font-size',
        !this.control?.additionalStyles['font-size']
          ? DefaultControlTypeStyles[this.control.type]['font-size']
          : this.control?.additionalStyles['font-size']
      );

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'font-weight',
        !this.control?.additionalStyles['font-weight']
          ? DefaultControlTypeStyles[this.control.type]['font-weight']
          : this.control?.additionalStyles['font-weight']
      );

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'color',
        !this.control?.additionalStyles['color']
          ? DefaultControlTypeStyles[this.control.type]['color']
          : this.control?.additionalStyles['color']
      );

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'text-align',
        !this.control?.additionalStyles['text-align']
          ? DefaultControlTypeStyles[this.control.type]['text-align']
          : this.control?.additionalStyles['text-align']
      );
    }
  }

  listenToFormValueChangesSubscription() {
    this.subscription.add(
      this.form.valueChanges.subscribe((value) => {
        this.updateControlValueAndCoordinates();
      })
    );
  }

  updateControlValueAndCoordinates() {
    const oldAttribute: any = JSON.parse(
      this.elementRef.nativeElement.getAttribute('data-control')
    );

    let newAttribute = JSON.parse(JSON.stringify(oldAttribute));

    newAttribute['coordinate']['transform'] =
      this.elementRef.nativeElement.style.transform;

    newAttribute['coordinate']['width'] =
      this.elementRef.nativeElement.style.width;

    newAttribute['coordinate']['height'] =
      this.elementRef.nativeElement.style.height;

    newAttribute['pageNumber'] = (
      this.elementRef.nativeElement as HTMLElement
    ).parentElement?.getAttribute('data-page-number');

    newAttribute['value'] = this.form.get(this.control.name)?.value;

    if (
      this.control.type === ControlTypeEnum.text ||
      this.control.type === ControlTypeEnum.date ||
      this.control.type === ControlTypeEnum.name ||
      this.control.type === ControlTypeEnum.email ||
      this.control.type === ControlTypeEnum.jobTitle
    ) {
      if (this.elementRef.nativeElement.style.fontFamily) {
        newAttribute = {
          ...newAttribute,
          additionalStyles: {
            ...newAttribute['additionalStyles'],
            'font-family': this.elementRef.nativeElement.style.fontFamily,
          },
        };
      }

      if (this.elementRef.nativeElement.style.fontSize) {
        newAttribute = {
          ...newAttribute,
          additionalStyles: {
            ...newAttribute['additionalStyles'],
            'font-size': this.elementRef.nativeElement.style.fontSize,
          },
        };
      }

      if (this.elementRef.nativeElement.style.fontWeight) {
        newAttribute = {
          ...newAttribute,
          additionalStyles: {
            ...newAttribute['additionalStyles'],
            'font-weight': this.elementRef.nativeElement.style.fontWeight,
          },
        };
      }

      if (this.elementRef.nativeElement.style.color) {
        newAttribute = {
          ...newAttribute,
          additionalStyles: {
            ...newAttribute['additionalStyles'],
            color: this.elementRef.nativeElement.style.color,
          },
        };
      }

      if (this.elementRef.nativeElement.style.textAlign) {
        newAttribute = {
          ...newAttribute,
          additionalStyles: {
            ...newAttribute['additionalStyles'],
            'text-align': this.elementRef.nativeElement.style.textAlign,
          },
        };
      }
    }

    this.elementRef.nativeElement.setAttribute(
      'data-control',
      JSON.stringify(newAttribute)
    );
  }

  checkboxTrueValidator(error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }

      return control?.value === false ? error : null;
    };
  }

  manageControlValidators() {
    const formControl: any = {};
    let validators: any[] | null = [];

    if (this.control?.validators && this.control?.validators !== null) {
      this.control?.validators?.forEach((validator: any) => {
        if (validator === 'required') {
          validators!.push(Validators.required);
        }

        if (validator === 'email') {
          validators!.push(Validators.email);
        }

        if (validator === 'checkboxTrue') {
          validators!.push(
            this.checkboxTrueValidator({
              checkboxTrue: true,
            })
          );
        }
      });
    } else {
      validators = null;
    }

    formControl[this.control?.name] = new FormControl(
      {
        value: this.control?.value,
        disabled: this.control?.disabled ? true : false,
      },
      validators !== null ? validators : []
    );

    // if (this.control?.type !== 'checkbox') {
    //   formControl[this.control?.name] = new FormControl(
    //     {
    //       value: this.control?.value,
    //       disabled: this.control?.disabled ? true : false,
    //     },
    //     validators !== null ? validators : []
    //   );
    // } else {
    //   // let opts = {};
    //   // for (let opt of control.options) {
    //   //   opts[opt.key] = new FormControl(opt.value);
    //   // }
    //   // formControl[control.name] = new FormGroup(opts)
    // }

    this.formControlEmitter.emit(formControl);
  }

  updateControlWithElementRef() {
    this.control = {
      ...this.control,
      element: this.elementRef.nativeElement,
    };

    this.updatedControl.emit(this.control);
  }

  setControlsInitialPosition() {
    for (const key in this.control.coordinate) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        `${key}`,
        `${this.control.coordinate[key]}`
      );
    }

    if (this.centerControlOnDrop === true) {
      const translate3d = (
        this.elementRef.nativeElement as HTMLElement
      ).style.transform
        .split('translate3d')[1]
        .replace('(', '')
        .replace(')', '')
        .split(',');

      const oldX = parseFloat(translate3d[0].split('px')[0]);
      const oldY = parseFloat(translate3d[1].split('px')[0]);

      const width = (
        this.elementRef.nativeElement as HTMLElement
      ).getBoundingClientRect().width;

      const height = (
        this.elementRef.nativeElement as HTMLElement
      ).getBoundingClientRect().height;

      const x = oldX - width / 2;
      const y = oldY - height / 2;

      (
        this.elementRef.nativeElement as HTMLElement
      ).style.transform = `translate3d(${x}px, ${y}px, 0px)`;
    }
  }

  generateControlFromControlType() {
    let controlComponent: any;

    if (this.control.type === ControlTypeEnum.signature) {
      controlComponent = SignatureComponent;
    } else if (
      this.control.type === ControlTypeEnum.text ||
      this.control.type === ControlTypeEnum.name ||
      this.control.type === ControlTypeEnum.email ||
      this.control.type === ControlTypeEnum.jobTitle
    ) {
      controlComponent = TextComponent;
    } else if (this.control.type === ControlTypeEnum.date) {
      controlComponent = DateComponent;
    } else if (this.control.type === ControlTypeEnum.checkbox) {
      controlComponent = CheckboxComponent;
    }

    if (controlComponent) {
      // const componentRef: ComponentRef<any> =
      //   this.viewContainerRef.createComponent(controlComponent);

      this.componentRef =
        this.viewContainerRef.createComponent(controlComponent);

      this.componentRef.instance.id = this.id;
      this.componentRef.instance.form = this.form;
      this.componentRef.instance.control = this.control;
      this.componentRef.instance.editorMode =
        this.editorMode === EditorModeEnum.PatchProcessConfigurationForm
          ? EditorModeEnum.Internal
          : this.editorMode;
      this.componentRef.instance.controlMode = this.controlMode;

      if (
        this.control.type === ControlTypeEnum.text ||
        this.control.type === ControlTypeEnum.date ||
        this.control.type === ControlTypeEnum.name ||
        this.control.type === ControlTypeEnum.email ||
        this.control.type === ControlTypeEnum.jobTitle
      ) {
        this.componentRef.instance.hoverState = this.hoverState;
      }

      if (
        this.control.type === ControlTypeEnum.text ||
        this.control.type === ControlTypeEnum.name ||
        this.control.type === ControlTypeEnum.email ||
        this.control.type === ControlTypeEnum.jobTitle
      ) {
        this.componentRef.instance.controlResize = this.controlResize;

        this.subscription.add(
          this.componentRef.instance.textHeight.subscribe((height: number) => {
            (
              this.elementRef.nativeElement as HTMLElement
            ).style.height = `${height}px`;

            this.updateControlValueAndCoordinates();
          })
        );
      }

      if (
        this.control.type === ControlTypeEnum.name ||
        this.control.type === ControlTypeEnum.email ||
        this.control.type === ControlTypeEnum.jobTitle
      ) {
        this.componentRef.instance.controlResize = this.controlResize;

        // (
        //   componentRef as ComponentRef<TextComponent>
        // ).instance.calculateTextControlWidth();

        this.subscription.add(
          this.componentRef.instance.textWidth.subscribe((width: number) => {
            if (width) {
              (
                this.elementRef.nativeElement as HTMLElement
              ).style.width = `${width}px`;

              this.updateControlValueAndCoordinates();
            }
          })
        );

        // const textWidth = (componentRef as ComponentRef<TextComponent>).instance
        //   .textWidth;

        // const timeout = setTimeout(() => {
        //   if (textWidth) {
        //     (
        //       this.elementRef.nativeElement as HTMLElement
        //     ).style.width = `${textWidth}px`;

        //     this.updateControlValueAndCoordinates();
        //   }

        //   clearTimeout(timeout);
        // });
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  // getShowActorsValue() {
  //   if (this.control.type === ControlType.checkmark) {
  //     return false;
  //   } else if (this.signType === EditorSignTypeEnum.Multiple) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }

  setupWindowMouseAndTouchEventListeners() {
    if (
      // this.editorMode === EditorModeEnum.Internal ||
      // this.editorMode === EditorModeEnum.PatchProcessConfigurationForm
      this.controlMode === ControlModeEnum.Internal
    ) {
      const dragStart = this.dragStart.bind(this);
      const dragEnd = this.dragEnd.bind(this);
      const drag = this.drag.bind(this);

      window.addEventListener('mousedown', dragStart, false);
      window.addEventListener('touchstart', dragStart, { passive: false });

      window.addEventListener('mouseup', dragEnd, false);
      window.addEventListener('touchend', dragEnd, { passive: false });

      window.addEventListener('mousemove', drag, false);
      window.addEventListener('touchmove', drag, { passive: false });

      // const resizerHandles = document.querySelectorAll(
      //   '.flwmn-control .resize-handle'
      // );
      // for (let i = 0; i < resizerHandles.length; i++) {
      //   this.initializeControlResizer((resizerHandles[i] as HTMLElement)!);
      // }

      this.initializeControlResizer(this.topLeftResizeHandle!.nativeElement);
      this.initializeControlResizer(this.topRightResizeHandle!.nativeElement);
      this.initializeControlResizer(this.bottomLeftResizeHandle!.nativeElement);
      this.initializeControlResizer(
        this.bottomRightResizeHandle!.nativeElement
      );
    }
  }

  dragStart(e: any) {
    const containerRect =
      this.elementRef?.nativeElement?.parentNode?.getBoundingClientRect();

    const isTouchStart = e.type === 'touchstart';

    this.setEditorCanvasTouchAction(isTouchStart ? 'none' : '');

    this.initialX = isTouchStart ? e.touches[0].clientX : e.clientX;
    this.initialY = isTouchStart ? e.touches[0].clientY : e.clientY;

    // Get the position relative to the immediate parent container
    const targetRect = (e?.target as HTMLElement)?.getBoundingClientRect();
    this.initialLeft = targetRect?.left - (containerRect?.left || 0);
    this.initialTop = targetRect?.top - (containerRect?.top || 0);

    const eventComposedPath = e.composedPath();

    const isDraggableElement = eventComposedPath.includes(
      this.elementRef.nativeElement
    );

    const isResizeHandle = [
      'top-left',
      'top-right',
      'bottom-left',
      'bottom-right',
    ].some((handleClass) =>
      eventComposedPath.some((el: HTMLElement) =>
        el?.classList?.contains(handleClass)
      )
    );

    if (
      isDraggableElement &&
      !isResizeHandle &&
      !eventComposedPath.some((el: HTMLElement) =>
        el?.classList?.contains('app-control-menu-bar')
      )
    ) {
      this.dragActive = true;
    } else {
      this.dragEnd(e);
    }
  }

  /** Old working function before refactoring **/
  // dragStart(e: any) {
  // const containerRect = (
  //   this.elementRef?.nativeElement?.parentNode as HTMLElement
  // )?.getBoundingClientRect();

  //   if (e.type === 'touchstart') {
  //     this.setEditorCanvasTouchAction('none');

  //     this.initialX = e.touches[0].clientX;
  //     this.initialY = e.touches[0].clientY;
  //   } else if (e.type === 'mousedown') {
  //     this.initialX = e.clientX;
  //     this.initialY = e.clientY;
  //   }

  //   /**By subtracting the containerRect by the targetRect,
  //    * I'm getting it's position relative to the container
  //    * this is needed to get the position relative to
  //    * the immediate parent container
  //    */
  //   this.initialLeft =
  //     (e?.target as HTMLElement)?.getBoundingClientRect()?.left -
  //     containerRect?.left;
  //   this.initialTop =
  //     (e?.target as HTMLElement)?.getBoundingClientRect()?.top -
  //     containerRect?.top;
  //   /*****************************************************************/

  //   const eventComposedPath = e.composedPath();

  //   if (
  //     eventComposedPath.includes(this.elementRef.nativeElement) &&
  //     eventComposedPath.findIndex((el: any) =>
  //       el?.classList?.contains('top-left')
  //     ) === -1 &&
  //     eventComposedPath.findIndex((el: any) =>
  //       el?.classList?.contains('top-right')
  //     ) === -1 &&
  //     eventComposedPath.findIndex((el: any) =>
  //       el?.classList?.contains('bottom-left')
  //     ) === -1 &&
  //     eventComposedPath.findIndex((el: any) =>
  //       el?.classList?.contains('bottom-right')
  //     ) === -1 &&
  //     eventComposedPath.findIndex((el: any) =>
  //       el?.classList?.contains('app-control-menu-bar')
  //     ) === -1

  //     // e.composedPath().includes(this.elementRef.nativeElement) &&
  //     // !e.composedPath().includes(this.topLeftResizeHandle?.nativeElement) &&
  //     // !e.composedPath().includes(this.topRightResizeHandle?.nativeElement) &&
  //     // !e.composedPath().includes(this.bottomLeftResizeHandle?.nativeElement) &&
  //     // !e.composedPath().includes(this.bottomRightResizeHandle?.nativeElement) &&
  //     // !e.composedPath().includes(this.controlMenuBarComponent?.elementRef)
  //   ) {
  //     this.dragActive = true;
  //   } else {
  //     this.dragEnd(e);
  //   }
  // }
  /*********************************************/

  dragEnd(e: any) {
    this.setEditorCanvasTouchAction('');

    this.dragActive = false;

    // Clear previous alignment lines
    while (this.flwmnControlGridOverlay.firstChild) {
      this.flwmnControlGridOverlay.removeChild(
        this.flwmnControlGridOverlay.firstChild
      );
    }

    // if (
    //   e.target.parentElement.parentElement === this.elementRef.nativeElement
    // ) {
    // this.initGetPageControlItemById();
    // }
  }

  drag(e: any) {
    if (!this.dragActive) {
      return;
    }

    e.preventDefault();

    let flowmonoPage!: HTMLElement;

    e.composedPath().forEach((el: HTMLElement) => {
      if (el && el?.classList?.contains('flwmn-page')) {
        flowmonoPage = el;
      }
    });

    const controlMenuBar = document.querySelector('#control-menu-bar');

    if (flowmonoPage && !e.composedPath().includes(controlMenuBar)) {
      const isTouchMove = e.type === 'touchmove';

      this.currentX =
        this.initialLeft +
        (isTouchMove ? e.touches[0].clientX : e.clientX) -
        this.initialX;

      this.currentY =
        this.initialTop +
        (isTouchMove ? e.touches[0].clientY : e.clientY) -
        this.initialY;

      const minBoundaryX = 0;
      const maxBoundaryX =
        flowmonoPage.getBoundingClientRect().width -
        this.elementRef.nativeElement.offsetWidth;

      const minBoundaryY = 0;
      const maxBoundaryY =
        flowmonoPage.getBoundingClientRect().height -
        this.elementRef.nativeElement.offsetHeight;

      // let newX = Math.round(this.currentX / this.gridSize) * this.gridSize;
      // let newY = Math.round(this.currentY / this.gridSize) * this.gridSize;

      let newX = this.currentX;
      let newY = this.currentY;

      /**
       * For currentX:
       * -------------
       * Math.min(maxBoundaryX, this.currentX): This part ensures that this.currentX does not exceed the maximum allowed value (maxBoundaryX).
       * If this.currentX is greater than maxBoundaryX, it will be set to maxBoundaryX.

       * Math.max(minBoundaryX, ... ): This part ensures that the result from the previous step does not go below the minimum allowed value (minBoundaryX).
       * If the calculated value is less than minBoundaryX, it will be set to minBoundaryX.

       * For currentY:
       * -------------
       * The same logic is applied to currentY:

       * Math.min(maxBoundaryY, this.currentY): Ensure this.currentY does not exceed maxBoundaryY.
       * Math.max(minBoundaryY, ... ): Ensure the result does not go below minBoundaryY.

       * In summary, these lines are clamping the currentX and currentY values to be within the specified boundaries defined by minBoundaryX,
       * maxBoundaryX, minBoundaryY, and maxBoundaryY. This is crucial to prevent the dragged element from going beyond the allowed area on the page.
       */

      newX = Math.max(minBoundaryX, Math.min(maxBoundaryX, newX));
      newY = Math.max(minBoundaryY, Math.min(maxBoundaryY, newY));

      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'transform',
        `translate3d(${newX}px, ${newY}px, 0px)`
      );

      // Update grid lines
      // this.updateGridLines(newX, newY);

      this.updateControlValueAndCoordinates();
    }
  }

  updateGridLines(x: number, y: number) {
    const gridLines = document.querySelectorAll('.grid-line');

    // Clear previous grid lines
    gridLines.forEach((line) => line.remove());

    // Get all draggable controls
    const allFlowmonoControls = document.querySelectorAll('.flwmn-control');

    allFlowmonoControls.forEach((control: any) => {
      if (control !== this.elementRef.nativeElement) {
        const controlRect = (control as HTMLElement).getBoundingClientRect();
        const offsetParentRect = (
          this.elementRef?.nativeElement?.parentNode as HTMLElement
        )?.getBoundingClientRect();

        // Calculate relative positions
        const relativeX = controlRect.left - offsetParentRect.left;
        const relativeY = controlRect.top - offsetParentRect.top;

        // Draw vertical alignment lines
        if (Math.abs(x - relativeX) <= this.gridSize) {
          this.drawGridLine(relativeX, 0, '1px', '100%');
        }
        if (Math.abs(x - (relativeX + controlRect.width)) <= this.gridSize) {
          this.drawGridLine(relativeX + controlRect.width, 0, '1px', '100%');
        }

        // Draw horizontal alignment lines
        if (Math.abs(y - relativeY) <= this.gridSize) {
          this.drawGridLine(0, relativeY, '100%', '1px');
        }
        if (Math.abs(y - (relativeY + controlRect.height)) <= this.gridSize) {
          this.drawGridLine(0, relativeY + controlRect.height, '100%', '1px');
        }

        // Center alignment lines
        if (
          Math.abs(x - (relativeX + controlRect.width / 2)) <= this.gridSize
        ) {
          this.drawGridLine(
            relativeX + controlRect.width / 2,
            0,
            '1px',
            '100%'
          );
        }
        if (
          Math.abs(y - (relativeY + controlRect.height / 2)) <= this.gridSize
        ) {
          this.drawGridLine(
            0,
            relativeY + controlRect.height / 2,
            '100%',
            '1px'
          );
        }

        // Center alignment lines for the center of the control
        if (
          Math.abs(x - (relativeX + controlRect.width / 2)) <= this.gridSize &&
          Math.abs(y - (relativeY + controlRect.height / 2)) <= this.gridSize
        ) {
          this.drawGridLine(
            relativeX + controlRect.width / 2,
            relativeY + controlRect.height / 2,
            '1px',
            '1px'
          );
        }

        // Additional center alignment lines for corners
        if (
          Math.abs(x - relativeX) <= this.gridSize &&
          Math.abs(y - relativeY) <= this.gridSize
        ) {
          this.drawGridLine(relativeX, relativeY, '1px', '1px');
        }
        if (
          Math.abs(x - (relativeX + controlRect.width)) <= this.gridSize &&
          Math.abs(y - relativeY) <= this.gridSize
        ) {
          this.drawGridLine(
            relativeX + controlRect.width,
            relativeY,
            '1px',
            '1px'
          );
        }
        if (
          Math.abs(x - relativeX) <= this.gridSize &&
          Math.abs(y - (relativeY + controlRect.height)) <= this.gridSize
        ) {
          this.drawGridLine(
            relativeX,
            relativeY + controlRect.height,
            '1px',
            '1px'
          );
        }
        if (
          Math.abs(x - (relativeX + controlRect.width)) <= this.gridSize &&
          Math.abs(y - (relativeY + controlRect.height)) <= this.gridSize
        ) {
          this.drawGridLine(
            relativeX + controlRect.width,
            relativeY + controlRect.height,
            '1px',
            '1px'
          );
        }
      }
    });
  }

  drawGridLine(left: number, top: number, width: string, height: string) {
    const line = document.createElement('div');

    line.className = 'grid-line';
    line.style.position = 'absolute';
    line.style.backgroundColor = 'red';
    line.style.left = `${left}px`;
    line.style.top = `${top}px`;
    line.style.width = width;
    line.style.height = height;

    this.flwmnControlGridOverlay.appendChild(line);
  }

  /**This is fine start**/
  // updateGridLines(x: number, y: number, gridSize: number) {
  //   const gridLines = document.querySelectorAll('.grid-line');

  //   // Clear previous grid lines
  //   gridLines.forEach((line) => line.remove());

  //   // Get the nearest draggable
  //   const nearestDraggable = this.getNearestFlowmonoControl(x, y);

  //   if (nearestDraggable) {
  //     const nearestDraggableRect = nearestDraggable.getBoundingClientRect();
  //     const offsetParentRect =
  //       nearestDraggable.offsetParent!.getBoundingClientRect();

  //     // Calculate relative positions
  //     const relativeX = nearestDraggableRect.left - offsetParentRect.left;
  //     const relativeY = nearestDraggableRect.top - offsetParentRect.top;

  //     // Draw vertical alignment lines
  //     if (Math.abs(x - relativeX) <= gridSize) {
  //       this.drawGridLine(relativeX, 0, '1px', '100%'); // Align left
  //     }
  //     if (Math.abs(x - (relativeX + nearestDraggableRect.width)) <= gridSize) {
  //       this.drawGridLine(
  //         relativeX + nearestDraggableRect.width,
  //         0,
  //         '1px',
  //         '100%'
  //       ); // Align right
  //     }

  //     // Draw horizontal alignment lines
  //     if (Math.abs(y - relativeY) <= gridSize) {
  //       this.drawGridLine(0, relativeY, '100%', '1px'); // Align top
  //     }
  //     if (Math.abs(y - (relativeY + nearestDraggableRect.height)) <= gridSize) {
  //       this.drawGridLine(
  //         0,
  //         relativeY + nearestDraggableRect.height,
  //         '100%',
  //         '1px'
  //       ); // Align bottom
  //     }

  //     // Center alignment lines
  //     if (
  //       Math.abs(x - (relativeX + nearestDraggableRect.width / 2)) <= gridSize
  //     ) {
  //       this.drawGridLine(
  //         relativeX + nearestDraggableRect.width / 2,
  //         0,
  //         '1px',
  //         '100%'
  //       );
  //     }
  //     if (
  //       Math.abs(y - (relativeY + nearestDraggableRect.height / 2)) <= gridSize
  //     ) {
  //       this.drawGridLine(
  //         0,
  //         relativeY + nearestDraggableRect.height / 2,
  //         '100%',
  //         '1px'
  //       );
  //     }

  //     // Center alignment lines for the center of the nearest control
  //     if (
  //       Math.abs(x - (relativeX + nearestDraggableRect.width / 2)) <=
  //         gridSize &&
  //       Math.abs(y - (relativeY + nearestDraggableRect.height / 2)) <= gridSize
  //     ) {
  //       this.drawGridLine(
  //         relativeX + nearestDraggableRect.width / 2,
  //         relativeY + nearestDraggableRect.height / 2,
  //         '1px',
  //         '1px'
  //       );
  //     }
  //   }
  // }

  // drawGridLine(
  //   left: number,
  //   top: number,
  //   width: string,
  //   height: string
  //   // thickness: string
  // ) {
  //   const line = document.createElement('div');

  //   line.className = 'grid-line';
  //   line.style.position = 'absolute';
  //   line.style.backgroundColor = 'red';
  //   line.style.left = `${left}px`;
  //   line.style.top = `${top}px`;
  //   line.style.width = width;
  //   line.style.height = height;

  //   this.gridOverlay.appendChild(line);
  // }
  /**This is fine end**/

  // calculateDistance(x1: number, y1: number, x2: number, y2: number) {
  //   const deltaX = x2 - x1;
  //   const deltaY = y2 - y1;
  //   return Math.sqrt(deltaX * deltaX + deltaY * deltaY);
  // }

  // getNearestFlowmonoControl(
  //   targetX: number,
  //   targetY: number
  // ): HTMLElement | null {
  //   let nearestComponent: HTMLElement | null = null;
  //   let minDistance = Infinity;

  //   const allFlowmonoControls = document.querySelectorAll('.flwmn-control');

  //   allFlowmonoControls.forEach((component: any) => {
  //     const { x, y } = component.getBoundingClientRect(); // Adjust this line based on how you get the coordinates of the components

  //     // Check distances to all corners
  //     const distances = [
  //       this.calculateDistance(targetX, targetY, x, y),
  //       this.calculateDistance(targetX, targetY, x + component.offsetWidth, y),
  //       this.calculateDistance(targetX, targetY, x, y + component.offsetHeight),
  //       this.calculateDistance(
  //         targetX,
  //         targetY,
  //         x + component.offsetWidth,
  //         y + component.offsetHeight
  //       ),
  //     ];

  //     // Check distances to all sides
  //     distances.push(
  //       Math.abs(targetX - x),
  //       Math.abs(targetX - (x + component.offsetWidth)),
  //       Math.abs(targetY - y),
  //       Math.abs(targetY - (y + component.offsetHeight))
  //     );

  //     const minDistanceToComponent = Math.min(...distances);

  //     if (minDistanceToComponent < minDistance) {
  //       minDistance = minDistanceToComponent;
  //       nearestComponent = component;
  //     }
  //   });

  //   return nearestComponent;
  // }

  setEditorCanvasTouchAction(action: 'none' | '') {
    const editorCanvas = document.getElementById('editor-canvas')!;
    if (editorCanvas) {
      editorCanvas.style.touchAction = action;
    }
  }

  initializeControlResizer(resizeHandle: HTMLElement) {
    this.resizeBound = this.resize.bind(this);
    this.stopResizeBound = this.stopResize.bind(this);

    resizeHandle!.addEventListener(
      'mousedown',
      (event: MouseEvent) => {
        this.resizeMouseDownTouchStartActions(
          event,
          event.clientX,
          event.clientY
        );

        this.activeResizeHandle = (event.target as HTMLElement)!;

        window.addEventListener('mousemove', this.resizeBound, false);

        window.addEventListener('mouseup', this.stopResizeBound, false);
      },
      false
    );

    resizeHandle!.addEventListener(
      'touchstart',
      (event: TouchEvent) => {
        this.resizeMouseDownTouchStartActions(
          event,
          event.touches[0].clientX,
          event.touches[0].clientY
        );

        this.activeResizeHandle = (event.target as HTMLElement)!;

        window.addEventListener('touchmove', this.resizeBound, false);

        window.addEventListener('touchend', this.stopResizeBound, false);
      },
      { passive: false }
    );
  }

  resizeMouseDownTouchStartActions(
    event: MouseEvent | TouchEvent,
    clientX: number,
    clientY: number
  ) {
    event.preventDefault();
    this.isMouseDown = true;

    const controlBoundingClientRect = (
      this.elementRef.nativeElement as HTMLElement
    ).getBoundingClientRect();

    this.resize_InitialClientX = clientX;
    this.resize_InitialClientY = clientY;
    this.resize_InitialControlWidth = controlBoundingClientRect.width;
    this.resize_InitialControlHeight = controlBoundingClientRect.height;

    const parentContainerRect = (
      this.elementRef?.nativeElement?.parentNode as HTMLElement
    )?.getBoundingClientRect();

    /**By subtracting the controlBoundingClientRect by the parentContainerRect,
     * I'm getting it's position relative to the container
     * this is needed to get the position relative to
     * the immediate parent container
     */

    this.resize_InitialControlLeft =
      controlBoundingClientRect.left - parentContainerRect?.left;
    this.resize_InitialControlTop =
      controlBoundingClientRect.top - parentContainerRect?.top;
  }

  resize(event: any) {
    if (!this.isMouseDown) {
      return;
    }

    window.requestAnimationFrame(() => {
      let clientX: number = 0;
      let clientY: number = 0;

      if (event.type === 'touchmove') {
        this.setEditorCanvasTouchAction('none');
        clientX = event.touches[0].clientX;
        clientY = event.touches[0].clientY;
      } else if (event.type === 'mousemove') {
        clientX = event.clientX;
        clientY = event.clientY;
      }

      if (this.activeResizeHandle !== null) {
        const deltaX = clientX - this.resize_InitialClientX;
        const deltaY = clientY - this.resize_InitialClientY;

        const width =
          this.resize_InitialControlWidth +
          (this.activeResizeHandle.classList.contains('top-right') ||
          this.activeResizeHandle.classList.contains('bottom-right')
            ? deltaX
            : -deltaX);

        const height =
          this.resize_InitialControlHeight +
          (this.activeResizeHandle.classList.contains('bottom-left') ||
          this.activeResizeHandle.classList.contains('bottom-right')
            ? deltaY
            : -deltaY);

        const x =
          this.resize_InitialControlLeft +
          (this.activeResizeHandle.classList.contains('top-left') ||
          this.activeResizeHandle.classList.contains('bottom-left')
            ? deltaX
            : 0);

        const y =
          this.resize_InitialControlTop +
          (this.activeResizeHandle.classList.contains('top-left') ||
          this.activeResizeHandle.classList.contains('top-right')
            ? deltaY
            : 0);

        if (
          width > this.resize_MinimumSize &&
          height > this.resize_MinimumSize
        ) {
          (
            this.elementRef.nativeElement as HTMLElement
          ).style.width = `${width}px`;
          (
            this.elementRef.nativeElement as HTMLElement
          ).style.height = `${height}px`;
          (
            this.elementRef.nativeElement as HTMLElement
          ).style.transform = `translate3d(${x}px, ${y}px, 0px)`;
        }

        // Update grid lines
        // this.updateGridLines(clientX, clientY);

        this.updateControlValueAndCoordinates();

        this.controlResize.next('');
      }
    });
  }

  // resize(event: any) {
  //   if (!this.isMouseDown) {
  //     return;
  //   } else {
  //     window.requestAnimationFrame(() => {
  //       let clientX: number = 0;
  //       let clientY: number = 0;

  //       if (event.type === 'touchmove') {
  //         this.setEditorCanvasTouchAction('none');

  //         clientX = event.touches[0].clientX;
  //         clientY = event.touches[0].clientY;
  //       } else if (event.type === 'mousemove') {
  //         clientX = event.clientX;
  //         clientY = event.clientY;
  //       }

  //       if (this.activeResizeHandle !== null) {
  //         /*********************************** Top Left ********************************/
  //         if (this.activeResizeHandle.classList.contains('top-left')) {
  //           const width =
  //             this.resize_InitialControlWidth -
  //             (clientX - this.resize_InitialClientX);

  //           const height =
  //             this.resize_InitialControlHeight -
  //             (clientY - this.resize_InitialClientY);

  //           const x =
  //             this.resize_InitialControlLeft +
  //             (clientX - this.resize_InitialClientX);

  //           const y =
  //             this.resize_InitialControlTop +
  //             (clientY - this.resize_InitialClientY);

  //           if (
  //             width > this.resize_MinimumSize &&
  //             height > this.resize_MinimumSize
  //           ) {
  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.width = `${width}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.height = `${height}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.transform = `translate3d(${x}px, ${y}px, 0px)`;
  //           }
  //         } else if (this.activeResizeHandle.classList.contains('top-right')) {
  //           /*****************************^*** Top Right *********^***********************/
  //           const width =
  //             this.resize_InitialControlWidth +
  //             (clientX - this.resize_InitialClientX);

  //           const height =
  //             this.resize_InitialControlHeight -
  //             (clientY - this.resize_InitialClientY);

  //           const y =
  //             this.resize_InitialControlTop +
  //             (clientY - this.resize_InitialClientY);

  //           if (
  //             width > this.resize_MinimumSize &&
  //             height > this.resize_MinimumSize
  //           ) {
  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.width = `${width}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.height = `${height}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.transform = `translate3d(${this.resize_InitialControlLeft}px, ${y}px, 0px)`;
  //           }
  //         } else if (
  //           this.activeResizeHandle.classList.contains('bottom-left')
  //         ) {
  //           /******************************** Bottom Left ********************************/
  //           const width =
  //             this.resize_InitialControlWidth -
  //             (clientX - this.resize_InitialClientX);

  //           const height =
  //             this.resize_InitialControlHeight +
  //             (clientY - this.resize_InitialClientY);

  //           const x =
  //             this.resize_InitialControlLeft +
  //             (clientX - this.resize_InitialClientX);

  //           if (
  //             width > this.resize_MinimumSize &&
  //             height > this.resize_MinimumSize
  //           ) {
  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.width = `${width}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.height = `${height}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.transform = `translate3d(${x}px, ${this.resize_InitialControlTop}px, 0px)`;
  //           }
  //         } else if (
  //           this.activeResizeHandle.classList.contains('bottom-right')
  //         ) {
  //           /******************************** Bottom Right *******************************/
  //           const width =
  //             this.resize_InitialControlWidth +
  //             (clientX - this.resize_InitialClientX);

  //           const height =
  //             this.resize_InitialControlHeight +
  //             (clientY - this.resize_InitialClientY);

  //           if (
  //             width > this.resize_MinimumSize &&
  //             height > this.resize_MinimumSize
  //           ) {
  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.width = `${width}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.height = `${height}px`;

  //             (
  //               this.elementRef.nativeElement as HTMLElement
  //             ).style.transform = `translate3d(${this.resize_InitialControlLeft}px, ${this.resize_InitialControlTop}px, 0px)`;
  //           }
  //         }

  //         // Update grid lines
  //         this.updateGridLines(clientX, clientY, this.gridSize);

  //         this.updateControlValueAndCoordinates();

  //         this.controlResize.next('');
  //       }
  //     });
  //   }
  // }

  stopResize(e: any) {
    this.isMouseDown = false;

    this.activeResizeHandle = null;

    window.removeEventListener('mousemove', this.resizeBound, false);
    window.removeEventListener('touchmove', this.resizeBound, false);

    window.removeEventListener('mouseup', this.stopResizeBound, false);
    window.removeEventListener('touchend', this.stopResizeBound, false);

    this.setEditorCanvasTouchAction('');

    // if (e.target.parentElement === this.elementRef.nativeElement) {
    //   this.initGetPageControlItemById();
    // }
  }

  handleFlowmonoPageClick() {
    document.querySelectorAll('.flwmn-page').forEach((page) => {
      page!.addEventListener('click', () => {
        if (
          this.elementRef.nativeElement.classList.contains(
            'active-flwmn-control'
          )
        ) {
          this.elementRef.nativeElement.classList.remove(
            'active-flwmn-control'
          );

          this.controlIsActive = false;

          this.controlMenuBarComponent?.elementRef.nativeElement.classList.remove(
            'show-control-menu-bar'
          );

          // this.recipientMenuComponent?.elementRef.nativeElement.classList.remove(
          //   'active'
          // );

          // this.actorMenuComponent?.elementRef.nativeElement.classList.remove(
          //   'active'
          // );
        }
      });
    });
  }

  setupElementRefMouseEventListeners() {
    if (
      this.form.controls[this.control.name] &&
      this.form.controls[this.control.name].disabled === false
    ) {
      (this.elementRef.nativeElement as HTMLElement).addEventListener(
        'mouseover',
        (event: MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();

          this.hoverState.next('mouseover');

          this.controlMenuBarComponent?.elementRef.nativeElement.classList.add(
            'show-control-menu-bar'
          );

          // this.recipientMenuComponent?.elementRef.nativeElement.classList.add(
          //   'active'
          // );

          // this.actorMenuComponent?.elementRef.nativeElement.classList.add(
          //   'active'
          // );

          // this.removeControlIconContainer?.nativeElement.classList.add('show');
        },
        true
      );

      (this.elementRef.nativeElement as HTMLElement).addEventListener(
        'mouseout',
        (event: MouseEvent) => {
          event.stopPropagation();
          event.preventDefault();

          this.hoverState.next('mouseout');

          if (
            !this.elementRef.nativeElement.classList.contains(
              'active-flwmn-control'
            )
          ) {
            if (
              (event.target as HTMLElement) !==
              this.controlMenuBarComponent?.elementRef.nativeElement
            ) {
              this.controlMenuBarComponent?.elementRef.nativeElement.classList.remove(
                'show-control-menu-bar'
              );
            }

            // this.recipientMenuComponent?.elementRef.nativeElement.classList.remove(
            //   'active'
            // );
            // this.actorMenuComponent?.elementRef.nativeElement.classList.remove(
            //   'active'
            // );
          }

          // this.removeControlIconContainer?.nativeElement.classList.remove(
          //   'show'
          // );
        },
        true
      );
    } else {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'disabled-flwmn-control'
      );
    }
  }

  listenToFormValueChanges() {
    this.subscription.add(
      this.form.valueChanges.subscribe(() => {
        // this.manageControlError();
        // this.removeShadowForSigned();
      })
    );
  }

  manageControlError() {
    if (this.form.get(this.control.name)?.errors) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'flwmn-control-error'
      );
    } else {
      this.renderer.removeClass(
        this.elementRef.nativeElement,
        'flwmn-control-error'
      );
    }
  }

  listenToControlValueChanges() {
    this.subscription.add(
      this.form.controls[this.control.name]?.valueChanges.subscribe(
        (value: any) => {
          this.updateControlValueAndCoordinates();
          this.setSignature();
        }
      )
    );
  }

  reassignActor() {
    if (this.signType === EditorSignTypeEnum.Multiple) {
      const controlData: any = JSON.parse(
        this.elementRef.nativeElement.getAttribute('data-control')
      );

      const actorData = this.controlMenuBarComponent?.getActorDataByEmail(
        controlData?.recipientEmail
      );

      if (actorData) {
        this.controlMenuBarComponent?.onSelectActor({
          actorId: actorData?.actorId,
          actorFirstName: actorData?.actorFirstName,
          actorLastName: actorData?.actorLastName,
          actorEmail: actorData?.actorEmail,
        });
      }

      this.changeDetectorRef.detectChanges();
    }
  }

  setupResizeFunctionForDocumentPageContainers() {
    if (this.dropEvent.target?.parentNode) {
      this.documentPageContainerResizeObserver = new ResizeObserver(
        this.manageComponentPositionOnDocumentPageContainerResize?.bind(this)
      ).observe(this.dropEvent.target?.parentNode);
    }
  }

  manageComponentPositionOnDocumentPageContainerResize(
    event: ResizeObserverEntry[]
  ) {
    const documentPageContainerBoundingClientRect =
      event[0].target.getBoundingClientRect();

    const x =
      (documentPageContainerBoundingClientRect.width *
        this.getDimensionsFromElementRef().x) /
      this.documentPageContainerOriginalWidth;

    const y =
      (documentPageContainerBoundingClientRect.height *
        this.getDimensionsFromElementRef().y) /
      this.documentPageContainerOriginalHeight;

    const width =
      (documentPageContainerBoundingClientRect.width *
        this.getDimensionsFromElementRef().width) /
      this.documentPageContainerOriginalWidth;

    const height =
      (documentPageContainerBoundingClientRect.height *
        this.getDimensionsFromElementRef().height) /
      this.documentPageContainerOriginalHeight;

    this.documentPageContainerOriginalWidth =
      documentPageContainerBoundingClientRect.width;

    this.documentPageContainerOriginalHeight =
      documentPageContainerBoundingClientRect.height;

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'transform',
      `translate3d(${x}px, ${y}px, 0px)`
    );

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'width',
      `${width}px`
    );

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'height',
      `${height}px`
    );

    this.updateControlValueAndCoordinates();
  }

  getDimensionsFromElementRef() {
    const transformValue = (
      this.elementRef.nativeElement as HTMLElement
    ).style.transform.split('(')[1];

    const x = parseFloat(transformValue.split(',')[0].replace('px', ''));
    const y = parseFloat(transformValue.split(',')[1].replace('px', ''));

    const width = parseFloat(
      (this.elementRef.nativeElement as HTMLElement).style.width.replace(
        'px',
        ''
      )
    );

    const height = parseFloat(
      (this.elementRef.nativeElement as HTMLElement).style.height.replace(
        'px',
        ''
      )
    );

    return { x, y, width, height };
  }

  onClickControlOverlay(event: MouseEvent) {
    event.stopPropagation();
    if (this.form.controls[this.control.name].disabled === false) {
      if (
        !this.elementRef.nativeElement.classList.contains(
          'active-flwmn-control'
        )
      ) {
        this.elementRef.nativeElement.classList.add('active-flwmn-control');

        this.controlIsActive = true;

        this.controlMenuBarComponent?.elementRef.nativeElement.classList.add(
          'show-control-menu-bar'
        );

        // this.recipientMenuComponent?.elementRef.nativeElement.classList.add(
        //   'active'
        // );

        // this.actorMenuComponent?.elementRef.nativeElement.classList.add(
        //   'active'
        // );
      }
    }
  }

  stopPropagation(event: MouseEvent) {
    event.stopPropagation();
  }

  receiveActor(event: any) {
    const oldAttribute: any = JSON.parse(
      this.elementRef.nativeElement.getAttribute('data-control')
    );

    oldAttribute['recipientEmail'] = event?.actorEmail
      ? event.actorEmail
      : null;

    // oldAttribute['rank'] = event?.rank ? event.rank : 0;

    oldAttribute['recipientId'] = event?.actorId ? event.actorId : 0;

    const newAttribute = {
      ...JSON.parse(JSON.stringify(oldAttribute)),
    };

    this.elementRef.nativeElement.setAttribute(
      'data-control',
      JSON.stringify(newAttribute)
    );
  }

  initiateSetTextSize(textSize: any) {
    // this.renderer.setStyle(
    //   this.elementRef.nativeElement,
    //   'font-family',
    //   `sans-serif`
    // );

    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'font-size',
      `${textSize}px`
    );

    this.updateControlValueAndCoordinates();

    const timeout = setTimeout(() => {
      this.controlResize.next('');

      clearTimeout(timeout);
    }, 500);
  }

  initiateSetTextWeight(textWeight: string) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'font-weight',
      textWeight
    );

    this.updateControlValueAndCoordinates();

    const timeout = setTimeout(() => {
      this.controlResize.next('');

      clearTimeout(timeout);
    }, 500);
  }

  initiateSetTextAlign(textAlign: string) {
    this.renderer.setStyle(
      this.elementRef.nativeElement,
      'text-align',
      textAlign
    );

    this.updateControlValueAndCoordinates();
  }

  initiateSetTextColor(textColor: string) {
    this.renderer.setStyle(this.elementRef.nativeElement, 'color', textColor);

    this.updateControlValueAndCoordinates();
  }

  initiateDuplicateControl(event: any) {
    const controlAttribute = (
      this.elementRef.nativeElement as HTMLElement
    ).getAttribute('data-control');

    let dropTarget!: HTMLElement;

    Array.from(
      (this.elementRef.nativeElement as HTMLElement).parentNode!.children
    ).forEach((element) => {
      if (element.classList.contains('flwmn-page-img')) {
        dropTarget = element as HTMLElement;
      }
    });

    const control = {
      element: this.elementRef.nativeElement,
      dropTarget: dropTarget,
      controlAttribute: JSON.parse(controlAttribute!),
    };

    this.duplicateControl.emit(control);
  }

  initiateRemoveControl() {
    const controlAttribute = (
      this.elementRef.nativeElement as HTMLElement
    ).getAttribute('data-control');

    const control = {
      element: this.elementRef.nativeElement,
      controlAttribute: JSON.parse(controlAttribute!),
    };

    this.removeControl.emit(control);
  }

  removeShadowForSigned() {
    if (
      this.control.name.includes('signature') &&
      this.form.controls[this.control.name].value !== null
    ) {
      this.renderer.addClass(
        this.elementRef.nativeElement,
        'disabled-flwmn-control'
      );
    }
  }

  setSignature() {
    if (this.componentRef && this.control.type === ControlTypeEnum.signature) {
      this.componentRef.instance.updateSignatureControl();
    }
  }

  onEditSignature(userData: any) {
    if (this.control.type === ControlTypeEnum.signature) {
      this.componentRef.instance.userData = {
        ...this.componentRef.instance.userData,
        ...userData,
      };
      this.componentRef.instance.showWaterMark = userData.showWaterMark;
      this.componentRef.instance.onEditSignatureClicked();
    }
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }

    if (this.documentPageContainerResizeObserver) {
      this.documentPageContainerResizeObserver.disconnect();
    }
  }
}
