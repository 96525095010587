import {
  Component,
  ElementRef,
  EventEmitter,
  Output,
  Input,
  OnChanges,
  SimpleChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { ControlTypeEnum } from 'src/app/@core/enums/control.enum';
import { select, Store } from '@ngrx/store';
import * as fromApp from 'src/app/@core/stores/app/app.reducer';
import * as editorSelectors from 'src/app/@core/stores/editor/editor.selectors';
import { Subscription } from 'rxjs';
import { FormGroup } from '@angular/forms';
import * as editorSelector from 'src/app/@core/stores/editor/editor.selectors';

@Component({
  selector: 'app-control-menu-bar',
  templateUrl: './control-menu-bar.component.html',
  styleUrls: ['./control-menu-bar.component.scss'],
})
export class ControlMenuBarComponent implements OnInit, OnChanges, OnDestroy {
  allControlTypes = ControlTypeEnum;
  allTextSizes: number[] = [
    8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
  ];
  allTextWeights: any[] = [
    { name: 'Normal', value: '400' },
    { name: 'Bold', value: '700' },
    { name: 'Extra Bold', value: '900' },
  ];
  textAlign!: 'start' | 'center' | 'end';

  allActorDetails: {
    actorListName: string;
    actorsData: {
      actorId: number;
      actorFirstName: string;
      actorLastName: string;
      actorEmail: string;
    }[];
  } | null = null;

  selectedActor: {
    actorId: number;
    actorFirstName: string;
    actorLastName: string;
    actorEmail: string;
  } | null = null;

  currentSignature: string | null = null;
  userData: any | null = null;
  private subscription: Subscription = new Subscription();

  @Input() control: any;
  @Input() showActors: boolean = false;
  @Input() form!: FormGroup;

  @Output() selectedActorEmitter = new EventEmitter<any>();
  @Output() selectedTextSize = new EventEmitter<number>();
  @Output() selectedTextWeight = new EventEmitter<'400' | '700' | '900'>();
  @Output() selectedTextAlign = new EventEmitter<'start' | 'center' | 'end'>();
  @Output() selectedTextColor = new EventEmitter<string>();
  @Output() duplicate = new EventEmitter<any>();
  @Output() remove = new EventEmitter<any>();
  @Output() editIcon = new EventEmitter<any>();

  constructor(
    public elementRef: ElementRef,
    private store: Store<fromApp.AppState>
  ) {}

  ngOnInit(): void {
    this.listenToGetControlActorsSelectorSub();

    this.listenToGetDocumentBySigningRequestId();

    this.listenToFormValueChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.control) {
      if (typeof this.control?.additionalStyles?.['font-size'] === 'string') {
        this.control.additionalStyles['font-size'] = Number(
          this.control?.additionalStyles?.['font-size'].replaceAll('px', '')
        );
      }

      if (this.control?.additionalStyles?.['text-align']) {
        this.textAlign = this.control?.additionalStyles?.['text-align'];
      }
    }
  }

  listenToGetControlActorsSelectorSub() {
    this.subscription.add(
      this.store
        .pipe(select(editorSelectors.getControlActors))
        .subscribe((resData) => {
          if (resData) {
            this.allActorDetails = resData;
          }
        })
    );
  }

  getActorDataByEmail(actorEmail: string) {
    return this.allActorDetails?.actorsData?.find((data) => {
      return data?.actorEmail === actorEmail;
    });
  }

  onSelectActor(actorData: any) {
    this.selectedActor = actorData;

    this.selectedActorEmitter.emit(this.selectedActor);
  }

  onSetTextSize(value: any) {
    this.selectedTextSize.emit(Number(value));
  }

  onSetTextWeight(event: MatSelectChange) {
    this.selectedTextWeight.emit(event.value);
  }

  onSetTextAlign(currentTextAlign: 'start' | 'center' | 'end') {
    if (currentTextAlign === 'start') {
      this.textAlign = 'center';

      this.selectedTextAlign.emit('center');
    } else if (currentTextAlign === 'center') {
      this.textAlign = 'end';

      this.selectedTextAlign.emit('end');
    } else if (currentTextAlign === 'end') {
      this.textAlign = 'start';

      this.selectedTextAlign.emit('start');
    }
  }

  onSetTextColor(value: any) {
    this.selectedTextColor.emit(value);
  }

  onDuplicate() {
    this.duplicate.emit('');
  }

  onRemove() {
    this.remove.emit('');
  }

  onEditSignatureClicked() {
    this.editIcon.emit(this.userData);
  }

  listenToFormValueChange() {
    this.subscription.add(
      this.form.valueChanges.subscribe((res) => {
        if (res[this.control.name]) {
          this.currentSignature = res[this.control.name];
        }
      })
    );
  }

  listenToGetDocumentBySigningRequestId() {
    this.subscription.add(
      this.store
        .select(editorSelector.getDocumentsBySigningRequestId)
        .subscribe((res: any) => {
          if (res?.length > 0) {
            const {
              email,
              subscriberId,
              createdByName,
              allowTimeStampOnSignatureControl,
            } = res[0].document;
            // this.getRecipientSignatures(email, subscriberId);

            this.userData = {
              ...this.userData,
              isFlowmonoUser: true,
              flowmonoUserHasSignature: true,
              fullName: createdByName,
              email: email,
              subscriberId: subscriberId,
              showWaterMark: allowTimeStampOnSignatureControl,
            };
          }
        })
    );
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
